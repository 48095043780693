import { Text } from '@chakra-ui/react';
import React from 'react';
import { Container, Content } from './styles';

interface ComponentProps {
  label?: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

const DateAndHourPicker: React.FC<ComponentProps> = ({
  label,
  onChange,
  ...rest
}) => {
  return (
    <Container>
      {label && (
        <Text fontSize="xs" color="rgba(70, 70, 70, 1)">
          {label}
        </Text>
      )}
      <Content>
        <input
          type="datetime-local"
          name="date"
          onChange={e => onChange(e.target.value)}
          {...rest}
        />
      </Content>
    </Container>
  );
};

export default DateAndHourPicker;
