import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import { ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsInvestorData: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { mutateAsync: updateClient, isLoading: isLoadingUpdateClient } =
    useUpdateClient({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
      },
    });

  const data = [
    {
      prop: 'investorProfile',
      label: 'Tipo de investidor',
      value: client.investorProfile || '-',
      editable: false,
    },
    {
      prop: 'range',
      label: 'Range de investimentos',
      value: client.range || '-',
      editable: false,
    },
    {
      prop: 'total',
      label: 'Patrimônio declarado (BTG)',
      value: client.netWorth?.total
        ? CurrencyUtils.format(client.netWorth?.total)
        : '0',
      editable: false,
    },
    {
      prop: 'totalDeclaredByAAI',
      label: 'Patrimônio declarado (AAI)',
      value: client.netWorth?.totalDeclaredByAAI?.toString() || '0',
      editable: true,
    },
  ];

  return (
    <Flex flexDirection="column" gap={2}>
      {data.map((item, index) => {
        if (item.editable) {
          return (
            <Flex key={index} gap={1} alignItems="center">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                {item.label}:
              </Text>

              <Input.Root style={{ flex: 1 }}>
                <Input.EditableCurrency
                  value={item.value}
                  isLoading={isLoadingUpdateClient}
                  onConfirmEdit={newText =>
                    updateClient({
                      ...client,
                      netWorth: {
                        ...client.netWorth,
                        [item.prop]: Number(newText?.split(',')[0]),
                      },
                    })
                  }
                />
              </Input.Root>
            </Flex>
          );
        }

        return (
          <Flex key={index} gap={1}>
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              {item.label}:
            </Text>
            <Text color="rgba(70, 70, 70, 1)">{item.value}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
