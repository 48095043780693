import { PieCustomLayerProps } from '@nivo/pie';
import { CurrencyUtils } from 'utilsV2/Currency';

export const ChartCenterText = ({
  centerX,
  centerY,
  dataWithArc,
}: PieCustomLayerProps<unknown>): JSX.Element => {
  let total = 0;
  dataWithArc.forEach(datum => {
    total += datum.value;
  });

  return (
    <g>
      <text
        x={centerX}
        y={centerY - 20}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fill: 'rgba(70, 70, 70, 1)',
          fontSize: 13,
        }}
      >
        Total
      </text>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fill: 'rgba(70, 70, 70, 1)',
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        {CurrencyUtils.format(total)}
      </text>
    </g>
  );
};
