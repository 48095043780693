export const chooseFunnelColor = (label: string): string => {
  switch (label) {
    case 'totalLeadsFromTable':
    case 'pjLeads':
    case 'listaLeads':
    case 'meetingsPurchased':
    case 'minedLeads':
    case 'totalLeadsReceived':
      return '#58B1AF';
    case 'suitability':
      return '#7275A7';
    case 'presentation':
      return '#FF5A4E';
    case 'waitingOpenAccount':
      return '#6D9A6F';
    case 'waitingAllocation':
      return '#dbb14d';
    case 'allocated':
      return '#cc78b2';
    default:
      return '';
  }
};
