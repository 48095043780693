import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { useState } from 'react';
import { useMutation } from 'react-query';
import parser from 'html-react-parser';

import {
  CreateNoteRequestType,
  hubApiClients,
} from 'services/hubApi/classes/HubApiClients';
import { ClientType } from 'sharedV2/types/client';
import { Box } from 'componentsV2/elements/boxes';
import { DateUtils } from 'utilsV2/Date';
import { Button } from 'componentsV2/elements/buttons';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsNotesSubPage: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const [note, setNote] = useState<string>('');

  const { mutateAsync: createNote, isLoading: isLoadingCreateNote } =
    useMutation({
      mutationKey: ['createNote'],
      mutationFn: async (data: CreateNoteRequestType) => {
        const response = await hubApiClients.createNote(data);
        return response;
      },
      onSuccess: async () => {
        setNote('');

        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
      },
    });

  const handleSaveNote = async () => {
    await createNote({
      btgCode: client.btgCode,
      content: note,
    });
  };

  return (
    <SimpleGrid columns={2} gap={4} w="100%" h="100%">
      <Flex w="100%" h="100%" flexDirection="column" justify="flex-end" gap={2}>
        <Input.Root w="100%" h="100%">
          <Input.TextAreaField
            placeholder="Anotações:"
            h="100%"
            value={note}
            onChange={event => setNote(event.target.value)}
          />
        </Input.Root>
        <Button.Primary
          onClick={handleSaveNote}
          isLoading={isLoadingCreateNote}
        >
          Salvar
        </Button.Primary>
      </Flex>

      <Flex flexDirection="column" gap={2}>
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
          Anotações Anteriores
        </Text>

        <Flex
          flexDirection="column"
          alignItems="center"
          gap={4}
          maxH="calc(100vh - 275px)"
          overflowY="auto"
          className="thinScrollbar"
        >
          {client.notes.map((note, index) => (
            <Flex
              key={index}
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <Box.Outline p={1}>
                <Text color="rgba(70, 70, 70, 1)">
                  {DateUtils.format(
                    note.createdAt,
                    "d MMM. 'de' yyyy 'às' HH:mm"
                  )}
                </Text>
              </Box.Outline>
              <Text color="rgba(70, 70, 70, 1)">
                {parser(note.content)} {note.from && `(Nota de ${note.from})`}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </SimpleGrid>
  );
};
