import { Flex, Text } from '@chakra-ui/react';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { formatNameToCaptalize } from 'utils/captalizeNames';

function UserIcon() {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.339 8.661c4.882 4.882 4.882 12.796 0 17.678-4.882 4.881-12.796 4.881-17.678 0-4.881-4.882-4.881-12.796 0-17.678 4.882-4.881 12.796-4.881 17.678 0"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M8.472 25.873c2.543-2.604 5.288-4.206 9.065-4.206 3.74 0 6.448 1.57 8.99 4.126C23.986 28.398 21.24 30 17.463 30c-3.74 0-6.447-1.57-8.99-4.127z"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.446 10.387a4.167 4.167 0 11-5.892 5.893 4.167 4.167 0 015.892-5.893"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const LeadDetailsHeader = (): JSX.Element => {
  const { lead } = useLeadDetailsContext();

  return (
    <Flex w="100%" alignItems="center" gap={2}>
      <UserIcon />
      <Text
        fontSize="xl"
        color="rgba(70, 70, 70, 1)"
        fontWeight="bold"
        isTruncated
        maxW="900px"
      >
        {formatNameToCaptalize((lead?.razaoSocial || lead.name) ?? '-')}
      </Text>
      {lead.EWZClient && (
        <Text fontSize="lg" fontStyle="italic" color="#1D63DDB2">
          - Cliente EWZ
        </Text>
      )}
    </Flex>
  );
};
