import { Text, TextProps } from '@chakra-ui/react';

type PropsType = {
  text: string;
} & TextProps;

export const InputLabel: React.FC<PropsType> = ({
  text,
  ...rest
}): JSX.Element => {
  return (
    <Text fontSize="sm" color="rgba(70, 70, 70, 1)" {...rest}>
      {text}
    </Text>
  );
};
