import { Flex, Text } from '@chakra-ui/react';
import { useState, FC } from 'react';
import { colors } from 'sharedV2/colors';

type UseTabNavigationRequestType<T extends string> = {
  tabs: T[];
  initialTab: T;
};

type UseTabNavigationResponseType<T> = {
  TabsDiv: FC;
  selectedTab: T;
};

export const useTabNavigation = <T extends string>(
  data: UseTabNavigationRequestType<T>
): UseTabNavigationResponseType<T> => {
  const [tab, setTab] = useState<T>(data.initialTab);

  const TabsDiv: FC = () => {
    return (
      <Flex gap={2}>
        {data.tabs.map(item => (
          <Text
            key={item}
            color={tab === item ? 'rgba(70, 70, 70, 1)' : 'inherit'}
            fontWeight={tab === item ? 'bold' : 'normal'}
            cursor="pointer"
            onClick={() => setTab(item)}
            borderBottom={`2px solid ${
              tab === item ? colors.BLUE : 'transparent'
            }`}
          >
            {item}
          </Text>
        ))}
      </Flex>
    );
  };

  return {
    TabsDiv,
    selectedTab: tab,
  };
};
