import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { getMonth } from 'date-fns';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useQuery } from 'react-query';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { colors } from 'sharedV2/colors';
import { ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';
import { DateUtils } from 'utilsV2/Date';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsOffersSubPage: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const offerDateFilter = useSimpleQueryFilter('offerDate');

  const { data: offers, isError } = useQuery({
    queryKey: ['clientOffers', client.btgCode],
    queryFn: async () => {
      const response = await hubApiUser.listClientsOffers(client.btgCode);
      return response;
    },
    retry: false,
  });

  const handleFilterOffersByDate = () => {
    if (!offerDateFilter.filterValue) {
      return offers;
    }

    return offers?.filter(offer => {
      const isOfferInTheSameMonthAsFilter =
        getMonth(new Date(offer.dateLimit as string)) + 1 ===
        Number(offerDateFilter.filterValue);

      return isOfferInTheSameMonthAsFilter;
    });
  };

  const filteredOffers = handleFilterOffersByDate();

  return (
    <Flex flexDirection="column" gap={4} w="100%">
      <Flex w="100%" justifyContent="space-between">
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
          Ofertas do cliente
        </Text>

        <Dropdown.Root
          w="130px"
          onChange={event =>
            offerDateFilter.handleChangeFilterValue(event.target.value)
          }
        >
          <Dropdown.Options
            placeholder="Periodo"
            optionWithoutValueName="Todos"
            options={[
              { label: 'Janeiro', value: '1' },
              { label: 'Fevereiro', value: '2' },
              { label: 'Março', value: '3' },
              { label: 'Abril', value: '4' },
              { label: 'Maio', value: '5' },
              { label: 'Junho', value: '6' },
              { label: 'Julho', value: '7' },
              { label: 'Agosto', value: '8' },
              { label: 'Setembro', value: '9' },
              { label: 'Outubro', value: '10' },
              { label: 'Novembro', value: '11' },
              { label: 'Dezembro', value: '12' },
            ]}
          />
        </Dropdown.Root>
      </Flex>

      <Flex
        flexDirection="column"
        gap={3}
        w="100%"
        maxH="calc(100vh - 290px)"
        className="thinScrollbar"
        overflowY="auto"
      >
        {isError && (
          <Text w="100%" fontStyle="italic" textAlign="center">
            Esse cliente não tem ofertas
          </Text>
        )}
        {filteredOffers &&
          filteredOffers.map((offer, index) => (
            <Box.Glass
              key={index}
              w="100%"
              flexDirection="column"
              alignItems="start"
              gap={4}
              paddingY={2}
            >
              <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
                {offer.offerName}
              </Text>

              <Flex gap={1} flexDirection="column">
                <Text color="rgba(70, 70, 70, 1)">
                  Data limite da oferta:{' '}
                  {offer.dateLimit
                    ? DateUtils.format(
                        offer.dateLimit,
                        "dd 'de' MMMM 'de' yyyy"
                      )
                    : 'Não informado'}
                </Text>

                <Text color="rgba(70, 70, 70, 1)">
                  Alocação: {CurrencyUtils.format(offer.allocationAmount)}
                </Text>
              </Flex>

              <Flex justifyContent="flex-end" w="100%">
                <Text
                  color={
                    offer.status.toLowerCase() === 'confirmado' ||
                    offer.status.toLowerCase() === 'transferida'
                      ? colors.GREEN
                      : colors.RED
                  }
                >
                  {offer.status.toUpperCase()}
                </Text>
              </Flex>
            </Box.Glass>
          ))}
      </Flex>
    </Flex>
  );
};
