import { Flex } from '@chakra-ui/react';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { LeadDetailsDeclineOffer } from '../declineOffer';
import { LeadDetailsAllocated } from '../leadAllocated';
import { LeadDetailsAnswer } from '../leadAnswer';
import { LeadContactAttempts } from '../leadContactAttempts';
import { LeadDetailsNoAnswer } from '../noAnswer';
import { LeadDetailsOpenAccount } from '../openAccount';
import { LeadDetailsRecoverMeetingFromPipe } from '../recoverMeetingFromPipe';
import { LeadDetailsRouteMeeeting } from '../routeMeeting';
import { LeadDetailsScheduleMeeeting } from '../scheduleMeeting';
import { ScheduleMeetingCallback } from '../scheduleMeetingCallback';
import { LeadDetailsTransferLeadToAAI } from '../transferLeadToAAI';
import { LeadDetailsTransferLeadToSalesHunter } from '../transferLeadToSalesHunter';

export const LeadDetailsFooter = (): JSX.Element => {
  const { lead, leadType, desiredFlow } = useLeadDetailsContext();

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  const isLeadsSearchDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.LEADS_SEARCH;

  const isLeadWithoutFeeback =
    lead?.meeting?.confirm === false &&
    (lead?.meeting?.leadType === LeadTypeEnum.MUNDO_INVEST ||
      lead?.meeting?.leadType === 'publics') &&
    lead?.meeting?.complement !==
      'Lead abriu conta no Mundo Invest pelo seu link.';

  const renderButtonsByMeetingStatus = () => {
    if (isLeadsSearchDesiredFlow) {
      return (
        <>
          {leadBussinessLogic.isLeadAbleToBeTransfferedToASalesHunter() && (
            <LeadDetailsTransferLeadToSalesHunter />
          )}

          <LeadDetailsTransferLeadToAAI />
          <LeadDetailsDeclineOffer />
        </>
      );
    }

    if (!lead.meeting) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsScheduleMeeeting />
        </>
      );
    }

    if (lead.meeting.status === MeetingStatusEnum.CREATED) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsRouteMeeeting />
        </>
      );
    }

    if (lead.meeting.status === MeetingStatusEnum.RETURNED) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsScheduleMeeeting />
        </>
      );
    }

    if (lead.meeting.status === MeetingStatusEnum.CALLBACK) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsScheduleMeeeting />
        </>
      );
    }

    if (isLeadWithoutFeeback) {
      return (
        <>
          <LeadDetailsNoAnswer />
          <LeadDetailsAnswer />
        </>
      );
    }

    return (
      <>
        {(lead?.meeting?.confirm || leadType !== LeadTypeEnum.MUNDO_INVEST) && (
          <>
            {lead?.meeting?.status === MeetingStatusEnum.ACCOUNT ? (
              <LeadDetailsAllocated />
            ) : (
              <>
                {lead?.meeting?.status !== MeetingStatusEnum.DECLINED && (
                  <LeadDetailsDeclineOffer />
                )}

                {!lead?.meeting?.isPipe && <ScheduleMeetingCallback />}

                {lead?.meeting && <LeadDetailsOpenAccount />}

                {lead?.meeting?.status !== MeetingStatusEnum.ALLOCATION && (
                  <>
                    {lead?.meeting?.isPipe ||
                    lead.meeting.status === MeetingStatusEnum.DECLINED ? (
                      <LeadDetailsRecoverMeetingFromPipe />
                    ) : (
                      <LeadDetailsScheduleMeeeting />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Flex
      w="100%"
      justifyContent={!isSalesHunterDesiredFlow ? 'space-between' : 'flex-end'}
    >
      {!isSalesHunterDesiredFlow && <LeadContactAttempts />}

      <Flex gap={2}>{renderButtonsByMeetingStatus()}</Flex>
    </Flex>
  );
};
