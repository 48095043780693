import { forwardRef, InputHTMLAttributes } from 'react';

type InputDateProps = InputHTMLAttributes<HTMLInputElement>;

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  (props, ref) => {
    return (
      <input
        style={{
          border: '1px solid gray',
          borderRadius: '6px',
          padding: '6px',
          paddingLeft: '15px',
          background: 'transparent',
          fontSize: '15px',
          color: 'rgba(70, 70, 70, 1)',
        }}
        type="datetime-local"
        name="date"
        ref={ref}
        className="input-light-mode"
        {...props}
      />
    );
  }
);
