import { SimpleGrid, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { ClientDetailsTabEnum } from 'pagesV2/ClientDetails';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { colors } from 'sharedV2/colors';

type PropsType = {
  selectedTabFilter: UseSimpleFilterResponseType<ClientDetailsTabEnum>;
};

export const ClientDetailsTabs: React.FC<PropsType> = ({
  selectedTabFilter,
}): JSX.Element => {
  const tabs = [
    {
      name: 'Contato',
      nameInUrl: ClientDetailsTabEnum.CONTACT,
      onClick: () =>
        selectedTabFilter.handleChangeFilterValue(ClientDetailsTabEnum.CONTACT),
    },
    {
      name: 'Notas',
      nameInUrl: ClientDetailsTabEnum.NOTES,
      onClick: () =>
        selectedTabFilter.handleChangeFilterValue(ClientDetailsTabEnum.NOTES),
    },
    {
      name: 'Ofertas',
      nameInUrl: ClientDetailsTabEnum.OFFERS,
      onClick: () =>
        selectedTabFilter.handleChangeFilterValue(ClientDetailsTabEnum.OFFERS),
    },
    {
      name: 'Alocação',
      nameInUrl: ClientDetailsTabEnum.ALLOCATION,
      onClick: () =>
        selectedTabFilter.handleChangeFilterValue(
          ClientDetailsTabEnum.ALLOCATION
        ),
    },
    {
      name: 'Receitas',
      nameInUrl: ClientDetailsTabEnum.REVENUE,
      onClick: () =>
        selectedTabFilter.handleChangeFilterValue(ClientDetailsTabEnum.REVENUE),
    },
    {
      name: 'Perfil',
      nameInUrl: ClientDetailsTabEnum.PROFILE,
      onClick: () =>
        selectedTabFilter.handleChangeFilterValue(ClientDetailsTabEnum.PROFILE),
    },
  ];

  return (
    <SimpleGrid gap={2} w="250px">
      {tabs.map(tab => (
        <Box.Glass
          key={tab.name}
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          onClick={tab.onClick}
          position="relative"
          borderColor={
            selectedTabFilter.filterValue === tab.nameInUrl
              ? colors.BLUE
              : colors.GRAY
          }
          _hover={useCommonHover()}
        >
          <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
            {tab.name}
          </Text>
        </Box.Glass>
      ))}
    </SimpleGrid>
  );
};
