import { Flex } from '@chakra-ui/react';
import { Input } from 'componentsV2/elements/input';

import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { Button } from 'componentsV2/elements/buttons';
import { queryClient } from 'App';

export const LeadDetailsObservations = (): JSX.Element => {
  const { lead } = useLeadDetailsContext();

  const { mutateAsync: createLeadNote, isLoading: isLoadingCreateLeadNote } =
    useMutation({
      mutationKey: ['createLeadNote'],
      mutationFn: async (data: {
        content: string;
        leadId: string;
        leadType: string;
      }) => {
        const response = await hubApiCaptation.createLeadNote(data);
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadHistory', lead.id]);

        setObservations('');
      },
    });

  const { desiredFlow, leadType } = useLeadDetailsContext();

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  const [observations, setObservations] = useState<string>('');

  const handleCreateLeadNote = async () => {
    await createLeadNote({
      content: observations,
      leadId: lead.id,
      leadType,
    });
  };

  return (
    <Flex w="100%" h="100%" flexDirection="column" justify="flex-end" gap={2}>
      <Input.Root w="100%" h={isSalesHunterDesiredFlow ? '90%' : '100%'}>
        <Input.TextAreaField
          placeholder="Observações:"
          h="100%"
          value={observations}
          onChange={event => {
            setObservations(event.target.value);
          }}
        />
      </Input.Root>
      <Button.Primary
        onClick={handleCreateLeadNote}
        isLoading={isLoadingCreateLeadNote}
      >
        Salvar
      </Button.Primary>
    </Flex>
  );
};
