import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { OpenAccountModal } from 'componentsV2/modules/captation/openAccountModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { SecondaryButton } from '../../../../components/elements/buttons';

export const LeadDetailsOpenAccount = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenLeadOpenAccountModal =
    getParam('isOpenLeadOpenAccountModal') === 'true';

  return (
    <>
      {lead?.meeting?.status === MeetingStatusEnum.ALLOCATION ? (
        <Button.Primary
          onClick={() => setParam('isOpenLeadOpenAccountModal', 'true')}
        >
          Abriu Conta
        </Button.Primary>
      ) : (
        <SecondaryButton
          onClick={() => setParam('isOpenLeadOpenAccountModal', 'true')}
        >
          Abriu Conta
        </SecondaryButton>
      )}

      <ModalWrapper
        isOpen={isOpenLeadOpenAccountModal}
        onClose={() => deleteParam('isOpenLeadOpenAccountModal')}
      >
        <OpenAccountModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
