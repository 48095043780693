import { Flex, Text } from '@chakra-ui/react';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { optionsProps } from 'pages/Captation/subpages/Results/data';
import { DropDownLeadType } from '../others/DropDownLeadType';
import 'date-fns';
import { DropDownAAI } from './DropdownAAI';
import { DropDownTable } from './DropdownTable';

interface IProps {
  startDate: string;
  endDate: string;
  handleFilterStartDate: (e: string) => void;
  handleFilterEndDate: (e: string) => void;
  handleFilterLeadType: (e: string) => void;
  isManagement: boolean;
  setCustomEmployeeId: (employeeId: string) => void;
  isGeralManagement: boolean;
  setCustomTableId: (tableId: string) => void;
  customTableId: string;
}

export const Filters: React.FC<IProps> = ({
  handleFilterLeadType,
  endDate,
  handleFilterEndDate,
  handleFilterStartDate,
  startDate,
  isManagement,
  setCustomEmployeeId,
  isGeralManagement,
  setCustomTableId,
  customTableId,
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="end" gap={2}>
        {(isManagement || isGeralManagement) &&
          window.location.href.includes('funnel_results') && (
            <Flex alignItems="end" gap={2}>
              {isGeralManagement && (
                <Flex flexDirection="column">
                  <Text fontSize="xs">Mesas: </Text>
                  <DropDownTable setCustomTableId={setCustomTableId} />
                </Flex>
              )}

              <Flex flexDirection="column">
                <Text fontSize="xs">AAI: </Text>
                <DropDownAAI
                  isGeralManagement={isGeralManagement}
                  customTableId={customTableId}
                  setCustomEmployeeId={setCustomEmployeeId}
                />
              </Flex>
            </Flex>
          )}
        <Flex flexDirection="column">
          <Text fontSize="xs">Origem: </Text>
          <DropDownLeadType
            filterLeadType={e => handleFilterLeadType(e)}
            optionsProps={optionsProps}
          />
        </Flex>
      </Flex>
      <Flex alignItems="end" gap="8px">
        <Text>Período: </Text>
        <DatePickerChackra
          style={{
            width: 'fit-content',
            alignSelf: 'flex-end',
          }}
          handlePickDate={e => handleFilterStartDate(e.target.value)}
          value={startDate}
        />
        <Text>até</Text>
        <DatePickerChackra
          style={{
            width: 'fit-content',
            alignSelf: 'flex-end',
          }}
          handlePickDate={e => handleFilterEndDate(e.target.value)}
          value={endDate}
        />
      </Flex>
    </Flex>
  );
};
