import { useQueryParams } from 'hooksV2/useQueryParams';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { LeadProspectType } from 'sharedV2/types/leadProspect';

type ResponseType = {
  handleSortLeadsProspect: (value: SortLeadsProspectEnum) => void;
  sortLeadsProspectValue: SortLeadsProspectEnum;
  filteredLeads: LeadProspectType[] | undefined;
};

export enum SortLeadsProspectEnum {
  NAME = 'name',
  LAST_CONTACT_ATTEMPTS_DESC = 'last_contact_attemps_desc',
  LAST_CONTACT_ATTEMPTS_ASC = 'last_contact_attemps_asc',
}

export const useFilterLeadsProspect = (
  leads: LeadProspectType[] | undefined
): ResponseType => {
  const { getParam } = useQueryParams();

  const {
    filterValue: sortLeadsProspectValue,
    handleChangeFilterValue: handleSortLeadsProspect,
  } = useSimpleQueryFilter<SortLeadsProspectEnum>('sortLeadsProspectBy');

  const shouldFetchDeclinedLeads = getParam('shouldFetchDeclinedLeads');

  const sortLeads = () => {
    if (!sortLeadsProspectValue) return leads;

    if (sortLeadsProspectValue === SortLeadsProspectEnum.NAME) {
      return leads?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }

    if (
      sortLeadsProspectValue ===
      SortLeadsProspectEnum.LAST_CONTACT_ATTEMPTS_DESC
    ) {
      const leadsWithLastContactAttempts = leads?.filter(
        lead => lead.lastContactAttemptsUpdate !== undefined
      );

      leadsWithLastContactAttempts?.sort((a, b) => {
        if (a.lastContactAttemptsUpdate === undefined) return 1;
        if (b.lastContactAttemptsUpdate === undefined) return -1;

        return (
          new Date(b.lastContactAttemptsUpdate).getTime() -
          new Date(a.lastContactAttemptsUpdate).getTime()
        );
      });

      return leadsWithLastContactAttempts;
    }

    if (
      sortLeadsProspectValue === SortLeadsProspectEnum.LAST_CONTACT_ATTEMPTS_ASC
    ) {
      const leadsWithLastContactAttempts = leads?.filter(
        lead => lead.lastContactAttemptsUpdate !== undefined
      );

      leadsWithLastContactAttempts?.sort((a, b) => {
        if (a.lastContactAttemptsUpdate === undefined) return 1;
        if (b.lastContactAttemptsUpdate === undefined) return -1;

        return (
          new Date(a.lastContactAttemptsUpdate).getTime() -
          new Date(b.lastContactAttemptsUpdate).getTime()
        );
      });

      return leadsWithLastContactAttempts;
    }
  };

  const filterDeclinedLeads = (
    filteredLeads: LeadProspectType[] | undefined
  ) => {
    if (!shouldFetchDeclinedLeads) return filteredLeads;

    return filteredLeads?.filter(lead => lead.status === 'finalizado');
  };

  let filteredLeads = sortLeads();

  filteredLeads = filterDeclinedLeads(filteredLeads);

  return {
    filteredLeads,
    handleSortLeadsProspect,
    sortLeadsProspectValue,
  };
};
