import { Flex } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { useCreateLeadManual } from 'hooksV2/useCreateLeadManual';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { showToast } from 'utilsV2/showToast';
import { queryClient } from 'App';

const schema = z
  .object({
    name: z.string().min(1, { message: 'O campo nome é obrigatório' }),
    email: z
      .string()
      .email({ message: 'O campo precisa ser email valido' })
      .optional()
      .or(z.literal('')),
    firstPhone: z.string().optional(),
    secondPhone: z.string().optional(),
    thirdPhone: z.string().optional(),
    originDropdown: z
      .string()
      .min(1, { message: 'O campo origem é obrigatório' }),
    originCustomInput: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.originDropdown === 'Outro' && !data.originCustomInput) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['originCustomInput'],
        message: 'O campo origem é obrigatório',
      });
    }
  });

const originDropdownOptions: BaseDropdownOptionsType[] = [
  {
    label: 'Linkedin',
    value: 'Linkedin',
  },
  {
    label: 'Lista Fria',
    value: 'Lista Fria',
  },
  {
    label: 'Indicação',
    value: 'Indicação',
  },
  {
    label: 'Relacionamento dia-a-dia',
    value: 'Relacionamento dia-a-dia',
  },
  {
    label: 'Outro',
    value: 'Outro',
  },
];

type FormFieldsType = z.infer<typeof schema>;

export const AddNewLeadManualModaLeadsProspect = (): JSX.Element => {
  const {
    filterValue: isOpenAddNewLeadManualModalFilterValue,
    handleChangeFilterValue: handleChangeIsOpenAddNewLeadManualFilterValue,
    handleDeleteParam: handleDeleteIsOpenAddNewLeadManualModalFilterValue,
  } = useSimpleQueryFilter('isOpenAddNewLeadManualModal');

  const {
    mutateAsync: createLeadManual,
    isLoading: isLoadingCreateLeadManual,
  } = useCreateLeadManual({
    onError: error => handleErrorMessage(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['leadsProspect']);
      handleDeleteIsOpenAddNewLeadManualModalFilterValue();
      showToast('Lead criado com sucesso!');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const handleCreateManualLead: SubmitHandler<FormFieldsType> = async data => {
    const phones: string[] = [];

    if (data.firstPhone) phones.push(data.firstPhone);
    if (data.secondPhone) phones.push(data.secondPhone);
    if (data.thirdPhone) phones.push(data.thirdPhone);

    await createLeadManual({
      name: data.name,
      origin:
        data.originDropdown === 'Outro'
          ? (data.originCustomInput as string)
          : data.originDropdown,
      email: data.email,
      phones,
    });
  };

  return (
    <ModalWrapper
      isOpen={isOpenAddNewLeadManualModalFilterValue === 'true'}
      onClose={() => handleChangeIsOpenAddNewLeadManualFilterValue('false')}
    >
      <Modal.Root w="35vw">
        <Modal.Header>
          <Modal.Title title="Adicionar novo lead manual" />
          <Modal.CloseIcon
            onClick={() => handleDeleteIsOpenAddNewLeadManualModalFilterValue()}
          />
        </Modal.Header>

        <Flex flexDirection="column" gap={3}>
          <Input.Root w="100%">
            <Input.Label text="Nome" />
            <Input.TextField placeholder="Nome" {...register('name')} />
            <Input.ErrorMessage message={errors?.name?.message} />
          </Input.Root>

          <Input.Root w="100%">
            <Input.Label text="Email" />
            <Input.TextField placeholder="Email" {...register('email')} />
          </Input.Root>

          <Flex gap={2}>
            <Input.Root w="100%">
              <Input.Label text="Telefone 1" />
              <Input.TextField
                placeholder="Telefone 1"
                {...register('firstPhone')}
              />
            </Input.Root>

            <Input.Root w="100%">
              <Input.Label text="Telefone 2" />
              <Input.TextField
                placeholder="Telefone 2"
                {...register('secondPhone')}
              />
            </Input.Root>

            <Input.Root w="100%">
              <Input.Label text="Telefone 3" />
              <Input.TextField
                placeholder="Telefone 3"
                {...register('thirdPhone')}
              />
            </Input.Root>
          </Flex>

          <Input.Root w="100%">
            <Input.Label text="Origem" />
            <Dropdown.Root
              borderColor="#1d63ddb2"
              {...register('originDropdown')}
            >
              <Dropdown.Options
                options={originDropdownOptions}
                placeholder="Origem"
              />
            </Dropdown.Root>
            {watch('originDropdown') === 'Outro' && (
              <Input.TextField
                placeholder="Origem"
                {...register('originCustomInput')}
              />
            )}
            <Input.ErrorMessage
              message={
                errors?.originDropdown?.message ||
                errors?.originCustomInput?.message
              }
            />
          </Input.Root>
        </Flex>

        <Modal.Actions>
          <Modal.Action
            w="40%"
            text="Adicionar"
            isLoading={isLoadingCreateLeadManual}
            onClick={handleSubmit(handleCreateManualLead)}
          />
        </Modal.Actions>
      </Modal.Root>
    </ModalWrapper>
  );
};
