import { Flex, Text } from '@chakra-ui/react';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { Link } from 'react-router-dom';

interface HeaderIPManagementProps {
  noTransferedOrders: number;
}

export const HeaderIPManagement: React.FC<HeaderIPManagementProps> = ({
  noTransferedOrders,
}) => {
  return (
    <Link to="/orders/transfer/ip">
      <Flex
        mt="2"
        justifyContent="flex-end"
        alignItems="center"
        cursor="pointer"
      >
        <Flex justifyContent="center" alignItems="center">
          <DefaultCardWrapper
            p="2"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            _hover={{ background: 'background.500' }}
            transition="400ms"
          >
            <Text color="white" fontSize="smaller">
              Ordens não transferidas
            </Text>

            <Text color="orange.400" fontSize="initial">
              {noTransferedOrders}
            </Text>
          </DefaultCardWrapper>
        </Flex>
      </Flex>
    </Link>
  );
};
