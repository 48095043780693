import { Flex } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { TextUtils } from 'utilsV2/Text';
import { ClientDetailsSkeletonLoading } from './components/skeletonLoading';
import { ClientDetailsTabs } from './components/tabs';
import { ClientDetailsAllocationSubPage } from './subpages/allocation';
import { ClientDetailsContactSubPage } from './subpages/contact';
import { ClientDetailsNotesSubPage } from './subpages/notes';
import { ClientDetailsOffersSubPage } from './subpages/offers';
import { ClientDetailsProfileSubPage } from './subpages/profile';
import { ClientDetailsRevenueSubPage } from './subpages/revenue';

export enum ClientDetailsTabEnum {
  CONTACT = 'CONTACT',
  NOTES = 'NOTES',
  OFFERS = 'OFFERS',
  ALLOCATION = 'ALLOCATION',
  REVENUE = 'REVENUE',
  PROFILE = 'PROFILE',
}

export const ClientDetails = (): JSX.Element => {
  const { btgCode } = useParams<{ btgCode: string }>();

  const selectedTabFilter = useSimpleQueryFilter<ClientDetailsTabEnum>('tab', {
    initialValue: ClientDetailsTabEnum.CONTACT,
  });

  const { data: client, isLoading: isLoadingClient } = useQuery({
    queryKey: ['clientDetails', btgCode],
    queryFn: async () => {
      const response = await hubApiClients.getClientByBtgCode(btgCode);
      return response;
    },
  });

  if (isLoadingClient || !client) {
    return <ClientDetailsSkeletonLoading />;
  }

  return (
    <PageWrapper paddingTop="2rem" paddingX="2rem" backPageButton>
      <MainPageTitle color="rgba(70, 70, 70, 1)">
        {TextUtils.toCapitalize(client.name)}
      </MainPageTitle>

      <Flex gap={2} h="calc(100vh - 200px)">
        <Flex
          borderRadius={5}
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          paddingX="1rem"
          border="1px solid gray"
          h="100%"
          w="100%"
          style={{ flex: 1 }}
          overflow="hidden"
          paddingY={5}
        >
          {selectedTabFilter.filterValue === ClientDetailsTabEnum.CONTACT && (
            <ClientDetailsContactSubPage client={client} />
          )}

          {selectedTabFilter.filterValue === ClientDetailsTabEnum.NOTES && (
            <ClientDetailsNotesSubPage client={client} />
          )}

          {selectedTabFilter.filterValue === ClientDetailsTabEnum.OFFERS && (
            <ClientDetailsOffersSubPage client={client} />
          )}

          {selectedTabFilter.filterValue ===
            ClientDetailsTabEnum.ALLOCATION && (
            <ClientDetailsAllocationSubPage client={client} />
          )}

          {selectedTabFilter.filterValue === ClientDetailsTabEnum.REVENUE && (
            <ClientDetailsRevenueSubPage client={client} />
          )}

          {selectedTabFilter.filterValue === ClientDetailsTabEnum.PROFILE && (
            <ClientDetailsProfileSubPage client={client} />
          )}
        </Flex>

        <ClientDetailsTabs selectedTabFilter={selectedTabFilter} />
      </Flex>
    </PageWrapper>
  );
};
