import { useNavigation } from 'hooksV2/useNavigation';
import { useMutation, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import hubApi, { ResetPasswordRequestType } from 'services/hubApi';
import { signInRequest } from 'store/modules/auth/actions';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { showToast } from 'utilsV2/showToast';

type UseAuthResponseType = {
  sendRecoverPasswordEmailMutation: UseMutationResult<
    void,
    unknown,
    string,
    unknown
  >;
  resetPasswordMutation: UseMutationResult<
    void,
    unknown,
    ResetPasswordRequestType,
    unknown
  >;
  dispatchLogin: (data: DispatchLoginRequestType) => void;
};

type DispatchLoginRequestType = {
  email: string;
  password: string;
};

export const useAuth = (request: {
  setIsLoading: any;
}): UseAuthResponseType => {
  const dispatch = useDispatch();
  const { redirect } = useNavigation();

  const sendRecoverPasswordEmailMutation = useMutation({
    mutationKey: ['sendRecoverPasswordEmail'],
    mutationFn: async (email: string) => {
      const response = await hubApi.requestResetPassword(email);
      return response;
    },
    onSuccess: () => {
      showToast('Email para a recuperação de senha enviado com sucesso!');
    },
    onError: error => handleErrorMessage(error),
  });

  const resetPasswordMutation = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async (data: ResetPasswordRequestType) => {
      const response = await hubApi.resetPassword(data);
      return response;
    },
    onSuccess: () => {
      showToast('Senha alterada com sucesso! Faça o login.');
      redirect('/v2/login');
    },
  });

  const dispatchLogin = (data: DispatchLoginRequestType) => {
    dispatch(signInRequest(data.email, data.password, request.setIsLoading));
  };

  return {
    sendRecoverPasswordEmailMutation,
    dispatchLogin,
    resetPasswordMutation,
  };
};
