import { MeetingStatusEnum } from 'sharedV2/types/meeting';

export class MeetingUtils {
  static translateStatus(status: MeetingStatusEnum | undefined): string {
    switch (status?.toLowerCase()) {
      case MeetingStatusEnum.SUITABILITY:
        return 'R1 - Suitability';
      case MeetingStatusEnum.RETURNED:
        return 'R.N.A';
      case MeetingStatusEnum.PRESENTATION:
        return 'R2 - Carteira';
      case MeetingStatusEnum.ALLOCATION:
        return 'Aguardando abertura';
      case MeetingStatusEnum.ACCOUNT:
        return 'Aporte';
      case MeetingStatusEnum.ALOCADO:
        return 'Alocado';
      case MeetingStatusEnum.WAITING_FEEDBACK:
        return 'Aguardando feedback';
      case MeetingStatusEnum.OCUPADO:
        return 'Ocupado';
      case MeetingStatusEnum.DECLINED:
        return 'Declinado';
      case MeetingStatusEnum.CREATED:
        return 'Reunião criada';
      case MeetingStatusEnum.CALLBACK:
        return 'Retorno agendado';
      default:
        return 'Could not translate meeting status';
    }
  }
}
