import { ModalWrapper } from 'components/elements/wrappers';
import { ScheduleMeetingCallbackModal } from 'componentsV2/modules/captation/scheduleMeetingCallbackModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { SecondaryButton } from '../../../../components/elements/buttons';

export const ScheduleMeetingCallback = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenScheduleMeetingCallbackModal =
    getParam('isOpenScheduleMeetingCallbackModal') === 'true';

  const isLeadMeetingInR1orR2 =
    lead.meeting?.status === MeetingStatusEnum.SUITABILITY ||
    lead.meeting?.status === MeetingStatusEnum.PRESENTATION;

  return (
    <>
      <SecondaryButton
        onClick={() => setParam('isOpenScheduleMeetingCallbackModal', 'true')}
      >
        {isLeadMeetingInR1orR2 ? 'Reagendar' : 'Retornar'}
      </SecondaryButton>

      <ModalWrapper
        isOpen={isOpenScheduleMeetingCallbackModal}
        onClose={() => deleteParam('isOpenScheduleMeetingCallbackModal')}
      >
        <ScheduleMeetingCallbackModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
