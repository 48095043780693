import { Flex, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Box } from 'componentsV2/elements/boxes';
import { Modal } from 'componentsV2/elements/modal';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { ClientMeetingStatusEnum, ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';
import parser from 'html-react-parser';
import { SecondaryButton } from '../../../../components/elements/buttons';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsMeetingSummary: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const isOpenMeetingSummaryModalFilter = useSimpleQueryFilter(
    'isOpenMeetingSummaryModal'
  );

  const meetingThatAlreadyHaveBeenConcluded = client.meetings.filter(
    meeting => meeting.status === ClientMeetingStatusEnum.CONCLUDED
  );

  return (
    <Flex>
      <SecondaryButton
        onClick={() =>
          isOpenMeetingSummaryModalFilter.handleChangeFilterValue('true')
        }
      >
        Resumo de Reuniões
      </SecondaryButton>

      <ModalWrapper
        isOpen={isOpenMeetingSummaryModalFilter.filterValue === 'true'}
        onClose={() => isOpenMeetingSummaryModalFilter.handleDeleteParam()}
      >
        <Modal.Root w="700px">
          <Modal.Header>
            <Modal.Title title="Resumo de Reuniões" />
            <Modal.CloseIcon
              onClick={() =>
                isOpenMeetingSummaryModalFilter.handleDeleteParam()
              }
            />
          </Modal.Header>

          <Flex
            flexDirection="column"
            gap={2}
            maxH="300px"
            overflowY="auto"
            className="thinScrollbar"
          >
            {meetingThatAlreadyHaveBeenConcluded.length === 0 && (
              <Text fontStyle="italic">Sem reuniões concluidas</Text>
            )}
            {meetingThatAlreadyHaveBeenConcluded.map((meeting, index) => (
              <Box.Opaque key={index} p={4} gap={2}>
                <Text color="white" fontWeight="bold">
                  Reunião em{' '}
                  {DateUtils.format(meeting.date, "dd/MM/yyyy 'às' hh:mm'h'")}
                </Text>
                <Flex gap={1}>
                  <Text color="white">Satisfação do cliente:</Text>
                  <Text>{meeting.clientSatisfaction}</Text>
                </Flex>
                {meeting.commentedAreas.length > 0 && (
                  <Flex gap={1}>
                    <Text color="white">Áreas Comentadas:</Text>
                    <Text>{meeting.commentedAreas.join(', ')}</Text>
                  </Flex>
                )}
                {meeting.observations && (
                  <Flex gap={1}>
                    <Text color="white">Observações: </Text>
                    <Text>{parser(meeting.observations)}</Text>
                  </Flex>
                )}
              </Box.Opaque>
            ))}
          </Flex>
        </Modal.Root>
      </ModalWrapper>
    </Flex>
  );
};
