export const resonsOptions = [
  { title: 'Cliente não estava ciente', value: 'customer_not_aware' },
  { title: 'Cliente não quis aceitar', value: 'customer_didnt_accept' },
  { title: 'Cliente não se enquadra', value: 'client_doesnt_fit' },
  { title: 'Sem dinheiro', value: 'no_money' },
  { title: 'Outros', value: 'others' },
];

export const closeOrderOptions = [
  { title: 'Boletada', value: '' },
  { title: 'Cancelada', value: '' },
];
