export const calcWidth = (width: number, highestValue: number): number => {
  const widthPercentual = 100;
  if (width === highestValue) {
    return 100;
  }

  let totalResult = (width * widthPercentual) / highestValue;

  if (width <= highestValue / 5) {
    totalResult *= 1.1;
  }

  return totalResult;
};
