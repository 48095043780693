export const adjustLabel = (label: string): string => {
  switch (label) {
    case 'pjLeads':
      return 'Leads PJ';
    case 'listaLeads':
      return 'Leads Lista';
    case 'minedLeads':
      return 'Leads Minerados';
    case 'meetingsPurchased':
      return 'Reuniões Compradas';
    case 'totalLeadsReceived':
      return 'Leads Recebidos';
    case 'suitability':
      return 'Suitability (R1)';
    case 'presentation':
      return 'Carteira (R2)';
    case 'waitingOpenAccount':
      return 'Ag. abertura de conta';
    case 'waitingAllocation':
      return 'Ag. Alocação';
    case 'allocated':
      return 'Alocado';
    default:
      return '';
  }
};
