import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { BackPageButton } from 'components/elements/buttons';

import { PageWrapper } from 'components/elements/wrappers';
import { HeaderIPManagement } from 'pages/IP/components/elements';
import { MainPageTitle } from 'components/elements/texts';
import { SegmentCard } from 'pages/IP/components/modules/Management';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { useSegmentDataAndEmployees } from 'pages/IP/hooks/useSegmentDataAndEmployees';

export const IpManagement: React.FC = () => {
  const {
    health,
    life,
    cred,
    agro,
    corporate,
    altInsurances,
    noTransferedOrders,
  } = useSegmentDataAndEmployees();
  const [userHealthSelected, setUserHealthSelected] = useState('');
  const [userImobSelected, setUserImobSelected] = useState('');
  const [userLifeSelected, setUserLifeSelected] = useState('');
  const [userAgroSelected, setUserAgroSelected] = useState('');
  const [userCorporateSelected, setUserCorporateSelected] = useState('');
  const [userAltInsurancesSelected, setUserAltInsurancesSelected] =
    useState('');

  const handleSelectOptionHealth = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setUserHealthSelected(e.target.value);
  };

  const handleSelectOptionImob = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUserImobSelected(e.target.value);
  };

  const handleSelectOptionLife = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUserLifeSelected(e.target.value);
  };

  const handleSelectOptionAgro = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUserAgroSelected(e.target.value);
  };

  const handleSelectOptionCorporate = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setUserCorporateSelected(e.target.value);
  };

  const handleSelectOptionAltInsurances = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setUserAltInsurancesSelected(e.target.value);
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '6' }}
    >
      <BackPageButton />

      <Flex justifyContent="space-between" w="80%" alignItems="center">
        <MainPageTitle mb="0">Gestão de IP</MainPageTitle>
        <HeaderIPManagement noTransferedOrders={noTransferedOrders} />
      </Flex>

      <Flex
        w="90%"
        justifyContent="flex-start"
        alignItems="center"
        m="10px 0 12px 0"
        overflowX="auto"
        wrap={{ base: 'wrap', md: 'nowrap' }}
        gridGap={2}
      >
        <SegmentCard
          segmentInfos={health}
          handleSelectedEmployee={handleSelectOptionHealth}
          selectedEmployee={userHealthSelected}
        />

        <SegmentCard
          segmentInfos={cred}
          handleSelectedEmployee={handleSelectOptionImob}
          selectedEmployee={userImobSelected}
        />

        <SegmentCard
          segmentInfos={life}
          handleSelectedEmployee={handleSelectOptionLife}
          selectedEmployee={userLifeSelected}
        />

        <SegmentCard
          segmentInfos={agro}
          handleSelectedEmployee={handleSelectOptionAgro}
          selectedEmployee={userAgroSelected}
        />

        <SegmentCard
          segmentInfos={corporate}
          handleSelectedEmployee={handleSelectOptionCorporate}
          selectedEmployee={userCorporateSelected}
        />

        <SegmentCard
          segmentInfos={altInsurances}
          handleSelectedEmployee={handleSelectOptionAltInsurances}
          selectedEmployee={userAltInsurancesSelected}
        />
      </Flex>
    </PageWrapper>
  );
};
