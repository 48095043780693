import React from 'react';
import { Text } from '@chakra-ui/react';

import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';

interface ComponentProps {
  ticker: string;
  info: string;
}

export const CardToInfo: React.FC<ComponentProps> = ({ ticker, info }) => {
  return (
    <DefaultModalContentWrapper>
      <ModalTitle textAlign="center">{ticker}</ModalTitle>
      <Text color="white">{info}</Text>
    </DefaultModalContentWrapper>
  );
};
