import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { HeadDataType } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { useNavigation } from 'hooksV2/useNavigation';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { LeadProspectType } from 'sharedV2/types/leadProspect';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { DateUtils } from 'utilsV2/Date';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  tableHeadData: HeadDataType[];
  lead: LeadProspectType;
};

export const LeadProspectTableRow: React.FC<PropsType> = ({
  lead,
  tableHeadData,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const handleOnLeadClick = () => {
    redirect(`/v2/lead/${LeadTypeEnum.UPLOAD}/${lead.id}`);
  };

  return (
    <TableRow
      headData={tableHeadData}
      key={lead.id}
      onClick={handleOnLeadClick}
      _hover={useCommonHover()}
    >
      <Flex>
        <Box maxW="250px">
          <Tooltip
            hasArrow
            label={TextUtils.toCapitalize(lead.name)}
            color="white"
            bg="background.200"
          >
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {TextUtils.toCapitalize(lead.name)}
            </Text>
          </Tooltip>
        </Box>
      </Flex>

      <Text>{lead.phone ? TextUtils.toPhone(lead.phone) : '-'}</Text>
      <Text>
        {lead.lastContactAttemptsUpdate
          ? DateUtils.formatWithTime(lead.lastContactAttemptsUpdate)
          : 'Sem Último Contato'}
      </Text>
      <Text>{lead.listName ? 'Lista' : 'Minerado'}</Text>
      <Text>{lead.contactAttempts}</Text>
    </TableRow>
  );
};
