import { Flex, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useAuth } from 'hooksV2/useAuth';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { colors } from 'sharedV2/colors';
import { z } from 'zod';

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'O campo email é obrigatório' })
    .email({ message: 'O campo email deve conter um email válido' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const SendRecoverPasswordEmailLogin = (): JSX.Element => {
  const { sendRecoverPasswordEmailMutation } = useAuth();

  const {
    filterValue: isOpenRecoverPasswordModalFilterValue,
    handleChangeFilterValue: handleChangeIsOpenRecoverPasswordModalFilterValue,
    handleDeleteParam: handleDeleteIsOpenRecoverPasswordModalFilterValue,
  } = useSimpleQueryFilter('isOpenRecoverPasswordModal');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const handleSendRecoverPasswordEmail: SubmitHandler<FormFieldsType> =
    async data => {
      await sendRecoverPasswordEmailMutation.mutateAsync(data.email);

      handleDeleteIsOpenRecoverPasswordModalFilterValue();
    };

  return (
    <Flex>
      <Text
        fontStyle="italic"
        cursor="pointer"
        transition="all 0.2s"
        fontSize="sm"
        _hover={{ color: colors.BLUE }}
        onClick={() =>
          handleChangeIsOpenRecoverPasswordModalFilterValue('true')
        }
        color="rgba(70, 70, 70, 1)"
      >
        Esqueci minha senha
      </Text>

      <ModalWrapper
        isOpen={isOpenRecoverPasswordModalFilterValue === 'true'}
        onClose={handleDeleteIsOpenRecoverPasswordModalFilterValue}
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title="Recuperar senha" />
            <Modal.CloseIcon
              onClick={handleDeleteIsOpenRecoverPasswordModalFilterValue}
            />
          </Modal.Header>

          <Input.Root>
            <Input.TextField placeholder="Email" {...register('email')} />
            <Input.ErrorMessage message={errors.email?.message} />
          </Input.Root>

          <Modal.Actions>
            <Modal.Action
              text="Enviar email"
              isLoading={sendRecoverPasswordEmailMutation.isLoading}
              onClick={handleSubmit(handleSendRecoverPasswordEmail)}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </Flex>
  );
};
