import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { ClientMeetingStatusEnum, ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsCommentedAreas: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const meetingThatAlreadyHaveBeenConcluded = client.meetings.filter(
    meeting => meeting.status === ClientMeetingStatusEnum.CONCLUDED
  );

  return (
    <Flex flexDirection="column" gap={3} w="100%">
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Áreas Comentadas
      </Text>
      <Flex
        w="100%"
        flexDirection="column"
        gap={3}
        overflowY="auto"
        className="thinScrollbar"
      >
        {meetingThatAlreadyHaveBeenConcluded.length === 0 && (
          <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
            Não existem áreas comentadas
          </Text>
        )}
        {meetingThatAlreadyHaveBeenConcluded.map((meeting, index) => (
          <Box.Outline justifyContent="space-between" w="100%" key={index}>
            {meeting.commentedAreas.length > 0 ? (
              <Text color="rgba(70, 70, 70, 1)">
                {meeting.commentedAreas.join(', ')}
              </Text>
            ) : (
              <Text color="rgba(70, 70, 70, 1)">-</Text>
            )}
            <Text color="rgba(70, 70, 70, 1)">
              {DateUtils.format(meeting.date, 'dd/MM/yyyy')}
            </Text>
          </Box.Outline>
        ))}
      </Flex>
    </Flex>
  );
};
