import React, {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { LeadTypeReturn } from 'utils';
import { errorHandler } from 'utils/errorHandler';
import { localOptions } from 'pages/Captation/data';
import { useCaptationMeetingsContext } from 'hooks';
import { checkIfDateIsPast, formatDateToISO } from 'utils/formatDate';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { Advisor, IQuestionProps } from 'pages/Captation/interfaces';
import { BaseModalFormCard } from 'components/elements/cards';
import { TextareaWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';

import { formatNameToCaptalize } from 'utils/captalizeNames';
import { scheduleMeetingSchema } from './schema';
import { LeadInfos } from '../../leads/CommonLeadContent';

interface ScheduleMeetingProps {
  meetingId?: string;
  handleShowModal: () => void;
  id: string;
  handleSaveLeadInfos?: () => Promise<void>;
  leadType:
    | 'leadstable'
    | 'leadspublic'
    | 'leadsmanual'
    | 'leadspj'
    | 'leadspersonal'
    | 'declined'
    | 'pipe'
    | 'leadspurchased'
    | 'pool';
  postTasks: () => Promise<void>;
  setResponse: Dispatch<SetStateAction<Partial<IQuestionProps>[]>>;
  contactAttempts?: string;
  status?:
    | 'created'
    | 'confirmed'
    | 'suitability'
    | 'presentation'
    | 'account'
    | 'allocation';
  handleRemoveMinedLeadById?: (leadId: string) => void;
  handleRemoveListLeadById?: (leadId: string) => void;
  leadInfo?: LeadInfos;
  updateTotalNumOfLeadsInPipe: () => void;
  handleNextLeadFromPool?: (
    action: 'decline' | 'purchase' | 'no_answer'
  ) => void;
  afterOperationCompleteCallback?: () => void;
}

export const ScheduleMeeting: React.FC<ScheduleMeetingProps> = ({
  handleShowModal,
  id,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSaveLeadInfos = () => {
    //
  },
  leadType,
  updateTotalNumOfLeadsInPipe,
  postTasks,
  setResponse,
  status = 'suitability',
  meetingId,
  contactAttempts = '0',
  handleRemoveMinedLeadById,
  handleRemoveListLeadById,
  leadInfo,
  handleNextLeadFromPool,
  afterOperationCompleteCallback,
}) => {
  const user = useSelector((state: RootStateOrAny) => state.user.profile);
  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [localTitle, setLocalTitle] = useState('Local');
  const [scheduling, setScheduling] = useState('');
  const [complement, setComplement] = useState('');
  const [advisorsList, setAdvisorsList] = useState<Advisor[]>([]);

  const [selectedAdvisor, setSelectedAdvisor] = useState({
    name: user.name,
    id: user.id,
  });

  async function getAdvisors() {
    if (leadType === 'leadspublic') return;
    try {
      const tableAdvisors = await hubApiOfficeTables.listUsersByTable(
        user.tableId
      );

      setAdvisorsList(tableAdvisors);
    } catch (err) {
      toast.dark('Erro ao buscar a lista de assessores disponíveis.');
    }
  }

  useEffect(() => {
    getAdvisors();
  }, [user.tableId]);

  const handleSelectAdvisor = (event: BaseSyntheticEvent) => {
    const foundAdvisor = advisorsList?.find(
      advisor => advisor.id === event.target.value
    );

    if (foundAdvisor) {
      setSelectedAdvisor({ id: foundAdvisor.id, name: foundAdvisor.name });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMeetingDate: any = (value: string) => {
    const parsed = formatDateToISO(value);

    setScheduling(parsed);
  };

  const handleFormSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const dateIsPast = checkIfDateIsPast(scheduling);
    if (dateIsPast || scheduling.trim() === '') {
      setIsLoading(false);
      toast.dark('Escolha uma data futura para marcar uma reunião.');
    }

    try {
      const LeadType = LeadTypeReturn(leadType);

      const meetingData = {
        leadId: id,
        employeeId: user.id,
        employeeIdParticipant:
          leadType === 'leadspublic' ? user.id : selectedAdvisor.id,
        type: 'meeting',
        local: localTitle,
        leadType: LeadType,
        complement,
        date: scheduling,
        status,
        confirm: !!handleNextLeadFromPool,
        feedback: '',
        contactAttempts: status === 'confirmed' ? contactAttempts : '0',
        isPipe: false,
      };
      // essa variavel status basicamente informa se aquele lead tem uma meeting, e se ele nao tiver, o contador deve ser zerado

      const isMeetingValid = await scheduleMeetingSchema.isValid(meetingData);

      if (!isMeetingValid) {
        await scheduleMeetingSchema.validate(meetingData).catch(err => {
          err.errors.map((error: string) => toast.dark(error));
        });
        setIsLoading(false);
        return;
      }

      if (status === 'confirmed' || leadInfo?.interest === 'no_answer') {
        await hubApiCaptation.pathMetting({ ...meetingData, id: meetingId });
      } else {
        await hubApiCaptation.postMetting(meetingData);
      }

      if (leadType.toLowerCase() === 'leadspublic' && status !== 'confirmed') {
        await hubApiCaptation.pathLeadsUpdate(LeadType, {
          id,
        });
      } else {
        await hubApiCaptation.pathLeadsUpdate(LeadType, {
          id,
          status: 'meet',
        });
      }

      if (!handleNextLeadFromPool) {
        postTasks && postTasks();

        handleSaveLeadInfos && (await handleSaveLeadInfos());

        updateTotalNumOfLeadsInPipe && updateTotalNumOfLeadsInPipe();

        if (handleRemoveMinedLeadById) {
          handleRemoveMinedLeadById(id);
        }
        if (handleRemoveListLeadById) {
          handleRemoveListLeadById(id);
        }

        setResponse([]);
      } else {
        handleNextLeadFromPool('purchase');
      }

      const isUpdateArr = true;

      await handleGetSpecificLeadTypeArr('suitability', isUpdateArr);

      toast.dark(
        status === 'confirmed'
          ? 'Reunião roteada com sucesso'
          : 'Reunião marcada com sucesso'
      );

      if (afterOperationCompleteCallback) {
        afterOperationCompleteCallback();
      }
      handleShowModal();
    } catch (err) {
      errorHandler(err);

      if (
        (
          err as Record<
            string,
            Record<string, Record<string, Record<string, string>>>
          >
        ).response?.data.error.message ===
        'Already exists a meeting register for this lead'
      ) {
        toast.dark(
          'Já existe uma reunião para este lead, verifique com o gestor'
        );
      } else {
        toast.dark(
          'Não foi possível agendar a reunião, tente novamente em alguns minutos'
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      maxW="510px"
      title="Agendar nova reunião"
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      primaryButtonText="Agendar reunião"
      isModal
    >
      <Stack spacing="4">
        <DateAndHourPicker onChange={handleChangeMeetingDate} label="Data" />

        <Flex mt="2" flexDir={{ base: 'column', md: 'row' }} gridGap="2">
          <Dropdown
            placeholder="Tipo de reunião"
            onChange={e => setLocalTitle(e.target.value)}
          >
            {localOptions.map((item, index) => (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            ))}
          </Dropdown>

          {leadType !== 'leadspublic' && (
            <Dropdown
              placeholder={formatNameToCaptalize(selectedAdvisor.name)}
              onChange={handleSelectAdvisor}
            >
              {advisorsList?.map(item => {
                if (item.name === selectedAdvisor.name) {
                  return <> </>;
                }

                return (
                  <option key={item.id} value={item.id}>
                    {formatNameToCaptalize(item.name)}
                  </option>
                );
              })}
            </Dropdown>
          )}
        </Flex>

        <TextareaWithLabel
          label="Complemento:"
          placeholder="Informações sobre a reunião"
          name="Complement"
          rows={4}
          onChange={e => setComplement(e.target.value)}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
