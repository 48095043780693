/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Text, UseCheckboxProps } from '@chakra-ui/react';

export const AllowedPagesItem: React.FC<UseCheckboxProps> = props => {
  return (
    <Text
      borderRadius="md"
      bg="background.400"
      fontSize="sm"
      textAlign="center"
      color="white"
      px="2.5"
      py="1"
    >
      {props.children}
    </Text>
  );
};
