import { SimpleGrid } from '@chakra-ui/react';
import { useTabNavigation } from 'hooksV2/useTabNavigation';
import { ClientDetailsActivities } from 'pagesV2/ClientDetails/components/activities';
import { ClientDetailsAdditionalInformations } from 'pagesV2/ClientDetails/components/additionalInformations';
import { ClientDetailsCommentedAreas } from 'pagesV2/ClientDetails/components/commentedAreas';
import { ClientDetailsLastActivities } from 'pagesV2/ClientDetails/components/lastActivities';
import { ClientDetailsMeetingSummary } from 'pagesV2/ClientDetails/components/meetingsSummary';
import { ClientDetailsNextActivities } from 'pagesV2/ClientDetails/components/nextActivities';
import { ClientType } from 'sharedV2/types/client';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsContactSubPage: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { TabsDiv, selectedTab } = useTabNavigation({
    tabs: ['Próximas Atividades', 'Últimas Atividades'],
    initialTab: 'Próximas Atividades',
  });

  return (
    <SimpleGrid
      columns={2}
      gap={5}
      flex={1}
      h="100%"
      w="100%"
      gridTemplateRows="100%"
    >
      <SimpleGrid
        columns={1}
        gap={3}
        gridTemplateRows="auto auto minmax(100px, 1fr) auto"
      >
        <ClientDetailsActivities client={client} />
        <TabsDiv />

        {selectedTab === 'Próximas Atividades' && (
          <ClientDetailsNextActivities client={client} />
        )}

        {selectedTab === 'Últimas Atividades' && (
          <ClientDetailsLastActivities client={client} />
        )}

        <ClientDetailsMeetingSummary client={client} />
      </SimpleGrid>
      <SimpleGrid
        columns={1}
        gridTemplateRows="minmax(100px, 1fr) minmax(100px, 1fr)"
        gap={3}
      >
        <ClientDetailsCommentedAreas client={client} />
        <ClientDetailsAdditionalInformations client={client} />
      </SimpleGrid>
    </SimpleGrid>
  );
};
