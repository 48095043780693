import { Flex, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { HeadDataType, TableBase } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { IMonthlyCommissionTotal } from 'pages/Remuneration/interfaces';
import { useState } from 'react';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';
import { DateUtils } from 'utilsV2/Date';
import { ClientDetailsRevenueDetailsModal } from './components/revenueDetailsModal';

type PropsType = {
  client: ClientType;
  data: IMonthlyCommissionTotal[] | undefined;
};

export const ClientDetailsRevenueTable: React.FC<PropsType> = ({
  data,
  client,
}): JSX.Element => {
  const isOpenRevenueDetailsModalFilter = useSimpleQueryFilter(
    'isOpenRevenueDetailsModal'
  );

  const [revenueDate, setRevenueDate] = useState<string>('');

  const tableHeadData: HeadDataType[] = [
    {
      columnName: 'Mês',
      widthPercentage: 100,
    },
    {
      columnName: 'Comissão AAI',
      widthPercentage: 100,
    },
  ];

  const handleShowRevenueDetails = (revenue: IMonthlyCommissionTotal) => {
    setRevenueDate(revenue.date);
    isOpenRevenueDetailsModalFilter.handleChangeFilterValue('true');
  };

  return (
    <>
      <TableBase
        headData={tableHeadData}
        overflowY="auto"
        maxH="calc(100vh - 250px)"
        className="thinScrollbar"
      >
        {data &&
          data.map((item, index) => (
            <TableRow
              key={index}
              _hover={useCommonHover()}
              headData={tableHeadData}
              onClick={() => handleShowRevenueDetails(item)}
            >
              <Flex>
                <Text>{DateUtils.format(item.date, "MMMM 'de' yyyy")}</Text>
              </Flex>
              <Flex>
                <Text>{CurrencyUtils.format(item.employeeCommission)}</Text>
              </Flex>
            </TableRow>
          ))}
      </TableBase>

      <ModalWrapper
        isOpen={isOpenRevenueDetailsModalFilter.filterValue === 'true'}
        onClose={() => isOpenRevenueDetailsModalFilter.handleDeleteParam()}
      >
        <ClientDetailsRevenueDetailsModal
          client={client}
          revenueDate={revenueDate}
          isOpenRevenueDetailsModalFilter={isOpenRevenueDetailsModalFilter}
        />
      </ModalWrapper>
    </>
  );
};
