import { Dropdown } from 'componentsV2/elements/dropdown';
import { Modal } from 'componentsV2/elements/modal';
import { ActionTypeEnum } from 'componentsV2/elements/modal/components/modalAction';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { useParams } from 'react-router-dom';
import { LeadMundoInvestStatusEnum } from 'sharedV2/types/leadMundoInvest';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { showToast } from 'utilsV2/showToast';
import { declineMeetingOptions, DeclineOfferOptionsEnum } from './data';

type ModalOptions = {
  title?: string;
  declineOfferReasonInitialValue?: DeclineOfferOptionsEnum;
};

type PropsType = {
  lead: LeadParsedType | undefined;
  leadType: LeadTypeEnum;
  shouldOnlyDeclineMeeting?: boolean;
  onModalCloseCallback?: () => void;
  modalOptions?: ModalOptions;
};

export const DeclineLeadOfferModal: React.FC<PropsType> = ({
  lead,
  leadType,
  onModalCloseCallback,
  shouldOnlyDeclineMeeting = false,
  modalOptions,
}): JSX.Element => {
  const { id: leadId } = useParams<{ id: string }>();

  const { deleteParam } = useQueryParams();

  const {
    filterValue: declineOfferReason,
    handleChangeFilterValue: handleChangeDeclineOfferReason,
  } = useSimpleQueryFilter<DeclineOfferOptionsEnum>('declineOfferReason', {
    initialValue: modalOptions?.declineOfferReasonInitialValue,
  });

  const { goBack } = useNavigation();

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions();

  const { mutateAsync: updateLead, isLoading: isLoadingUpdateLead } =
    useUpdateLead();

  const isLoading = isLoadingUpdateLead || isLoadingMeetingAction;

  const handleCloseModal = () => {
    deleteParam('isOpenDeclineOfferModal');

    if (onModalCloseCallback) onModalCloseCallback();
  };

  const handleDeclineLeadOffer = async () => {
    if (!declineOfferReason) return showToast('Escolha uma opção de declínio');

    if (shouldOnlyDeclineMeeting) {
      await meetingAction({
        action: MeetingActionEnum.DECLINED,
        leadType,
        meetingId: lead?.meeting?.id as string,
        declinedReason: declineOfferReason,
      });

      return;
    }

    await updateLead({
      type: leadType,
      leadId: leadId || (lead?.id as string),
      status: LeadMundoInvestStatusEnum.FINALIZADO,
      declinedReason: declineOfferReason,
    });

    goBack();

    return showToast('Declínio realizado com sucesso!');
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title={modalOptions?.title || 'Declínio de oferta'} />
        <Modal.CloseIcon onClick={handleCloseModal} />
      </Modal.Header>

      <Dropdown.Root
        value={declineOfferReason}
        onChange={event =>
          handleChangeDeclineOfferReason(
            event.target.value as DeclineOfferOptionsEnum
          )
        }
      >
        <Dropdown.Options
          options={declineMeetingOptions}
          placeholder="Escolha uma das opções"
        />
      </Dropdown.Root>

      <Modal.Actions>
        <Modal.Action
          text="Não"
          onClick={handleCloseModal}
          actionType={ActionTypeEnum.SECONDARY}
        />

        <Modal.Action
          text="Sim"
          onClick={handleDeclineLeadOffer}
          isLoading={isLoading}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
