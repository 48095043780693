import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { ITables } from 'pages/GenerationalVariables/interfaces';
import { PageWrapper } from 'components/elements/wrappers';
import { LoadingSpinner } from 'components/elements/others';
import { IEmployeesDistributionInfo, IPoolList } from './interfaces';
import { Header } from './components/Header';
import { Table } from './components/Table';

export const DistributionOfMinedLeadsTable: React.FC = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [tables, setTables] = useState<ITables[]>([]);
  const [tableId, setTableId] = useState('');
  const [employeesData, setEmployeesData] = useState<
    IEmployeesDistributionInfo[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allPoolLists, setAllPoolLists] = useState<IPoolList[]>([]);

  useEffect(() => {
    getAllTables();
    getAllLists();
  }, []);

  useEffect(() => {
    if (tableId !== '') {
      getLeadsByTableId(tableId);
    } else {
      handleGetAllEmployees();
    }
  }, [tableId]);

  const getAllTables = async () => {
    setIsLoading(true);
    try {
      const response = await hubApiCaptation.getAllTables();
      setTables(response);
    } catch (err) {
      toast.dark(
        'Não foi possível carregar todas as mesas.Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };

  const handleGetAllEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await hubApiCaptation.getAllEmployees();
      setEmployeesData(response);
    } catch (err) {
      toast.dark(
        'Não foi possível carregar os dados dos usuários.Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };
  const getLeadsByTableId = async (tableId: string) => {
    setIsLoading(true);
    try {
      const getEmployeesData = await hubApiCaptation.getLeadsByTableId(tableId);
      setEmployeesData(getEmployeesData);
    } catch (err) {
      toast.dark(
        'Não foi possível carregar os dados da mesa selecionada.Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };

  const getAllLists = async () => {
    setIsLoading(true);
    try {
      const lists = await hubApiCaptation.getAllLists();
      setAllPoolLists(lists);
    } catch (error) {
      toast.dark(
        'Não foi possível carregar as opções do dropdown.Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };

  return (
    <PageWrapper p={5}>
      <Flex flexDir="column" w="100%" maxW="1080px" mx="auto">
        <Header
          setShowDetailsModal={setShowDetailsModal}
          tables={tables}
          setTableId={setTableId}
          allPoolLists={allPoolLists}
          showDetailsModal={showDetailsModal}
          handleGetAllEmployees={handleGetAllEmployees}
        />

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            allPoolLists={allPoolLists}
            employeesData={employeesData}
            setEmployeesData={setEmployeesData}
          />
        )}
        {employeesData.length === 0 && (
          <Flex
            height="calc(100vh - 292px)"
            justifyContent="center"
            alignItems="center"
            fontStyle="italic"
            fontSize="md"
          >
            <Text>Selecione uma mesa</Text>
          </Flex>
        )}
      </Flex>
    </PageWrapper>
  );
};
