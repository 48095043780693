import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { Button } from 'componentsV2/elements/buttons';
import { Input } from 'componentsV2/elements/input';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useAuth } from 'hooksV2/useAuth';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import mundoInvestLogo from 'shared/assets/images/logo-mi.svg';
import { SendRecoverPasswordEmailLogin } from './components/sendRecoverPasswordEmail';

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'O campo email é obrigatório' })
    .email({ message: 'O campo email deve conter um email válido' }),
  password: z.string().min(1, { message: 'O campo senha é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const Login = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { dispatchLogin } = useAuth({
    setIsLoading: () => setIsLoading(false),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const handleLogin: SubmitHandler<FormFieldsType> = async data => {
    try {
      setIsLoading(true);
      dispatchLogin(data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper
      paddingTop="2.5rem"
      heigth="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box.Glass w="650px" paddingY="1rem">
        <Flex flexDirection="column" gap={4} w="100%">
          <img
            style={{
              width: '160px',
              marginBottom: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={mundoInvestLogo}
            alt="ewz logo"
          />
          <Flex flexDirection="column" gap={2}>
            <Text color="rgba(70, 70, 70, 1)" fontSize="xl">
              Bem vindo ao HUB
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              Faça login com o seu email e senha
            </Text>
          </Flex>

          <Flex w="100%" flexDirection="column" gap={3}>
            <Input.Root w="100%">
              <Input.TextField
                placeholder="Email"
                h="40px"
                {...register('email')}
              />
              <Input.ErrorMessage message={errors.email?.message} />
            </Input.Root>

            <Input.Root>
              <Input.TextField
                placeholder="Senha"
                h="40px"
                type="password"
                {...register('password')}
              />
              <Input.ErrorMessage message={errors.password?.message} />
            </Input.Root>
          </Flex>

          <SendRecoverPasswordEmailLogin />

          <Flex justifyContent="end">
            <Button.Primary
              onClick={handleSubmit(handleLogin)}
              isLoading={isLoading}
            >
              Entrar
            </Button.Primary>
          </Flex>
        </Flex>
      </Box.Glass>
    </PageWrapper>
  );
};
