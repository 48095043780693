import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  CreateAportRequestType,
  hubApiClients,
} from 'services/hubApi/classes/HubApiClients';
import { ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';
import { z } from 'zod';

type PropsType = {
  client: ClientType;
  isOpenAddAportModalFilter: UseSimpleFilterResponseType;
};

const schema = z.object({
  date: z.string().min(1, { message: 'O campo data é obrigatório' }),
  value: z.string().min(1, { message: 'O campo valor é obrigatório' }),
  description: z
    .string()
    .min(1, { message: 'O campo descrição é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const AddClientAportModal: React.FC<PropsType> = ({
  client,
  isOpenAddAportModalFilter,
}): JSX.Element => {
  const { mutateAsync: createAport, isLoading: isLoadingCreateAport } =
    useMutation({
      mutationKey: ['createClientAport'],
      mutationFn: async (data: CreateAportRequestType) => {
        const response = await hubApiClients.createAport(data);
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);

        isOpenAddAportModalFilter.handleDeleteParam();
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const handleAddAport: SubmitHandler<FormFieldsType> = async data => {
    await createAport({
      btgCode: client.btgCode,
      description: data.description,
      value: CurrencyUtils.formatValueFromInput(data.value),
      date: data.date,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Adicionar Aporte" />
        <Modal.CloseIcon
          onClick={() => isOpenAddAportModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={4}>
        <Input.Root>
          <Input.Label text="Data" />
          <Input.Date {...register('date')} />
          <Input.ErrorMessage message={errors.date?.message} />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Valor" />
          <Input.Currency {...register('value')} />
          <Input.ErrorMessage message={errors.value?.message} />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Descrição" />
          <Input.TextField {...register('description')} />
          <Input.ErrorMessage message={errors.description?.message} />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Adicionar"
          onClick={handleSubmit(handleAddAport)}
          isLoading={isLoadingCreateAport}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
