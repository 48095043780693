import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { ModalWrapper } from 'components/elements/wrappers';
import { ConfirmationModal } from 'componentsV2/@shared/modals/confirmationModal';
import { Box } from 'componentsV2/elements/boxes';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { useDeleteClientReminder } from 'hooksV2/useDeleteClientReminder';
import { useNavigation } from 'hooksV2/useNavigation';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { AportDetailsModal } from 'pagesV2/ClientDetails/components/aportDetailsModal';
import { ReminderDetailsModal } from 'pagesV2/ClientDetails/components/reminderDetailsModal';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { Icons } from 'sharedV2/icons';
import { formateDateAndTime } from 'utils/formatDate';
import { NextActivitiesType } from '../..';

type PropsType = {
  activity: NextActivitiesType;
};

export const ClientDetailsNextActivitiesCard: React.FC<PropsType> = ({
  activity,
}): JSX.Element => {
  const { redirect } = useNavigation();
  const [activityIdToDelete, setActivityIdToDelete] = useState<string>('');

  const isOpenReminderDetailsModalFilter = useSimpleQueryFilter(
    'isOpenReminderDetailsModal'
  );

  const isOpenAportDetailsModalFilter = useSimpleQueryFilter(
    'isOpenAportDetailsModal'
  );

  const isOpenConfirmDeleteActivityModalFilter = useSimpleQueryFilter(
    'isOpenConfirmDeleteActivityModal'
  );

  const { mutateAsync: deleteAport, isLoading: isLoadingDeleteAport } =
    useMutation({
      mutationKey: ['deleteClientAport'],
      mutationFn: async (id: string) => {
        const response = await hubApiClients.deleteAport(id);
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'clientDetails',
          activity.btgCode,
        ]);
      },
    });

  const { mutateAsync: deleteMeeting, isLoading: isLoadingDeleteMeeting } =
    useMutation({
      mutationKey: ['deleteClientMeeting'],
      mutationFn: async (id: string) => {
        const response = await hubApiClients.deleteMeeting(id);
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'clientDetails',
          activity.btgCode,
        ]);
      },
    });

  const { mutateAsync: deleteReminder, isLoading: isLoadingDeleteReminder } =
    useDeleteClientReminder({
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'clientDetails',
          activity.btgCode,
        ]);
      },
    });

  const handleCardClick = () => {
    if (activity.type === 'reminder') {
      isOpenReminderDetailsModalFilter.handleChangeFilterValue('true');
    }

    if (activity.type === 'aport') {
      isOpenAportDetailsModalFilter.handleChangeFilterValue('true');
    }

    if (activity.type === 'meeting') {
      redirect(`/v2/client/${activity.btgCode}/meeting/${activity.id}`);
    }
  };

  const handleDeleteActivity = async () => {
    if (activity.type === 'reminder') {
      await deleteReminder(activity.id);
    }
    if (activity.type === 'aport') {
      await deleteAport(activity.id);
    }
    if (activity.type === 'meeting') {
      await deleteMeeting(activity.id);
    }

    isOpenConfirmDeleteActivityModalFilter.handleDeleteParam();
  };

  const isLoadingDeleteActivity =
    isLoadingDeleteReminder || isLoadingDeleteAport || isLoadingDeleteMeeting;

  return (
    <>
      <Box.Opaque
        p={3}
        gap={4}
        flexDirection="row"
        alignItems="center"
        _hover={useCommonHover()}
        cursor="pointer"
        onClick={handleCardClick}
      >
        <Icons.CALENDAR color="rgba(70, 70, 70, 1)" />
        <Flex flexDirection="column" w="100%">
          <Flex justifyContent="space-between" alignItems="center">
            <Tooltip text={activity.title}>
              <Text color="rgba(70, 70, 70, 1)" isTruncated maxW="290px">
                {activity.title}
              </Text>
            </Tooltip>

            <Icons.TRASH
              onClick={event => {
                event.stopPropagation();

                setActivityIdToDelete(activity.id);

                isOpenConfirmDeleteActivityModalFilter.handleChangeFilterValue(
                  'true'
                );
              }}
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              {formateDateAndTime(activity.date)}
            </Text>
          </Flex>
        </Flex>
      </Box.Opaque>

      {activityIdToDelete === activity.id && (
        <ModalWrapper
          isOpen={isOpenConfirmDeleteActivityModalFilter.filterValue === 'true'}
          onClose={() =>
            isOpenConfirmDeleteActivityModalFilter.handleDeleteParam()
          }
        >
          <ConfirmationModal
            title="Deseja realmente excluir essa atividade?"
            subtitle="Essa operação é irreversível. Somente confirme se tiver certeza!"
            isOpen={
              isOpenConfirmDeleteActivityModalFilter.filterValue === 'true'
            }
            onClose={() =>
              isOpenConfirmDeleteActivityModalFilter.handleDeleteParam()
            }
            onConfirmAction={handleDeleteActivity}
            isLoading={isLoadingDeleteActivity}
          />
        </ModalWrapper>
      )}

      {activity.type === 'reminder' && (
        <ModalWrapper
          isOpen={isOpenReminderDetailsModalFilter.filterValue === 'true'}
          onClose={() => isOpenReminderDetailsModalFilter.handleDeleteParam()}
        >
          <ReminderDetailsModal
            reminder={activity}
            isOpenReminderDetailsModalFilter={isOpenReminderDetailsModalFilter}
          />
        </ModalWrapper>
      )}

      {activity.type === 'aport' && (
        <ModalWrapper
          isOpen={isOpenAportDetailsModalFilter.filterValue === 'true'}
          onClose={() => isOpenAportDetailsModalFilter.handleDeleteParam()}
        >
          <AportDetailsModal
            aport={activity}
            isOpenAportDetailsModalFilter={isOpenAportDetailsModalFilter}
          />
        </ModalWrapper>
      )}
    </>
  );
};
