/* eslint-disable no-case-declarations */
import { IFunnelData } from '../dtos';

interface IProps {
  data: IFunnelData[];
  label: string;
}

export const calcPercentage = ({ data, label }: IProps): string => {
  let father;
  let percentage = '0.0';
  switch (label) {
    case 'totalLeadsReceived':
    case 'listaLeads':
    case 'minedLeads':
    case 'pjLeads':
    case 'meetingsPurchased':
    case 'totalLeadsFromTable':
      return '100';

    case 'suitability':
      father = data.find(
        item => item.label === 'totalLeadsReceived' || 'meetingsPurchased'
      ) as IFunnelData;
      if (father.value === 0) {
        percentage = '0.0';
        return percentage;
      }
      const suitability = data.find(
        item => item.label === label
      ) as IFunnelData;
      if (father.value === 0) {
        percentage = '0.0';
        return percentage;
      }
      percentage = ((suitability.value / father.value) * 100).toFixed(1);
      return percentage;
    case 'presentation':
      father = data.find(item => item.label === 'suitability') as IFunnelData;
      const presentation = data.find(
        item => item.label === label
      ) as IFunnelData;
      if (father.value === 0) {
        percentage = '0.0';
        return percentage;
      }
      percentage = ((presentation.value / father.value) * 100).toFixed(1);
      return percentage;
    case 'waitingOpenAccount':
      father = data.find(item => item.label === 'presentation') as IFunnelData;
      const waitingOpenAccount = data.find(
        item => item.label === label
      ) as IFunnelData;
      if (father.value === 0) {
        percentage = '0.0';
        return percentage;
      }
      percentage = ((waitingOpenAccount.value / father.value) * 100).toFixed(1);
      return percentage;
    case 'waitingAllocation':
      father = data.find(
        item => item.label === 'waitingOpenAccount'
      ) as IFunnelData;
      if (father.value === 0) {
        percentage = '0.0';
        return percentage;
      }
      const waitingAllocation = data.find(
        item => item.label === label
      ) as IFunnelData;
      percentage = ((waitingAllocation.value / father.value) * 100).toFixed(1);
      return percentage;

    case 'allocated':
      father = data.find(
        item => item.label === 'waitingAllocation'
      ) as IFunnelData;
      if (father.value === 0) {
        percentage = '0.0';
        return percentage;
      }
      const allocated = data.find(item => item.label === label) as IFunnelData;
      percentage = ((allocated.value / father.value) * 100).toFixed(1);
      return percentage;
    default:
      return '0.0';
  }
};
