import { Flex, Grid, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import {
  MeetingsPoolTabEnum,
  MeetingsPoolTabTitleEnum,
  useMeetingsPoolContext,
} from 'pagesV2/MeetingsPool/context/useMeetingsPoolContext';
import { GetMeetingsPoolResponseType } from 'services/hubApi/classes/HubApiCaptation';
import { CurrencyUtils } from 'utilsV2/Currency';

const tabs = [
  {
    title: MeetingsPoolTabTitleEnum.LATE,
    name: MeetingsPoolTabEnum.LATE,
  },
  {
    title: MeetingsPoolTabTitleEnum.CALLBACK,
    name: MeetingsPoolTabEnum.CALLBACK,
  },
  {
    title: MeetingsPoolTabTitleEnum.SUITABILITY,
    name: MeetingsPoolTabEnum.SUITABILITY,
  },
  {
    title: MeetingsPoolTabTitleEnum.PRESENTATION,
    name: MeetingsPoolTabEnum.PRESENTATION,
  },
  {
    title: MeetingsPoolTabTitleEnum.ALLOCATION,
    name: MeetingsPoolTabEnum.ALLOCATION,
  },
  {
    title: MeetingsPoolTabTitleEnum.ACCOUNT,
    name: MeetingsPoolTabEnum.ACCOUNT,
  },
];

type PropsType = {
  meetings: GetMeetingsPoolResponseType | undefined;
};

export const MeetingsPoolTabs: React.FC<PropsType> = ({
  meetings,
}): JSX.Element => {
  const { handleChangeSelectedTab } = useMeetingsPoolContext();

  const countLateMeetings = meetings?.late.meetings.length;

  const handleGetTotalPipeValueForEachTab = (
    tabName: MeetingsPoolTabEnum
  ): number => {
    if (!meetings) return 0;

    return meetings[tabName].pipeValue;
  };

  return (
    <Grid w="20%" gap={2} gridTemplateRows="36px auto auto auto auto auto">
      {tabs.map(tab => {
        const totalPipeValue = handleGetTotalPipeValueForEachTab(tab.name);

        if (tab.title === MeetingsPoolTabTitleEnum.LATE) {
          return (
            <Box.Glass
              key={tab.title}
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleChangeSelectedTab(tab.name)}
              position="relative"
            >
              <Flex
                backgroundColor="red.500"
                justifyContent="center"
                alignItems="center"
                borderRadius="full"
                position="absolute"
                top="-10px"
                right="-15px"
                padding="1px"
                px="6px"
              >
                <Text color="rgba(70, 70, 70, 1)" fontSize="sm">
                  {countLateMeetings}
                </Text>
              </Flex>
              <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
                {tab.title}
              </Text>
            </Box.Glass>
          );
        }

        return (
          <Box.Glass
            key={tab.title}
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleChangeSelectedTab(tab.name)}
            flexDirection="column"
          >
            <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
              {tab.title}
            </Text>
            <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
              {CurrencyUtils.format(totalPipeValue)}
            </Text>
          </Box.Glass>
        );
      })}
    </Grid>
  );
};
