import React from 'react';

import { CardToLink } from 'components/elements/cards';
import { SectionOptionsList } from 'components/elements/others';

import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { fundsProducts, otherProducts } from './data';

const Products: React.FC = () => {
  return (
    <PageWrapper alignItems="center" paddingTop="4.5rem">
      <SectionOptionsList title="Outros">
        {otherProducts.map((item, index: number) => (
          <CardToLink
            external={
              item.title === 'Apresentações Gestoras' ||
              item.title === 'Apresentação de Produtos' ||
              item.title === 'Reunião RV' ||
              item.title === 'Café com Diamantes'
            }
            key={index}
            title={item.title}
            link={item.link}
            Icon={item.icon}
          />
        ))}
      </SectionOptionsList>

      <SectionOptionsList title="Fundos de Investimentos">
        {fundsProducts.map((item, index: number) => (
          <CardToLink
            key={index}
            title={item.title}
            link={item.link}
            Icon={item.icon}
          />
        ))}
      </SectionOptionsList>
    </PageWrapper>
  );
};

export default Products;
