import { LeadManualType } from 'sharedV2/types/leadManual';
import {
  LeadMundoInvestStatusEnum,
  LeadMundoInvestType,
} from 'sharedV2/types/leadMundoInvest';
import { LeadPJType } from 'sharedV2/types/leadPJ';
import { LeadTypes } from 'sharedV2/types/leadTypes';
import { LeadUploadType } from 'sharedV2/types/leadUpload';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { DateUtils } from 'utilsV2/Date';
import { LeadUtils } from 'utilsV2/Lead';

export class LeadBussinessLogic {
  public static MAX_CONTACT_ATTEMPTS_BEFORE_DECLINE_LEAD_MODAL = 5;

  private _lead: LeadTypes;

  constructor(lead: LeadTypes) {
    this._lead = lead;
  }

  public isDeclinedLead(): boolean {
    return this._lead.status === LeadMundoInvestStatusEnum.FINALIZADO;
  }

  public shouldDisplayLeadActions(): boolean {
    return !!(
      LeadUtils.isLeadMundoInvest(this._lead) &&
      this._lead.actions &&
      !this._lead.meeting
    );
  }

  public shouldBlockUpdateContactAttempts(): boolean {
    const WAIT_TIME_TO_UPDATE_CONTACT_ATTEMPTS_IN_MINUTES = 30;

    const lastTimeContactAttemptsHaveBeenUpdated = localStorage.getItem(
      this._lead.id
    );

    if (lastTimeContactAttemptsHaveBeenUpdated) {
      const timeDiff =
        DateUtils.differenceInMinutes(
          new Date(+lastTimeContactAttemptsHaveBeenUpdated).toISOString()
        ) * -1;

      if (timeDiff < WAIT_TIME_TO_UPDATE_CONTACT_ATTEMPTS_IN_MINUTES) {
        return true;
      }
    }

    return false;
  }

  public saveLastTimeContactAttemptsHaveBeenUpdated(): void {
    localStorage.setItem(this._lead.id, Date.now().toString());
  }

  public isLeadAbleToBeTransfferedToASalesHunter(): boolean {
    return LeadUtils.isLeadMundoInvest(this._lead);
  }

  public shouldDisplayConfirmationModalWhenLeadIsBeingTransferred(): boolean {
    const lead = this._lead as
      | LeadUploadType
      | LeadManualType
      | LeadPJType
      | LeadMundoInvestType; // Developers guarantee that only leads with these types will call this function

    if (LeadUtils.isLeadPJ(lead))
      return (
        lead.status === LeadMundoInvestStatusEnum.FINALIZADO &&
        lead.meetings[0].status === MeetingStatusEnum.DECLINED
      );

    return (
      this.isDeclinedLead() &&
      lead.meeting?.status === MeetingStatusEnum.DECLINED
    );
  }
}
