import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';

import history from 'services/history';
import { IReduxState } from 'shared/interfaces';
import { EwzLogo } from 'components/modules/EwzLogo';

import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { useAgreementsContext } from 'hooks';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';

import { useQuery } from 'react-query';
import hubApi from 'services/hubApi';
import { toast } from 'react-toastify';
import { formatMibs } from 'utils/formatMibs';
import { useEmployeeMibs } from 'pages/MyMIBs/hooks/useEmployeeMibs';
import { MIBSLogo } from 'shared/assets/icons/MIBSLogo';
import {
  captationPagesDefault,
  captationPagesOptions,
  navbarPages,
  operationalPages,
  userPages,
} from './data';
import { SubMenu } from './SubMenu';

interface ISubMenuProps {
  title: string;
  path: string;
  permission: string;
}

const NavbarComponent: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [customNavbarPages, setCustomNavbarPages] = useState(navbarPages);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [captationSubMenu, setCaptationSubMenu] = useState<ISubMenuProps[]>([
    { title: 'Reuniões', path: '/captation/meetings', permission: 'userOACap' },
  ]);

  const { data: allPowerBIsDashboards } = useQuery({
    queryKey: ['allPowerBIsDashboards'],
    queryFn: async () => {
      const response = await hubApi.getPowerBIDashboards({});
      return response;
    },
    onError: () => {
      toast.dark('Erro ao carregar Power BI.');
    },
  });

  const { data: employeeMibs } = useEmployeeMibs(user.id);

  const currentPage = useLocation();

  const handleClickMobileMenu = () => setMobileMenu(!mobileMenu);

  const checkIfUserIsSH = async () => {
    let levelAAI: 'pool' | 'sh' = 'pool';
    let accessLevel = '';

    if (
      verifyEmployeePermission({
        user,
        areaCod: ['ASS'],
        permissionCod: ['ASS', 'GTM', 'GTR'],
      }) ||
      (await hubApiCaptation.checkSh('AAI', user.id))
    ) {
      levelAAI = 'pool';
      accessLevel = 'AAI';
    }

    if (
      verifyEmployeePermission({
        user,
        areaCod: ['ASS'],
        permissionCod: ['TRA'],
      }) ||
      (await hubApiCaptation.checkSh('SH', user.id))
    ) {
      levelAAI = 'sh';
      if (accessLevel === 'AAI') {
        accessLevel = 'AAI&SH';
      }
    }

    if (user.position === 'MASTER' || accessLevel === 'AAI&SH') {
      setCaptationSubMenu([
        ...captationPagesDefault,
        ...captationPagesOptions.sh,
        ...captationPagesOptions.pool,
      ]);
    } else {
      setCaptationSubMenu([
        ...captationPagesDefault,
        ...captationPagesOptions[levelAAI],
      ]);
    }
  };

  useEffect(() => {
    if (allPowerBIsDashboards && allPowerBIsDashboards.dashboards.length > 0) {
      const dashboardsAlreadyExists = customNavbarPages.some(
        item => item.title === 'Dashboards'
      );

      if (!dashboardsAlreadyExists)
        setCustomNavbarPages(prev => [
          { title: 'Dashboards', path: '/dashboards' },
          ...prev,
        ]);
    } else {
      const filter = customNavbarPages.filter(
        item => item.title !== 'Dashboards'
      );
      setCustomNavbarPages(filter);
    }
  }, [allPowerBIsDashboards]);

  useEffect(() => {
    checkIfUserIsSH();
  }, [user]);

  const handleGetSpecificLeadTypeArr = useAgreementsContext();

  return (
    <Flex
      as="header"
      alignItems="center"
      height="80px"
      justifyContent="space-between"
      boxShadow="0px 4px 20px 0px rgb(0, 0, 0)"
      paddingX={{ base: '18px', md: '24px', lg: '36px' }}
      background="#fff"
      borderBottom="1px solid rgba(255, 255, 255, 0.12)"
    >
      <Flex alignItems="center" gap={8}>
        <EwzLogo />

        <Flex
          border="1px solid #FA8528"
          borderRadius={8}
          px={2}
          py={1}
          alignItems="center"
          gap={2}
          cursor="pointer"
          onClick={() => history.push('/user/mibs')}
        >
          <MIBSLogo />
          <Text color="black" fontSize="sm">
            {formatMibs(
              employeeMibs ? employeeMibs[0].mibs + employeeMibs[1].mibs : 0
            )}{' '}
            MIBs
          </Text>
        </Flex>
      </Flex>

      <Flex
        as="nav"
        height="100%"
        width={{ base: '100vw', md: '50%', lg: 'fit-content' }}
        zIndex={{ base: '10', lg: 'inherit' }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
        background={{ base: 'background.700', lg: 'transparent' }}
        position={{ base: 'absolute', lg: 'initial' }}
        top={{ base: '80px' }}
        right="0"
        pt={{ base: '24px', lg: '0' }}
        display={{ base: `${mobileMenu ? 'inherit' : 'none'}`, lg: 'inherit' }}
      >
        <Flex
          as="ul"
          height={{ base: 'fit-content', lg: '100%' }}
          flexDir={{ base: 'column', lg: 'row' }}
          alignItems="center"
          listStyleType="none"
          gridGap="3.5"
          color="white"
        >
          {customNavbarPages.map((route, index) => {
            return (
              <Flex
                as="li"
                key={index}
                height="100%"
                alignItems="center"
                fontSize={{ base: 'sm', '2xl': 'md' }}
                pt={{ '2xl': '1px' }}
                borderBottom={
                  currentPage.pathname === route.path ? '1px solid' : 'none'
                }
                borderColor={
                  currentPage.pathname === route.path
                    ? 'primary.500'
                    : 'transparent'
                }
                transition="color 0.15s ease-in"
                cursor="pointer"
                sx={{ '>a:hover': { color: 'inherit' } }}
                _hover={{ color: 'primary.400' }}
                onClick={() => history.push(route.path)}
              >
                <Text as="span" color="black">
                  {route.title}
                </Text>
              </Flex>
            );
          })}

          {/* <SubMenu menuPages={myClientsPages} title="Meus Clientes" /> */}

          <SubMenu menuPages={captationSubMenu} title="Captação" />

          <SubMenu menuPages={operationalPages} title="Operacional" />

          <SubMenu
            menuPages={userPages}
            title={`Bem-vindo, ${formatToCapitalized(user.name.split(' ')[0])}`}
            enableLogout
            haveContract={
              handleGetSpecificLeadTypeArr.allAgreements.filter(
                item => !item.accept
              ).length > 0
            }
          />
        </Flex>
      </Flex>

      <Flex
        display={{ base: 'inherit', lg: 'none' }}
        cursor="pointer"
        color="white"
        onClick={handleClickMobileMenu}
      >
        {mobileMenu ? <FaTimes /> : <FaBars />}
      </Flex>
    </Flex>
  );
};

export default NavbarComponent;
