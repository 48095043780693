import { Flex, SimpleGrid, Text } from '@chakra-ui/react';

import {
  ClientAportStatusEnum,
  ClientAportType,
  ClientMeetingStatusEnum,
  ClientMeetingType,
  ClientRemindersType,
  ClientType,
} from 'sharedV2/types/client';
import { ClientDetailsNextActivitiesCard } from './components/card';

type PropsType = {
  client: ClientType;
};

export type NextActivitiesType =
  | (ClientRemindersType & { type: 'reminder' }) // We don't need to add the title here because a reminder already have it
  | (ClientMeetingType & { type: 'meeting'; title: string })
  | (ClientAportType & { type: 'aport'; title: string });

export const ClientDetailsNextActivities: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const combinedActivities: NextActivitiesType[] = [
    ...client.reminders.map(reminder => ({
      type: 'reminder' as const,
      ...reminder,
    })),
    ...client.meetings.map(meeting => ({
      type: 'meeting' as const,
      title: 'Reunião',
      ...meeting,
    })),
    ...client.aports.map(aport => ({
      type: 'aport' as const,
      title: 'Aporte',
      ...aport,
    })),
  ];

  const nextActivities = combinedActivities.filter(activity => {
    if (activity.type === 'meeting') {
      return activity.status !== ClientMeetingStatusEnum.CONCLUDED;
    }

    if (activity.type === 'aport') {
      return activity.status !== ClientAportStatusEnum.CONCLUDED;
    }

    return activity;
  });

  return (
    <Flex flexDirection="column" gap={3}>
      {nextActivities.length === 0 && (
        <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
          Não existem próximas atividades
        </Text>
      )}

      <SimpleGrid
        columns={2}
        gap={2}
        overflowY="auto"
        className="thinScrollbar"
      >
        {nextActivities.map((activity, index) => (
          <ClientDetailsNextActivitiesCard key={index} activity={activity} />
        ))}
      </SimpleGrid>
    </Flex>
  );
};
