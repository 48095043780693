import { Flex } from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { LeadInfos } from '../CommonLeadContent';
import {
  LeadMundoInvestPoolActionType,
  PoolMILeadCard,
} from '../../../elements/cards/PoolMILeadCard';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../../../../components/elements/buttons';
import { BuyLeadsModal } from '../../modals/BuyLeadsModal';
import { IReduxState } from '../../../../../../shared/interfaces';
import { LoadingSpinner } from '../../../../../../components/elements/others';
import { LeadsPoolMundoInvestHistory } from './components/leadsHistory';
import { MainPageTitle } from '../../../../../../components/elements/texts';

interface IRangeSlider {
  [key: number]: string;
}

export const LeadsPoolMI: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [showSearchConfigCard, setShowSearchConfigCard] = useState(true);
  const [rangeMinPL, setRangeMinPL] = useState('');
  const [rangeMaxPL, setRangeMaxPL] = useState('');
  const [phoneDDD, setPhoneDDD] = useState('');
  const [startCaptation, setStartCaptation] = useState(true);
  const [estimateTotalToPay, setEstimateTotalToPay] = useState<number>(0);
  const [rangeSlider, setRangeSlider] = useState<IRangeSlider>({
    0: 'MI 50k',
    12: 'MI 100k',
    24: 'MI 250k',
    36: 'MI 500k',
    48: 'MI 750k',
    60: 'MI 1kk',
    72: 'MI 5kk',
  });
  const [shouldShowLeadsHistory, setShouldShowLeadsHistory] =
    useState<boolean>(false);

  const { data: employeeMibs, refetch: refetchMibs } = useQuery<any>(
    ['employeeMibs'],
    async () => {
      const response = await hubApiCaptation.getEmployeeMibs(user.id);

      return response;
    }
  );

  const updateLead = useMutation({
    mutationFn: async ({
      action,
      employeeId,
      leadId,
      declinedReason,
    }: {
      action: LeadMundoInvestPoolActionType;
      employeeId: string;
      leadId: string;
      declinedReason?: string;
    }) => {
      const response = await hubApiCaptation.updateLeadFromPoolMI({
        action,
        employeeId,
        leadId,
        declinedReason,
      });

      refetchLeadFromPool();

      return response;
    },
    onError: () => {
      toast.dark(
        'Não foi possível atualizar o lead, tente novamente em alguns minutos'
      );
    },
  });

  const {
    data: leadFromPoolMI,
    refetch: refetchLeadFromPool,
    isFetching,
  } = useQuery<LeadInfos>(
    ['leadFromPoolMI'],
    async () => {
      const employeeMibsUpdated = await refetchMibs();

      if (
        employeeMibsUpdated.data &&
        estimateTotalToPay * 0.5 > employeeMibsUpdated.data[0].mibs
      ) {
        setShowSearchConfigCard(true);
        toast.dark('Saldo de MIBs insuficiente para continuar a captação');
        return;
      }

      try {
        const response = await hubApiCaptation.getPoolMILeads({
          minPL: rangeMinPL,
          maxPL: rangeMaxPL,
          ddd: phoneDDD,
        });

        setShowSearchConfigCard(false);

        return response;
      } catch (error) {
        toast.dark(
          'Não encontramos leads para estes parâmetros, tente alterá-los'
        );
      }
    },
    {
      enabled: false,
      cacheTime: 0,
    }
  );

  const handleLeadAction = (
    action: LeadMundoInvestPoolActionType,
    declinedReason?: string
  ) => {
    updateLead.mutateAsync({
      action,
      employeeId: user.id,
      leadId: leadFromPoolMI?.id as string,
      declinedReason,
    });
  };

  const handleStartCaptation = (ddd: string, rangePL: string) => {
    if (employeeMibs && estimateTotalToPay * 0.5 > employeeMibs[0].mibs) {
      return toast.dark('Saldo de MIBs insuficiente para iniciar a captação');
    }

    if (!employeeMibs) {
      return toast.dark(
        'Não foi possível iniciar a captação. Erro ao localizar sua carteira'
      );
    }

    const rangeValues = rangePL.replace(/(\d+)k/g, '$1000').match(/\d+/g) || [];

    if (
      phoneDDD === ddd &&
      rangeMaxPL === rangeValues[1] &&
      rangeMinPL === rangeValues[0]
    ) {
      setStartCaptation(!startCaptation);
    }

    if (rangeValues[1] === '1000' || rangeValues[1] === '5000') {
      rangeValues[1] += '000';
    }

    if (rangeValues[0] === '1000' || rangeValues[0] === '5000') {
      rangeValues[0] += '000';
    }

    setPhoneDDD(ddd);
    setRangeMinPL(rangeValues[0]);
    setRangeMaxPL(rangeValues[1]);
  };

  const { isFetching: aaiLevelLoading } = useQuery<any>(
    ['aaiLevel'],
    async () => {
      const response = await hubApiCaptation.getPoolPublicLeads();
      return response;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        updateRangeSliderAccordingToAAILevel(
          Number(data.data.nivel) > 5 ? 5 : Number(data.data.nivel)
        );
      },
    }
  );

  const updateRangeSliderAccordingToAAILevel = (level: number): any => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(level)) {
      level = 2;
    }

    const defaultRangeSliderKeys = Object.keys(
      rangeSlider
    ) as unknown[] as number[];
    const rangeSliderAccordingAAILevel: IRangeSlider = {};

    for (let i = 0; i < level + 2; i++) {
      rangeSliderAccordingAAILevel[defaultRangeSliderKeys[i]] =
        rangeSlider[defaultRangeSliderKeys[i]];
    }

    setRangeSlider(rangeSliderAccordingAAILevel);

    return rangeSliderAccordingAAILevel;
  };

  useEffect(() => {
    if (rangeMinPL !== '') {
      refetchLeadFromPool();
    }
  }, [phoneDDD, rangeMinPL, rangeMaxPL, startCaptation]);

  return (
    <Flex flexDirection="column" w="75%" h="calc(100vh - 165px)">
      <BackPageButton />
      <MainPageTitle>Pool Mundo Invest</MainPageTitle>
      <Flex gap={2} mt={2} px={4}>
        <PrimaryButton
          size="xs"
          w="230px"
          onClick={() => {
            setShouldShowLeadsHistory(false);
            setShowSearchConfigCard(true);
          }}
        >
          Configurar filtros de busca
        </PrimaryButton>
        <SecondaryButton
          size="xs"
          w="200px"
          onClick={() => setShouldShowLeadsHistory(true)}
        >
          Histórico de Leads
        </SecondaryButton>
      </Flex>
      <Flex
        gap={1}
        wrap="wrap"
        h="100%"
        justifyContent="center"
        p={4}
        overflowY="hidden"
      >
        {showSearchConfigCard &&
          !aaiLevelLoading &&
          !shouldShowLeadsHistory && (
            <BuyLeadsModal
              setIsOpenBuyLeadsModal={setShowSearchConfigCard}
              poolMISession
              primaryButtonAction={handleStartCaptation}
              isLoadingPoolMISession={isFetching}
              estimateTotalToPay={estimateTotalToPay}
              setEstimateTotalToPay={setEstimateTotalToPay}
              employeeMIBs={employeeMibs && employeeMibs[0].mibs}
              rangeSlider={rangeSlider}
            />
          )}
        {showSearchConfigCard && aaiLevelLoading && !shouldShowLeadsHistory && (
          <Flex
            w="100%"
            backgroundColor="background.700"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius={8}
            px={6}
            pt={4}
            pb={4}
            h="444px"
          >
            <LoadingSpinner />
          </Flex>
        )}
        {!showSearchConfigCard && !shouldShowLeadsHistory && (
          <PoolMILeadCard
            lead={leadFromPoolMI as any}
            leadAction={handleLeadAction}
            isLoading={isFetching}
          />
        )}
        {shouldShowLeadsHistory && (
          <LeadsPoolMundoInvestHistory leadAction={handleLeadAction} />
        )}
      </Flex>
    </Flex>
  );
};
