import { Flex, Text, Box as ChakraBox } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { Icons } from 'sharedV2/icons';
import { TextUtils } from 'utilsV2/Text';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { DateUtils } from 'utilsV2/Date';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { LeadActionsEnum, useGlobalLeadAction } from 'hooksV2/useLeadActions';
import { LeadContactAttemptsOperationEnum } from 'pagesV2/LeadDetails/components/leadContactAttempts';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { queryClient } from 'App';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { showToast } from 'utilsV2/showToast';
import { useState } from 'react';
import { ModalWrapper } from 'components/elements/wrappers';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { DeclineLeadOfferModal } from 'componentsV2/modules/captation/declineLeadOfferModal';
import { useParseLeadByType } from 'hooksV2/useParseLeadByType';
import { LoadingSpinner } from 'components/elements/others';
import { ScreenUtils } from 'utilsV2/Screen';
import { DeclineOfferOptionsEnum } from 'componentsV2/modules/captation/declineLeadOfferModal/data';
import { LeadProspectType } from 'sharedV2/types/leadProspect';
import { LeadUtils } from 'utilsV2/Lead';
import { formatToPhone } from 'brazilian-values';

type PropsType = {
  lead: LeadProspectType;
};

export const LeadProspectCard: React.FC<PropsType> = ({
  lead,
}): JSX.Element => {
  const { redirect } = useNavigation();
  const { setParam, getParam } = useQueryParams();

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const [isOpenDeclineOfferModal, setIsOpenDeclineOfferModal] =
    useState<boolean>(false);

  const [
    copyLeadPhoneToClipboardTooltipText,
    setCopyLeadPhoneToClipboardTooltipText,
  ] = useState<string>('Copiar telefone');

  const lastViewedLeadId = getParam('lastViewedLeadId');

  const {
    mutateAsync: globalLeadAction,
    isLoading: isLoadingGlobalLeadAction,
  } = useGlobalLeadAction({
    onSuccess: async () => {
      showToast('Tentativa de contato realizada com sucesso!');
      leadBussinessLogic.saveLastTimeContactAttemptsHaveBeenUpdated();

      await queryClient.invalidateQueries(['leadsProspect']);
    },
    onError: error => {
      handleErrorMessage(error);
    },
  });

  const handleIncreseLeadContactAttempts = async (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    shouldOpenDeclineLeadOfferModal = true
  ) => {
    event?.stopPropagation();

    if (leadBussinessLogic.shouldBlockUpdateContactAttempts()) {
      return showToast(
        'Aguarde ao menos 30 minutos para realizar outra tentativa!'
      );
    }

    if (
      shouldOpenDeclineLeadOfferModal &&
      lead.contactAttempts + 1 >=
        LeadBussinessLogic.MAX_CONTACT_ATTEMPTS_BEFORE_DECLINE_LEAD_MODAL
    ) {
      return setIsOpenDeclineOfferModal(true);
    }

    await globalLeadAction({
      action: LeadActionsEnum.UPDATE_CONTACT_ATTEMPTS,
      operation: LeadContactAttemptsOperationEnum.INCREASE,
      leadId: lead.id,
      leadType: lead.type,
    });
  };

  const handleLeadCardClick = () => {
    const scrollTop = ScreenUtils.getScrollTop('scrollContainer');

    setParam('scrollTop', scrollTop.toString());
    setParam('lastViewedLeadId', lead.id);

    redirect(`/v2/lead/${lead.type}/${lead.id}?isFromProspectPage=true`);
  };

  const handleOnDeclineLeadOfferModalClose = async () => {
    setIsOpenDeclineOfferModal(false);

    await handleIncreseLeadContactAttempts(undefined, false);
  };

  const getLeadOrigin = (): string => {
    if (lead.type === LeadTypeEnum.UPLOAD && lead.listName) return 'Lista';

    if (lead.type === LeadTypeEnum.MANUAL) return 'Manual';

    if (lead.type === LeadTypeEnum.UPLOAD && !lead.listName) return 'Minerado';

    if (lead.type === LeadTypeEnum.MUNDO_INVEST) return 'Mundo Invest';

    return 'Could not translate lead origin';
  };

  return (
    <Box.Opaque
      h="100%"
      p={5}
      gap={3}
      cursor="pointer"
      onClick={handleLeadCardClick}
      _hover={useCommonHover()}
      shouldHaveAllBorders={lastViewedLeadId === lead.id}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          color="rgba(70, 70, 70, 1)"
          fontWeight="bold"
          fontSize="xl"
          isTruncated
        >
          {TextUtils.toCapitalize(lead.name)}
        </Text>
        {leadBussinessLogic.isDeclinedLead() && (
          <Tooltip text="Lead declinado">
            <ChakraBox bg="red.300" borderRadius="50%" h="15px" minW="15px" />
          </Tooltip>
        )}
      </Flex>

      <Flex flexDirection="column" gap={3}>
        <Flex alignItems="center">
          <Icons.PHONE />
          <Flex flexDirection="column" minW="150px" ml="10px">
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              Telefone
            </Text>
            <Tooltip text={copyLeadPhoneToClipboardTooltipText}>
              <Text
                color="rgba(70, 70, 70, 1)"
                onClick={event => {
                  event.stopPropagation();
                  if (!lead.phone) return;
                  TextUtils.copyToClipboard(lead.phone);
                  setCopyLeadPhoneToClipboardTooltipText('Copiado!');

                  setTimeout(() => {
                    setCopyLeadPhoneToClipboardTooltipText('Copiar telefone');
                  }, 2000);
                }}
              >
                {lead.phone
                  ? formatToPhone(
                      lead.phone.startsWith('55')
                        ? lead.phone.slice(2) // remove 55 (brazil country code)
                        : lead.phone
                    )
                  : '-'}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>

        <Flex alignItems="center">
          <Icons.CALENDAR />
          <Flex flexDirection="column" minW="150px" ml="10px">
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              Último Contato
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              {lead.lastContactAttemptsUpdate
                ? DateUtils.formatWithTime(lead.lastContactAttemptsUpdate)
                : 'Sem Último Contato'}
            </Text>
          </Flex>
        </Flex>

        <Flex alignItems="center">
          <Icons.USER_CIRCLE />
          <Flex flexDirection="column" minW="150px" ml="10px">
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              Origem
            </Text>
            <Tooltip text={lead.listName}>
              <Text color="rgba(70, 70, 70, 1)">{getLeadOrigin()}</Text>
            </Tooltip>
          </Flex>
        </Flex>

        {!leadBussinessLogic.isDeclinedLead() && (
          <Tooltip text="Realizar Tentativa de Contato">
            <Flex
              alignItems="center"
              _hover={useCommonHover()}
              onClick={handleIncreseLeadContactAttempts}
            >
              <Icons.PHONE_X />
              <Flex flexDirection="column" minW="150px" ml="10px">
                <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                  Tentativas de Contato
                </Text>
                {isLoadingGlobalLeadAction ? (
                  <LoadingSpinner size="xs" h="24px" />
                ) : (
                  <Text color="rgba(70, 70, 70, 1)">
                    {lead.contactAttempts}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Tooltip>
        )}

        {leadBussinessLogic.isDeclinedLead() && (
          <Flex alignItems="center" px={2} borderRadius={5}>
            <Icons.X color="#1D63DD" size={24} />
            <Flex flexDirection="column" minW="150px" ml="10px">
              <Text fontSize="sm">Motivo do Declinio</Text>
              <Text color="rgba(70, 70, 70, 1)">
                {LeadUtils.translateLeadDeclinedReasonToPortuguese(
                  lead.declinedReason
                )}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>

      <ModalWrapper
        isOpen={isOpenDeclineOfferModal}
        onClose={() => setIsOpenDeclineOfferModal(false)}
      >
        <DeclineLeadOfferModal
          lead={useParseLeadByType({
            lead,
            leadType: lead.type,
          })}
          shouldOnlyDeclineMeeting={false}
          leadType={LeadTypeEnum.UPLOAD}
          onModalCloseCallback={handleOnDeclineLeadOfferModalClose}
          modalOptions={{
            title: 'Deseja declinar este lead por falta de contato?',
            declineOfferReasonInitialValue: DeclineOfferOptionsEnum.NO_ANSWER,
          }}
        />
      </ModalWrapper>
    </Box.Opaque>
  );
};
