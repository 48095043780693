import { useEffect, useState } from 'react';
import { FunnelChart } from 'pages/Captation/components/elements/funnelChart';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IFunnelData } from 'pages/Captation/components/elements/funnelChart/dtos';
import { MainPageTitle } from 'components/elements/texts';
import { chooseFunnelColor } from 'pages/Captation/components/elements/funnelChart/utils/chooseFunnelColor';
import { Box, Flex } from '@chakra-ui/react';
import { Filters } from 'pages/Captation/components/elements/funnelChart/Filters';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { IReduxProfile } from 'shared/interfaces/ReduxState';
import { toast } from 'react-toastify';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { SecondaryButton } from 'components/elements/buttons';
import { FiExternalLink } from 'react-icons/all';
import history from 'services/history';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

type IFromTypeLead = 'all' | 'mined' | 'publics' | 'pj' | 'lista';

interface IProps {
  isManagement: boolean;
}

export const Results: React.FC<IProps> = ({ isManagement }) => {
  const user: IReduxProfile = useSelector(
    (state: IReduxState) => state.user.profile
  );
  const [graphData, setGraphData] = useState<IFunnelData[]>([]);
  const [fromTypeLead, setFromTypeLead] = useState<IFromTypeLead>('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fromTypeAllResponse, setFromTypeAllResponse] = useState<IFunnelData[]>(
    []
  );
  const [customEmployeeId, setCustomEmployeeId] = useState('');
  const [customTableId, setCustomTableId] = useState(
    user.rules[0].permission_cod === 'GTR' || user.position === 'MASTER'
      ? 'all'
      : user.tableId
  );
  let isAllFromTable =
    window.location.href.includes('funnel_results') && customEmployeeId === ''
      ? 'true'
      : 'false';

  const [fromTypeListaResponse, setFromTypeListaResponse] = useState<
    IFunnelData[]
  >([]);
  const [fromTypeMundoInvestResponse, setFromTypeMundoInvestResponse] =
    useState<IFunnelData[]>([]);

  const [fromTypeMinedResponse, setFromTypeMinedResponse] = useState<
    IFunnelData[]
  >([]);

  const [fromTypePJResponse, setFromTypePJResponse] = useState<IFunnelData[]>(
    []
  );

  const isUserAManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTM', 'GTR'],
  });

  const getEmployeeMetricsDate = async () => {
    setIsLoading(true);

    try {
      const response = await hubApiCaptation.getEmployeeMetrics(
        customEmployeeId || user.id,
        fromTypeLead,
        startDate,
        endDate,
        isAllFromTable,
        customTableId || user.tableId
      );

      const newData: IFunnelData[] = [];
      Object.keys(response).forEach(key => {
        newData.push({
          value: response[key],
          label: key,
          color: chooseFunnelColor(key),
        });
      });
      setGraphData(newData);
      setIsLoading(false);
    } catch (err) {
      setGraphData([]);
      return toast.dark('Erro ao buscar informacoes da mesa.');
    }
  };

  const getEmployeeMetrics = async () => {
    setIsLoading(true);
    if (fromTypeLead === 'all' && fromTypeAllResponse.length > 0) {
      setIsLoading(false);
      return setGraphData(fromTypeAllResponse);
    }

    if (fromTypeLead === 'lista' && fromTypeListaResponse.length > 0) {
      setIsLoading(false);
      return setGraphData(fromTypeListaResponse);
    }

    if (fromTypeLead === 'publics' && fromTypeMundoInvestResponse.length > 0) {
      setIsLoading(false);
      return setGraphData(fromTypeMundoInvestResponse);
    }

    if (fromTypeLead === 'mined' && fromTypeMinedResponse.length > 0) {
      setIsLoading(false);
      return setGraphData(fromTypeMinedResponse);
    }

    if (fromTypeLead === 'pj' && fromTypePJResponse.length > 0) {
      setIsLoading(false);
      return setGraphData(fromTypePJResponse);
    }

    const response = await hubApiCaptation.getEmployeeMetrics(
      customEmployeeId || user.id,
      fromTypeLead,
      startDate,
      endDate,
      isAllFromTable,
      customTableId || user.tableId
    );

    const newData: IFunnelData[] = [];
    Object.keys(response).forEach(key => {
      newData.push({
        value: response[key],
        label: key,
        color: chooseFunnelColor(key),
      });
    });

    if (fromTypeLead === 'all' && fromTypeAllResponse.length === 0) {
      setFromTypeAllResponse(newData);
    }

    if (fromTypeLead === 'lista' && fromTypeListaResponse.length === 0) {
      setFromTypeListaResponse(newData);
    }

    if (
      fromTypeLead === 'publics' &&
      fromTypeMundoInvestResponse.length === 0
    ) {
      setFromTypeMundoInvestResponse(newData);
    }

    if (fromTypeLead === 'mined' && fromTypeMinedResponse.length === 0) {
      setFromTypeMinedResponse(newData);
    }

    if (fromTypeLead === 'pj' && fromTypePJResponse.length === 0) {
      setFromTypePJResponse(newData);
    }
    setGraphData(newData);
    setIsLoading(false);
  };

  const handleFilterLeadType = (e: string) => {
    setStartDate('');
    setEndDate('');
    setGraphData([]);
    setFromTypeLead(e as IFromTypeLead);
  };

  const handleFilterStartDate = (e: string) => {
    setGraphData([]);
    setStartDate(e as IFromTypeLead);
  };

  const handleFilterEndDate = (e: string) => {
    setGraphData([]);
    setEndDate(e as IFromTypeLead);
  };

  useEffect(() => {
    setGraphData([]);
    getEmployeeMetrics();
  }, [fromTypeLead, customTableId]);

  useEffect(() => {
    if (
      startDate !== '' ||
      endDate !== '' ||
      customEmployeeId !== '' ||
      customEmployeeId === '' ||
      customTableId !== 'all'
    ) {
      setFromTypeAllResponse([]);
      setFromTypeListaResponse([]);
      setFromTypeMinedResponse([]);
      setFromTypeMundoInvestResponse([]);
      setFromTypePJResponse([]);
      isAllFromTable =
        window.location.href.includes('funnel_results') &&
        customEmployeeId === ''
          ? 'true'
          : 'false';
      setGraphData([]);
      getEmployeeMetricsDate();
    }
  }, [startDate, endDate, customEmployeeId, customTableId]);

  return (
    <PageWrapper paddingTop="2rem">
      <MainPageTitle color="rgba(70, 70, 70, 1)" textAlign="center">
        {isManagement ? 'Resultados Assessoria' : 'Meus Resultados'}
      </MainPageTitle>
      <Flex
        flexDirection="column"
        backgroundColor="white"
        w="85vw"
        height="30rem"
        py={4}
        px={8}
        borderRadius={6}
      >
        <Filters
          customTableId={customTableId}
          setCustomTableId={setCustomTableId}
          isGeralManagement={
            user.rules[0].permission_cod === 'GTR' || user.position === 'MASTER'
          }
          setCustomEmployeeId={setCustomEmployeeId}
          isManagement={isManagement}
          endDate={endDate}
          handleFilterEndDate={handleFilterEndDate}
          handleFilterLeadType={handleFilterLeadType}
          handleFilterStartDate={handleFilterStartDate}
          startDate={startDate}
        />
        <FunnelChart data={graphData} isLoading={isLoading} />
        {!window.location.href.includes('funnel_results') && isUserAManager && (
          <Flex justifyContent="flex-end" gap={2} mb={2}>
            <SecondaryButton
              onClick={() => history.push('/captation/funnel_results')}
              gap={1.5}
              w="fit-content"
              size="xs"
            >
              Ver resultados da assessoria
              <Box mb={0.5}>
                <FiExternalLink size={16} />
              </Box>
            </SecondaryButton>
          </Flex>
        )}
      </Flex>
    </PageWrapper>
  );
};
