import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { Box } from 'componentsV2/elements/boxes';
import { LeadActionsEnum, useGlobalLeadAction } from 'hooksV2/useLeadActions';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { useUpdateMeeting } from 'hooksV2/useUpdateMeeting';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { Minus, Plus } from 'phosphor-react';
import { LeadTypes } from 'sharedV2/types/leadTypes';
import { showToast } from 'utils';
import { LeadUtils } from 'utilsV2/Lead';

export enum LeadContactAttemptsOperationEnum {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export const LeadContactAttempts = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const thereIsAMeeting = !!lead?.meeting;

  const { mutateAsync: updateMeeting, isLoading: isLoadingUpdateMeeting } =
    useUpdateMeeting({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
      onMutate: async updatedMeeting => {
        // Contact Attempts Optimistic Update
        await queryClient.cancelQueries(['leadDetails', lead?.id]);

        const previousMeeting = queryClient.getQueryData([
          'leadDetails',
          lead?.id,
        ]);

        queryClient.setQueryData(['leadDetails', lead?.id], old => {
          const oldLead = old as LeadTypes;

          if (
            (LeadUtils.isLeadMundoInvest(oldLead) ||
              LeadUtils.isLeadUpload(oldLead) ||
              LeadUtils.isLeadManual(oldLead)) &&
            oldLead.meeting
          ) {
            oldLead.meeting.contactAttempts = updatedMeeting.contactAttempts;
          }

          if (LeadUtils.isLeadPJ(oldLead)) {
            oldLead.meetings[0].contactAttempts =
              updatedMeeting.contactAttempts;
          }

          return oldLead;
        });

        return { previousMeeting };
      },
      onError: (_, __, context) => {
        const queryContext = context as
          | { previousMeeting: unknown }
          | undefined;

        queryClient.setQueryData(
          ['leadDetails', lead?.id],
          queryContext?.previousMeeting
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['leadDetails', lead?.id]);
      },
    });

  const { mutateAsync: updateLead, isLoading: isLoadingUpdateLead } =
    useUpdateLead({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
      onMutate: async updatedLead => {
        // Contact Attempts Optimistic Update
        await queryClient.cancelQueries(['leadDetails', lead?.id]);

        const previousMeeting = queryClient.getQueryData([
          'leadDetails',
          lead?.id,
        ]);

        queryClient.setQueryData(['leadDetails', lead?.id], old => {
          const oldLead = old as LeadTypes;

          if (
            LeadUtils.isLeadManual(oldLead) ||
            LeadUtils.isLeadPJ(oldLead) ||
            LeadUtils.isLeadUpload(oldLead)
          ) {
            oldLead.contactAttempts = updatedLead.contactAttempts;
          }

          return oldLead;
        });

        return { previousMeeting };
      },
      onError: (_, __, context) => {
        const queryContext = context as
          | { previousMeeting: unknown }
          | undefined;

        queryClient.setQueryData(
          ['leadDetails', lead?.id],
          queryContext?.previousMeeting
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['leadDetails', lead?.id]);
      },
    });

  const {
    mutateAsync: globalLeadAction,
    isLoading: isLoadingGlobalLeadAction,
  } = useGlobalLeadAction({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['leadDetails', lead?.id]);
    },
    onMutate: async updatedLead => {
      // Contact Attempts Optimistic Update
      await queryClient.cancelQueries(['leadDetails', lead?.id]);

      const previousMeeting = queryClient.getQueryData([
        'leadDetails',
        lead?.id,
      ]);

      queryClient.setQueryData(['leadDetails', lead?.id], old => {
        const oldLead = old as LeadTypes;

        if (
          LeadUtils.isLeadManual(oldLead) ||
          LeadUtils.isLeadPJ(oldLead) ||
          LeadUtils.isLeadUpload(oldLead)
        ) {
          oldLead.contactAttempts =
            updatedLead.operation === LeadContactAttemptsOperationEnum.INCREASE
              ? Number(oldLead?.contactAttempts) + 1
              : Number(oldLead?.contactAttempts) - 1;
        }

        return oldLead;
      });

      return { previousMeeting };
    },
    onError: (_, __, context) => {
      const queryContext = context as { previousMeeting: unknown } | undefined;

      queryClient.setQueryData(
        ['leadDetails', lead?.id],
        queryContext?.previousMeeting
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['leadDetails', lead?.id]);
    },
  });

  const isLoading =
    isLoadingUpdateMeeting || isLoadingUpdateLead || isLoadingGlobalLeadAction;

  const handleChangeContactAttempts = async (
    operation: LeadContactAttemptsOperationEnum
  ) => {
    if (isLoading) return;

    if (leadBussinessLogic.shouldBlockUpdateContactAttempts()) {
      return showToast(
        'Aguarde ao menos 30 minutos para realizar outra tentativa!'
      );
    }

    const newContactAttempts =
      operation === LeadContactAttemptsOperationEnum.INCREASE
        ? Number(
            thereIsAMeeting
              ? lead?.meeting?.contactAttempts
              : lead.contactAttempts
          ) + 1
        : Number(
            thereIsAMeeting
              ? lead?.meeting?.contactAttempts
              : lead.contactAttempts
          ) - 1;

    if (thereIsAMeeting) {
      await updateMeeting({
        ...lead?.meeting,
        contactAttempts: newContactAttempts.toString(),
      });

      leadBussinessLogic.saveLastTimeContactAttemptsHaveBeenUpdated();

      return;
    }

    if (LeadUtils.isLeadPJ(lead)) {
      await updateLead({
        type: leadType,
        leadId: lead.id,
        contactAttempts: newContactAttempts.toString(),
      });
    } else {
      await globalLeadAction({
        action: LeadActionsEnum.UPDATE_CONTACT_ATTEMPTS,
        leadId: lead.id,
        leadType,
        operation,
      });
    }

    leadBussinessLogic.saveLastTimeContactAttemptsHaveBeenUpdated();
  };

  return (
    <Box.Default gap={2}>
      <Text color="white">Tentativas de contato:</Text>
      <Flex alignItems="center" gap={2}>
        <Minus
          cursor="pointer"
          onClick={() =>
            handleChangeContactAttempts(
              LeadContactAttemptsOperationEnum.DECREASE
            )
          }
        />
        <Text color="#FF607D">
          {thereIsAMeeting
            ? lead.meeting?.contactAttempts || 0
            : lead?.contactAttempts || 0}
        </Text>
        <Plus
          cursor="pointer"
          onClick={() =>
            handleChangeContactAttempts(
              LeadContactAttemptsOperationEnum.INCREASE
            )
          }
        />
      </Flex>
    </Box.Default>
  );
};
