import { Flex, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { Icons } from 'sharedV2/icons';
import { ClientType } from 'sharedV2/types/client';
import { ClientDetailsAddTagModal } from './addTagModal';
import { ClientDetailsTagCard } from './card';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsTags: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const isOpenAddTagModalFilter = useSimpleQueryFilter('isOpenAddTagModal');

  return (
    <Flex w="100%" flexDirection="column" gap={2}>
      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
          Tags
        </Text>

        <Icons.PLUS
          onClick={() =>
            isOpenAddTagModalFilter.handleChangeFilterValue('true')
          }
        />
      </Flex>

      <Flex
        flexDirection="column"
        gap={2}
        overflowY="auto"
        className="thinScrollbar"
      >
        {client.tags.length === 0 && (
          <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
            Sem tags adicionadas
          </Text>
        )}
        {client.tags.map((tag, index) => (
          <ClientDetailsTagCard tag={tag} key={index} client={client} />
        ))}
      </Flex>

      <ModalWrapper
        isOpen={isOpenAddTagModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddTagModalFilter.handleDeleteParam()}
      >
        <ClientDetailsAddTagModal
          client={client}
          isOpenAddTagModalFilter={isOpenAddTagModalFilter}
        />
      </ModalWrapper>
    </Flex>
  );
};
