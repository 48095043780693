export const optionsProps = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'lista',
    label: 'Leads Lista',
  },
  {
    value: 'mined',
    label: 'Leads Minerados',
  },
  {
    value: 'pj',
    label: 'Leads PJ',
  },
  {
    value: 'publics',
    label: 'Leads Mundo Invest',
  },
];
