import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  CreateReminderRequestType,
  hubApiClients,
} from 'services/hubApi/classes/HubApiClients';
import { ClientType } from 'sharedV2/types/client';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { z } from 'zod';

type PropsType = {
  client: ClientType;
  isOpenAddReminderModalFilter: UseSimpleFilterResponseType;
};

const schema = z.object({
  title: z.string().min(1, { message: 'O campo título é obrigatório' }),
  description: z
    .string()
    .min(1, { message: 'O campo descrição é obrigatório' }),
  date: z.string().min(1, { message: 'O campo data é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const AddClientReminderModal: React.FC<PropsType> = ({
  client,
  isOpenAddReminderModalFilter,
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const { mutateAsync: createReminder, isLoading: isLoadingCreateReminder } =
    useMutation({
      mutationKey: ['createReminder'],
      mutationFn: async (data: CreateReminderRequestType) => {
        const response = await hubApiClients.createReminder(data);

        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);

        isOpenAddReminderModalFilter.handleDeleteParam();
      },
      onError: error => {
        handleErrorMessage(error);
      },
    });

  const handleAddReminder: SubmitHandler<FormFieldsType> = async data => {
    await createReminder({
      btgCode: client.btgCode,
      ...data,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Criar Lembrete" />
        <Modal.CloseIcon
          onClick={() => isOpenAddReminderModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={4}>
        <Input.Root>
          <Input.Label text="Título" />
          <Input.TextField {...register('title')} />
          <Input.ErrorMessage message={errors.title?.message} />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Descrição" />
          <Input.TextField {...register('description')} />
          <Input.ErrorMessage message={errors.description?.message} />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Data" />
          <Input.Date {...register('date')} />
          <Input.ErrorMessage message={errors.date?.message} />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Criar"
          isLoading={isLoadingCreateReminder}
          onClick={handleSubmit(handleAddReminder)}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
