import React from 'react';
import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react';

export const Textarea: React.FC<TextareaProps> = ({ ...rest }) => {
  return (
    <>
      <ChakraTextarea
        size="sm"
        padding="3"
        background="background.500"
        borderColor="transparent"
        color="white"
        borderRadius="6px"
        focusBorderColor="primary.600"
        _placeholder={{ color: 'gray.400' }}
        _hover={{ borderColor: 'primary.800' }}
        {...rest}
      />
    </>
  );
};
