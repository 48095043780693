import { Dropdown } from 'components/elements/others';
import { useEffect, useState } from 'react';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';

interface ITables {
  created_at: string;
  id: string;
  managerId: string;
  name: string;
  officeId: string;
  updated_at: string;
}

interface IProps {
  setCustomTableId: (tableId: string) => void;
  includeAllOption?: boolean;
}

export const DropDownTable: React.FC<IProps> = ({
  setCustomTableId,
  includeAllOption = true,
}) => {
  const [tables, setTables] = useState<ITables[]>([]);

  const handleGetTables = async () => {
    const tablesData = await hubApiOfficeTables.listTablesOffice();
    setTables(tablesData);
  };

  useEffect(() => {
    handleGetTables();
  }, []);

  return (
    <Dropdown
      borderRadius="sm"
      w={['130px', '180px']}
      h="7"
      fontSize="xs"
      onChange={e => setCustomTableId(e.target.value)}
    >
      {includeAllOption ? (
        <option value="all">Todos</option>
      ) : (
        <option value="">Mesas</option>
      )}

      {tables.map(table => (
        <option key={table.id} value={table.id}>
          {table.name}
        </option>
      ))}
    </Dropdown>
  );
};
