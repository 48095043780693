import { Flex, SimpleGrid } from '@chakra-ui/react';
import { useTabNavigation } from 'hooksV2/useTabNavigation';
import { ClientDetailsBasicData } from 'pagesV2/ClientDetails/components/basicData';
import { ClientDetailsEmployeesHistory } from 'pagesV2/ClientDetails/components/employeesHistory';
import { ClientDetailsInvestorData } from 'pagesV2/ClientDetails/components/investorData';
import { ClientDetailsTags } from 'pagesV2/ClientDetails/components/tags';
import { ClientType } from 'sharedV2/types/client';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsProfileSubPage: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { TabsDiv, selectedTab } = useTabNavigation({
    tabs: ['Dados Básicos', 'Dados do Investidor'],
    initialTab: 'Dados Básicos',
  });

  return (
    <SimpleGrid columns={2} w="100%" h="100%" gap={3} gridTemplateRows="100%">
      <SimpleGrid columns={1} gap={3} gridTemplateRows="auto">
        <Flex gap={6} flexDirection="column">
          <TabsDiv />

          {selectedTab === 'Dados Básicos' && (
            <ClientDetailsBasicData client={client} />
          )}

          {selectedTab === 'Dados do Investidor' && (
            <ClientDetailsInvestorData client={client} />
          )}
        </Flex>
      </SimpleGrid>

      <SimpleGrid
        columns={1}
        gap={3}
        gridTemplateRows="minmax(100px, 1fr) minmax(100px, 1fr)"
      >
        <ClientDetailsEmployeesHistory client={client} />

        <ClientDetailsTags client={client} />
      </SimpleGrid>
    </SimpleGrid>
  );
};
