import { Flex, Text } from '@chakra-ui/react';
import { Divider } from 'componentsV2/elements/divider';
import { useBuildLeadData } from 'pagesV2/LeadDetails/hooks/useBuildLeadData';
import { LeadInfomationBox } from 'pagesV2/LeadDetails/components/leadInformationBox';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { Box } from 'componentsV2/elements/boxes';
import { SalesHunterPoolLeadActions } from 'pagesV2/SalesHunterPool/components/leadActions';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { LeadMundoInvestInformation } from '../leadMundoInvestInformation';
import { LeadDetailsPipeValue } from '../pipeValue';
import { LeadDetailsObservations } from '../leadObservations';
import { LeadDetailsQuestionsForm } from '../leadQuestionsForm';
import { LeadDetailsHistory } from '../leadHistory';

export const LeadInformations = (): JSX.Element => {
  const { data } = useBuildLeadData();

  const { desiredFlow, lead } = useLeadDetailsContext();
  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  return (
    <Flex w="100%" gap={5} style={{ flex: 1 }}>
      <Flex flexDirection="column" gap={4} w="60%">
        {!isSalesHunterDesiredFlow && <LeadDetailsPipeValue />}

        <Flex
          gap={2}
          wrap="wrap"
          overflowY="auto"
          maxHeight="calc(100vh - 310px)"
          className="thinScrollbar"
        >
          {data.map((item, index) => (
            <LeadInfomationBox
              key={index}
              label={item.label}
              value={item.value}
            />
          ))}

          {leadBussinessLogic.shouldDisplayLeadActions() && (
            <Box.Opaque
              style={{ flex: 1 }}
              minW="calc(50% - 4px)"
              p={3}
              gap={2}
            >
              <Text color="rgba(70, 70, 70, 1)">Tarefas para hoje</Text>
              <SalesHunterPoolLeadActions lead={lead as LeadMundoInvestType} />
            </Box.Opaque>
          )}

          <LeadMundoInvestInformation />

          {isSalesHunterDesiredFlow && <LeadDetailsQuestionsForm />}
        </Flex>
      </Flex>

      <Flex flexDirection="column" w="60%" gap={2}>
        <LeadDetailsObservations />
        <LeadDetailsHistory />
      </Flex>
    </Flex>
  );
};
