import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { ModalWrapper } from 'components/elements/wrappers';
import { Menu } from 'componentsV2/elements/menu';
import { Spinner } from 'componentsV2/elements/spinner';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { useCreateClientMeeting } from 'hooksV2/useCreateClientMeeting';
import { useNavigation } from 'hooksV2/useNavigation';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { colors } from 'sharedV2/colors';
import { ClientType } from 'sharedV2/types/client';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { AddClientAportModal } from './components/addClientAportModal';
import { AddClientMeetingModal } from './components/addClientMeetingModal';
import { AddClientReminderModal } from './components/addClientReminderModal';
import { ClientDetailsContactFrequencyModal } from './components/contactFrequencyModal';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsActivities: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const isOpenAddAportModalFilter = useSimpleQueryFilter('isOpenAddAportModal');

  const isOpenAddReminderModalFilter = useSimpleQueryFilter(
    'isOpenAddReminderModal'
  );

  const isOpenAddMeetingModalFilter = useSimpleQueryFilter(
    'isOpenAddMeetingModal'
  );

  const isOpenContactFrequencyModalFilter = useSimpleQueryFilter(
    'isOpenContactFrequencyModal'
  );

  const { mutateAsync: createMeeting, isLoading: isLoadingCreateMeeting } =
    useCreateClientMeeting({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
      },
      onError: error => {
        handleErrorMessage(error);
      },
    });

  const handleCreateInstantMeeting = async () => {
    const meeting = await createMeeting({
      btgCode: client.btgCode,
      date: new Date().toISOString(),
    });

    redirect(`/v2/client/${client.btgCode}/meeting/${meeting.id}`);
  };

  return (
    <Flex flexDirection="column" gap={3}>
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Realizar Atividade
      </Text>
      <SimpleGrid columns={4} gap={2}>
        <Flex
          p={3}
          bgColor="#1d63dd"
          justifyContent="center"
          borderRadius={6}
          cursor="pointer"
          flexDirection="column"
          transition="background-color 0.3s ease"
          _hover={{ bgColor: '#1d63ddea' }}
          onClick={() =>
            isOpenContactFrequencyModalFilter.handleChangeFilterValue('true')
          }
        >
          <Flex justifyContent="flex-end">
            <Tooltip
              text={
                client.isContactFrequencyActive
                  ? 'Frequência Ativada'
                  : 'Frequência Desativada'
              }
            >
              <Flex
                w="12px"
                h="12px"
                bgColor={
                  client.isContactFrequencyActive ? colors.GREEN : colors.RED
                }
                borderRadius="50%"
              />
            </Tooltip>
          </Flex>

          <Flex flexDirection="column" position="relative" bottom="3px">
            {!client.isContactFrequencyActive && !client.contactFrequency && (
              <Text fontSize="sm" color="white" textAlign="center">
                Definir Frequência de Contato
              </Text>
            )}
            {(client.isContactFrequencyActive || client.contactFrequency) && (
              <>
                <Text fontSize="sm" color="white" textAlign="center">
                  Contato a cada
                </Text>
                <Text fontSize="sm" color="white" textAlign="center">
                  {client.contactFrequency} dias
                </Text>
              </>
            )}
          </Flex>
        </Flex>

        <Menu.Root>
          <Menu.Button
            p={3}
            bgColor="#1d63dd"
            justifyContent="center"
            alignItems="center"
            borderRadius={6}
            cursor="pointer"
            transition="background-color 0.3s ease"
            _hover={{ bgColor: '#1d63ddea' }}
          >
            <Text fontSize="sm" color="white" textAlign="center">
              Realizar Atividade
            </Text>
          </Menu.Button>
          <Menu.List>
            <Menu.Item
              onClick={() =>
                isOpenAddReminderModalFilter.handleChangeFilterValue('true')
              }
            >
              Criar Lembrete
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                isOpenAddMeetingModalFilter.handleChangeFilterValue('true')
              }
            >
              Agendar Reunião
            </Menu.Item>
          </Menu.List>
        </Menu.Root>

        <Flex
          p={3}
          bgColor="#1d63dd"
          justifyContent="center"
          alignItems="center"
          borderRadius={6}
          cursor="pointer"
          transition="background-color 0.3s ease"
          _hover={{ bgColor: '#1d63ddea' }}
          onClick={handleCreateInstantMeeting}
        >
          {isLoadingCreateMeeting ? (
            <Spinner h="20px" w="20px" />
          ) : (
            <Text fontSize="sm" color="white" textAlign="center">
              Reunião Instantânea
            </Text>
          )}
        </Flex>

        <Flex
          p={3}
          bgColor="#1d63dd"
          justifyContent="center"
          alignItems="center"
          borderRadius={6}
          cursor="pointer"
          transition="background-color 0.3s ease"
          _hover={{ bgColor: '#1d63ddea' }}
          onClick={() =>
            isOpenAddAportModalFilter.handleChangeFilterValue('true')
          }
        >
          <Text fontSize="sm" color="white" textAlign="center">
            Adicionar Aporte
          </Text>
        </Flex>
      </SimpleGrid>

      <ModalWrapper
        isOpen={isOpenContactFrequencyModalFilter.filterValue === 'true'}
        onClose={() => isOpenContactFrequencyModalFilter.handleDeleteParam()}
      >
        <ClientDetailsContactFrequencyModal
          client={client}
          isOpenContactFrequencyModalFilter={isOpenContactFrequencyModalFilter}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenAddAportModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddAportModalFilter.handleDeleteParam()}
      >
        <AddClientAportModal
          client={client}
          isOpenAddAportModalFilter={isOpenAddAportModalFilter}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenAddReminderModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddReminderModalFilter.handleDeleteParam()}
      >
        <AddClientReminderModal
          client={client}
          isOpenAddReminderModalFilter={isOpenAddReminderModalFilter}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenAddMeetingModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddMeetingModalFilter.handleDeleteParam()}
      >
        <AddClientMeetingModal
          client={client}
          isOpenAddMeetingModalFilter={isOpenAddMeetingModalFilter}
        />
      </ModalWrapper>
    </Flex>
  );
};
