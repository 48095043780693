import { Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Box } from 'componentsV2/elements/boxes';
import { Spinner } from 'componentsV2/elements/spinner';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import { Icons } from 'sharedV2/icons';
import { ClientType } from 'sharedV2/types/client';

type PropsType = {
  client: ClientType;
  tag: string;
};

export const ClientDetailsTagCard: React.FC<PropsType> = ({
  tag,
  client,
}): JSX.Element => {
  const { mutateAsync: updateClient, isLoading: isLoadingUpdateClient } =
    useUpdateClient({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
      },
    });

  const handleDeleteTag = async () => {
    const newTags = client.tags.filter(clientTag => clientTag !== tag);

    await updateClient({
      ...client,
      tags: newTags,
    });
  };

  return (
    <Box.Opaque
      p={2}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text color="white">{tag}</Text>
      {isLoadingUpdateClient ? (
        <Spinner h="15px" w="15px" />
      ) : (
        <Icons.TRASH onClick={handleDeleteTag} />
      )}
    </Box.Opaque>
  );
};
