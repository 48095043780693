import {
  MenuButtonProps,
  MenuButton as ChakraMenuButton,
  Flex,
} from '@chakra-ui/react';

export const MenuButton: React.FC<MenuButtonProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <ChakraMenuButton
      as={Flex}
      border="none"
      bgColor="transparent"
      outline="none"
      boxShadow="none"
      alignItems="center"
      px={2}
      cursor="pointer"
      justifyContent="center"
      _hover={{ bgColor: '#1D63DD' }}
      _focus={{ outline: 'none' }}
      borderRadius={5}
      {...rest}
    >
      {children}
    </ChakraMenuButton>
  );
};
