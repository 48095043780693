import { Box, Flex, Text } from '@chakra-ui/react';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import {
  ArrowCounterClockwise,
  Calendar,
  CalendarCheck,
  CalendarPlus,
  CalendarX,
  Chat,
  CheckCircle,
  CheckSquareOffset,
  PaperPlaneTilt,
  Phone,
  PhoneCall,
  PhoneX,
  PlusCircle,
  Question,
  ShoppingBag,
} from 'phosphor-react';
import { useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formateDateAndTimeNoTimezone } from 'utils/formatDate';
import parser from 'html-react-parser';
import { TextUtils } from 'utilsV2/Text';

export const LeadDetailsHistory = (): JSX.Element => {
  const { lead } = useLeadDetailsContext();

  const { data: history } = useQuery({
    queryKey: ['leadHistory', lead.id],
    queryFn: async () => {
      const response = await hubApiCaptation.getLeadHistory(lead.id);

      return response;
    },
  });

  const handleGetIcon = (eventType: string) => {
    const obj: Record<string, JSX.Element> = {
      'Reunião agendada': <CalendarPlus size={30} color="#1D63DDB2" />,
      'Reunião reagendada': <Calendar size={30} color="#1D63DDB2" />,
      'Tentativa de contato (↑)': <Phone size={30} color="#1D63DDB2" />,
      'Tentativa de contato (↓)': <Phone size={30} color="#1D63DDB2" />,
      'Lead entrou no sistema': <PlusCircle size={30} color="#1D63DDB2" />,
      'Lead recuperado das reuniões atrasadas': (
        <ArrowCounterClockwise size={30} color="#1D63DDB2" />
      ),
      'Lead declinado': <CalendarX size={30} color="#1D63DDB2" />,
      'Tarefa realizada': <CheckSquareOffset size={30} color="#1D63DDB2" />,
      'Reunião roteada': <CalendarCheck size={30} color="#1D63DDB2" />,
      'Reunião comprada no pool de assessoria': (
        <ShoppingBag size={30} color="#1D63DDB2" />
      ),
      'Lead atendeu': <PhoneCall size={30} color="#1D63DDB2" />,
      'Lead não atendeu': <PhoneX size={30} color="#1D63DDB2" />,
      Alocado: <CheckCircle size={30} color="#1D63DDB2" />,
      'Retorno agendado': <Calendar size={30} color="#1D63DDB2" />,
      'Lead Transferido (Retirado)': (
        <PaperPlaneTilt size={30} color="#1D63DDB2" />
      ),
      'Lead Transferido (Responsável)': (
        <PaperPlaneTilt size={30} color="#1D63DDB2" />
      ),
      'Lead Transferido (Beneficiário)': (
        <PaperPlaneTilt size={30} color="#1D63DDB2" />
      ),
    };

    return obj[eventType] || <Question size={30} color="#1D63DDB2" />;
  };

  return (
    <Flex flexDirection="column" maxH="calc(100vh - 500px)">
      <Text fontWeight="bold" fontSize={20} color="rgba(70, 70, 70, 1)" mb={4}>
        Histórico do Lead
      </Text>
      <Flex
        flexDirection="column"
        overflowY="auto"
        maxH="calc(100vh - 500px)"
        className="thinScrollbar"
      >
        {history?.length === 0 && <Text>Sem movimentações</Text>}
        {history &&
          history.map(item => {
            if ('content' in item) {
              return (
                <Box key={item.id} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="60px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm" mt={-4}>
                        {formateDateAndTimeNoTimezone(item.created_at)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.content)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            return (
              <Box key={item.id} width="100%">
                <Flex gap={3} alignItems="center">
                  <Flex flexDirection="column" alignItems="center">
                    {handleGetIcon(item.eventType)}

                    <Flex border="1px solid #b0b0b0" h="60px" />
                  </Flex>

                  <Flex flexDirection="column">
                    <Text fontSize="sm" mt={-10}>
                      {formateDateAndTimeNoTimezone(item.created_at)}
                    </Text>
                    <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                      {item.eventType}{' '}
                      <span
                        style={{
                          fontStyle: 'italic',
                          color: 'gray',
                          fontWeight: 'lighter',
                        }}
                      >
                        por {TextUtils.toCapitalize(item.employeeName)}
                      </span>
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};
