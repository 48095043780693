import { Flex, Text } from '@chakra-ui/react';
import { calcWidth } from './utils/calcWidth';

interface IProps {
  highestValue: number;
  firstData: number;
}

export const HundredPerCent: React.FC<IProps> = ({
  highestValue,
  firstData,
}) => {
  return (
    <Flex w="100%" alignItems="center" h="30px">
      <Flex w="20%">
        <Text textAlign="center" color="transparent" fontWeight="bold">
          Total Recebidos
        </Text>
      </Flex>

      <Flex w="80%" justifyContent="center" alignItems="center">
        <Flex
          w={`${calcWidth(firstData, highestValue)}%`}
          borderLeft="1px solid gray"
          borderRight="1px solid gray"
          borderTop="1px solid gray"
          alignItems="center"
          h="20px"
          flexDirection="column"
        >
          <Text fontSize="xs" marginTop={1}>
            100%
          </Text>
        </Flex>
        <Flex w="100px" justifyContent="end" />
      </Flex>
    </Flex>
  );
};
