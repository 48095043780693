import {
  IEmployeesDistributionInfo,
  IPoolList,
} from 'pages/DistributionOfMinedLeads/interfaces';
import { TableBase } from 'components/elements/table';
import { EmployeeRow } from './EmployeeRow';

interface ITableProps {
  employeesData: Array<IEmployeesDistributionInfo>;
  allPoolLists: Array<IPoolList>;
  setEmployeesData: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo[]>
  >;
}

export const Table: React.FC<ITableProps> = ({
  employeesData,
  allPoolLists,
  setEmployeesData,
}) => {
  const tableHeaders = [
    'Nome AAI',
    'Mesa AAI',
    'Qtd. Leads Minerados',
    'Qtd. Leads Lista',
    'Lista',
    'Qtd. Envio Leads',
    '',
    '',
    '',
  ];

  return (
    <TableBase
      headData={tableHeaders}
      w="100%"
      maxW={{ base: '1080px', '2xl': '1240px' }}
      maxH="calc(100vh - 292px)"
      overflow="auto"
    >
      {employeesData.map(employee => (
        <EmployeeRow
          employeesData={employeesData}
          setEmployeesData={setEmployeesData}
          key={employee.employeeName}
          employee={employee}
          allPoolLists={allPoolLists}
        />
      ))}
    </TableBase>
  );
};
