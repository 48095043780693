import { RiHealthBookLine } from 'react-icons/ri';
import { HiSearch, HiViewBoards } from 'react-icons/hi';
import { BsPersonPlusFill } from 'react-icons/bs';
import {
  AiOutlineMail,
  AiOutlineTeam,
  AiOutlineUpload,
  AiOutlineUser,
} from 'react-icons/ai';
import { GoGear } from 'react-icons/go';

import {
  BiAddToQueue,
  BiBug,
  BiBuilding,
  BiBuildings,
  BiCalendarEvent,
  BiCommentDetail,
  BiHealth,
  BiTask,
  BiTransferAlt,
} from 'react-icons/bi';
import { FaCoins } from 'react-icons/fa';

export const geralOptionsAlt = [
  {
    sectionTitle: 'Geral',

    pages: [
      {
        title: 'Assinatura eletrônica',
        path: '/user/eletronic_signature/create',
        Icon: BiAddToQueue,
      },

      {
        title: 'Gestão de Tags',
        path: '/user/users_clients/tags',
        Icon: BiAddToQueue,
      },
      {
        title: 'Reportar Problema',
        path: 'https://forms.clickup.com/9007130840/f/8cdvu6r-2207/554MT3JEFKNAJXOX74',
        Icon: BiBug,
      },
      {
        title: 'Meus MIBs',
        path: '/user/mibs',
        Icon: FaCoins,
      },
    ],
  },
];

if (process.env.REACT_APP_ENVIRONMENT === 'test') {
  geralOptionsAlt[0].pages.push({
    title: 'Fake Data',
    path: '/fakes',
    Icon: BsPersonPlusFill,
  });
}

export const menuBIsAlt = [
  {
    sectionTitle: 'BIs',
    pages: [
      {
        title: 'Gerenciamento de BIs',
        path: '/dashboards/management',
        Icon: GoGear,
        area: ['MANAGER'],
      },
    ],
  },
];

const menuOptionsAlt = [
  [
    {
      sectionTitle: 'CRMs',
      pages: [
        {
          title: 'CRM - Marketing',
          path: '/board/marketing',
          Icon: HiViewBoards,
          area: ['MKT'],
        },
        {
          title: 'CRM - RH',
          path: '/board/Rh',
          Icon: AiOutlineUser,
          area: ['RH'],
        },
        {
          title: 'CRM - Alavancagem Patrimonial',
          path: '/board/real_state',
          Icon: BiBuilding,
          area: ['IP'],
          permission: ['CLI'],
        },
        {
          title: 'CRM - Saúde',
          path: '/board/health',
          Icon: RiHealthBookLine,
          area: ['IP'],
          permission: ['CLS'],
        },
        {
          title: 'CRM - IP',
          path: '/v2/board/life',
          Icon: BiHealth,
          area: ['IP'],
          permission: ['CLV'],
        },
        {
          title: 'CRM - Corporate',
          path: '/board/corporate',
          Icon: HiViewBoards,
          area: ['IP'],
          permission: ['CLC'],
        },
        {
          title: 'CRM - Agro',
          path: '/board/agro',
          Icon: HiViewBoards,
          area: ['IP'],
          permission: ['CLG'],
        },
        {
          title: 'CRM - Seguros Alternativos',
          path: '/board/alt_insurances',
          Icon: HiViewBoards,
          area: ['IP'],
          permission: ['CLA'],
        },
        {
          title: 'CRM - T.I',
          path: '/board/ti',
          Icon: HiViewBoards,
          area: ['TI'],
        },
        {
          title: 'CRM - Câmbio',
          path: '/board/exchange',
          Icon: HiViewBoards,
          area: ['EX'],
        },
      ],
    },
    {
      sectionTitle: 'Gerenciar Produtos',
      pages: [
        {
          title: 'IPOs',
          path: '/products/ipo/management',
          Icon: BiAddToQueue,
          area: ['PDT'],
          permission: ['ESP', 'GTR'],
        },
        {
          title: 'COEs',
          path: '/products/coe/management',
          Icon: BiAddToQueue,
          area: ['PDT'],
          permission: ['ESP', 'GTR'],
        },
        {
          title: 'Fundos',
          path: '/products/funds/management',
          Icon: BiAddToQueue,
          area: ['PDT'],
          permission: ['ESP', 'GTR'],
        },
        {
          title: 'Fundos Imobiários',
          path: '/products/real_estate_funds/management',
          Icon: BiAddToQueue,
          area: ['PDT'],
          permission: ['ESP', 'GTR'],
        },
        {
          title: 'Carteiras',
          path: '/products/portfolios/management',
          Icon: BiAddToQueue,
          area: ['PDT'],
          permission: ['ESP', 'GTR'],
        },
        {
          title: 'Oferta Pública Renda Fixa',
          path: '/products/public_offering/management',
          Icon: BiAddToQueue,
          area: ['PDT'],
          permission: ['ESP', 'GTR'],
        },
      ],
    },

    {
      sectionTitle: 'Gestor',

      pages: [
        {
          title: 'Avisos do Gestor',
          path: '/manager/notices',
          Icon: BiTransferAlt,
          area: ['ASS'],
          permission: ['GTR'],
        },
        {
          title: 'Transferência de Ordens - T.I',
          path: '/orders/transfer/ti',
          Icon: BiTransferAlt,
          area: ['TI'],
          permission: ['GTR'],
        },
        {
          title: 'Transferência de ordens - R.H',
          path: '/orders/transfer/rh',
          Icon: BiTransferAlt,
          area: ['RH'],
          permission: ['GTR'],
        },
        {
          title: 'Transferência de ordens - Câmbio',
          path: '/orders/transfer/exchange',
          Icon: BiTransferAlt,
          area: ['EX'],
          permission: ['GTR'],
        },

        {
          title: 'Transferência de ordens - PJ2',
          path: '/orders/transfer/ip',
          Icon: BiTransferAlt,
          area: ['IP'],
          permission: ['GTR'],
        },
        {
          title: 'Pesquisa de leads',
          path: '/v2/leads/search',
          Icon: HiSearch,
          area: ['ASS'],
          permission: ['GTR', 'GTM'],
        },
        {
          title: 'Pesquisa de Clientes',
          path: '/v2/clients/search',
          Icon: HiSearch,
          area: ['ASS'],
          permission: ['GTR', 'GTM'],
        },
        {
          title: 'Pesquisar usuários',
          path: '/users/search/all',
          Icon: HiSearch,
          area: ['ASS', 'RH', 'FI'],
          permission: ['GTR', 'GTM', 'CLT'],
        },
        // // {
        // //   title: 'Saida de clientes',
        // //   path: '/clients/lost/manager',
        // //   Icon: HiSearch,
        // //   area: ['ASS'],
        // //   permission: ['GTR'],
        // // },
        // {
        //     title: 'Transferência de ordens - Mundo Invest',
        //     path: '/orders/transfer/mundo_invest',
        //     Icon: BiTransferAlt,
        //   [  area: 'managerGuru'],
        //     permission: '',
        // },
        {
          title: 'Gerenciar Mesa',
          path: '/manager/office/tables',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['MASTER'],
        },
        {
          title: 'Gerenciar Filiais',
          path: '/manager/office/branches',
          Icon: BiBuildings,
          area: ['ASS'],
          permission: ['MASTER'],
        },
        {
          title: 'Gestão de IP',
          path: '/ip_management',
          Icon: BiAddToQueue,
          area: ['IP'],
          allowedPosition: ['GTR'],
        },
        {
          title: 'Gestão de Câmbio',
          path: '/exchange/management',
          Icon: BiAddToQueue,
          area: ['EX'],
          allowedPosition: ['GTR'],
        },
        {
          title: 'Gestão de Ordens de Usuários Descontinuados',
          path: '/discontinued_user_orders',
          Icon: BiAddToQueue,
          area: ['ASS'],
          permission: ['MASTER'],
        },
        {
          title: 'Pendências do dia',
          path: '/manager/tablependencies',
          Icon: BiTransferAlt,
          area: ['ASS'],
          permission: ['GTR', 'GTM'],
        },

        {
          title: 'Criação de notícia',
          path: '/notices',
          Icon: BiAddToQueue,
          area: ['RV', 'PDT'],
          permission: ['MASTER', 'GTR', 'ESP'],
        },

        {
          title: 'Relatório das reuniões',
          path: '/manager/teammeetings',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['GTR', 'GTM', 'MASTER'],
        },
        {
          title: 'Criação de eventos',
          path: '/manager/events',
          Icon: BiCalendarEvent,
          area: ['RH', 'ASS'],
          permission: ['GTR', 'CLT'],
        },
        // {
        //   title: 'Agenda dos Assessores',
        //   path: '/employee_agenda',
        //   Icon: BiCalendarEvent,
        //   area: ['ASS'],
        //   permission: ['GTM', 'GTR'],
        // },
        {
          title: 'Novos Clientes EWZ',
          path: '/clients/table',
          Icon: AiOutlineTeam,
          area: ['IP'],
          permission: ['MASTER', 'GTR', 'CLV'],
        },
        // {
        //   title: 'Clientes das Áreas',
        //   path: '/areas_management',
        //   Icon: AiOutlineTeam,
        //   area: ['IP', 'ASS'],
        //   permission: [
        //     'CLT',
        //     'MASTER',
        //     'ASS',
        //     'CLI',
        //     'CLA',
        //     'CLV',
        //     'CLS',
        //     'CLC',
        //     'CLG',
        //     'GTM',
        //     'GTR',
        //   ],
        // },
      ],
    },
    {
      sectionTitle: 'Captação',

      pages: [
        {
          title: 'Frequência',
          path: '/generatinal_variables/frequency',
          Icon: AiOutlineTeam,
          area: [''],
          permission: ['MASTER'],
        },

        {
          title: 'Gerenciamento de leads',
          path: '/lead/management',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['COT', 'MASTER'],
        },

        // {
        //   title: 'Gráfico Big Numbers MI',
        //   path: '/captation/management/BigNumbersMI',
        //   Icon: AiOutlineTeam,
        //   area: [''],
        //   permission: ['MASTER'],
        // },

        {
          title: 'Grupos',
          path: '/generatinal_variables/groups',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['COT', 'MASTER'],
        },

        {
          title: 'Gestão SH',
          path: '/generatinal_variables/managementSH',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['COT', 'GTM'],
        },
        {
          title: 'Gestão AAI (EWZ)',
          path: '/captation/management_aai/ewz',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['GTR'],
        },
        {
          title: 'Gestão captação MI',
          path: '/captation/management/MI',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['GTR'],
        },

        {
          title: 'Gestão AAI (MI)',
          path: '/captation/management_aai/mi',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['GTR'],
        },

        {
          title: 'Dashboard diário SH',
          path: '/mosaic/diary/SH',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['COT', 'GTM'],
        },
        // {
        //   title: 'Variáveis geracionais - AAI',
        //   path: '/generational_variables/aai',
        //   Icon: AiOutlineTeam,
        //   area: [''],
        //   permission: ['MASTER'],
        // },
        {
          title: 'Variáveis Geracionais - SH',
          path: '/generatinal_variables/sh',
          Icon: AiOutlineTeam,
          area: [''],
          permission: ['MASTER'],
        },
        // {
        //   title: 'Dashboard de mesas - Tv',
        //   path: '/dashboard/tables',
        //   Icon: AiOutlineTeam,
        //   area: [''],
        //   permission: ['MASTER'],
        // },

        // {
        //   title: 'Dashboard de mesas - Desktop',
        //   path: '/dashboard/tables/desktop',
        //   Icon: AiOutlineTeam,
        //   area: ['ASS'],
        //   permission: ['MASTER', 'GTM', 'GTR'],
        // },
        {
          title: 'Distribuição de leads minerados',
          path: '/distribution_of_mined_leads',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['COT', 'MASTER', 'GTR', 'GTM'],
        },

        {
          title: 'Reuniões Roteadas',
          path: '/captation/routed_meetings',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['MASTER', 'GTM', 'GTR'],
        },
        {
          title: 'Resultados Assessoria',
          path: '/captation/funnel_results',
          Icon: AiOutlineTeam,
          area: ['ASS'],
          permission: ['MASTER', 'GTM', 'GTR'],
        },
        // {
        //   title: 'Real time',
        //   path: '/dashboard/real_time',
        //   Icon: FiActivity,
        //   permission: '',
        //   allowedPosition: ['MASTER'],
        // },
        // {
        //   title: 'Comparação de mesas',
        //   path: '/dashboard/table_comparison',
        //   Icon: AiOutlineTeam,
        //   permission: '',
        //   allowedPosition: ['MASTER'],
        // },

        // {
        //   title: 'Crescimento da Mesa',
        //   path: '/dashboard/table_growth',
        //   Icon: AiOutlineTeam,
        //   permission: '',
        //   allowedPosition: ['GESTOR'],
        // },
        // {
        //   title: 'Alocação',
        //   path: '/dashboard/allocation',
        //   Icon: AiOutlineTeam,
        //   permission: '',
        //   allowedPosition: ['GESTOR'],
        // },
        // {
        //   title: 'Captação do mês',
        //   path: '/dashboard/capture_month',
        //   Icon: AiOutlineTeam,
        //   permission: '',
        //   allowedPosition: ['GESTOR'],
        // },
        // {
        //   title: 'Ligadores Ativos',
        //   path: '/dashboard/captation',
        //   Icon: IoMdCall,
        //   permission: 'managerGMManager',
        //   allowedPosition: ['GESTOR'],
        // },
        // {
        //   title: 'Receita',
        //   path: '/dashboard/revenue',
        //   Icon: AiOutlineTeam,
        //   permission: '',
        //   allowedPosition: ['GESTOR'],
        // },
        // {
        //   title: 'Métricas do AAI',
        //   path: '/dashboard/metrics_aai',
        //   Icon: AiOutlineTeam,
        //   permission: '',
        //   allowedPosition: ['GESTOR'],
        // },
      ],
    },
    // {
    // sectionTitle: 'R.V',
    // pages: [
    // {
    //   title: 'Criação de noticia',
    //   path: '/notices',
    //   Icon: BiAddToQueue,
    //   area: ['RV'],
    //   permission: ['ESP'],
    // },
    //     {
    //       title: 'Clientes Ativos RV',
    //       path: '/active_customers_rv',
    //       Icon: BiAddToQueue,
    //       area: ['RV'],
    //       permission: ['BRK'],
    //     },
    //     {
    //       title: 'Clientes Ativos Fundos Imobiliários',
    //       path: '/active_customers_founds',
    //       Icon: BiAddToQueue,
    //       area: ['RV'],
    //       permission: ['BRK'],
    //     },
    //     {
    //       title: 'Clientes Ativos Ações',
    //       path: '/active_customers_shares',
    //       Icon: BiAddToQueue,
    //       area: ['RV'],
    //       permission: ['BRK'],
    //     },

    //     {
    //       title: 'Gerenciar Estruturadas',
    //       path: '/crud_structured',
    //       Icon: BiAddToQueue,
    //       area: ['RV'],
    //       permission: ['BRK'],
    //     },
    //     {
    //       title: 'Gerenciar Açoes/Fundos Imobiliarios',
    //       path: '/orders_rv',
    //       Icon: BiAddToQueue,
    //       area: ['RV'],
    //       permission: ['BRK'],
    //     },
    //     {
    //       title: 'Ordens de Estruturadas Solicitadas',
    //       path: '/requested_structured_orders',
    //       Icon: BiAddToQueue,
    //       area: ['RV'],
    //       permission: ['BRK'],
    //     },
    // ],
    // },
    {
      sectionTitle: 'Marketing',
      pages: [
        {
          title: 'Gestão de Materiais',
          path: '/materials_management/marketing',
          Icon: BiAddToQueue,
          area: ['MKT'],
          permission: ['GTR'],
        },
        {
          title: 'Gestão de Marketing',
          path: '/marketing_management',
          Icon: BiAddToQueue,
          area: ['MKT'],
          allowedPosition: ['GTR'],
        },
        {
          title: 'Transferência de Ordens - Marketing',
          path: '/orders/transfer/mkt',
          Icon: BiTransferAlt,
          area: ['MKT'],
          permission: ['GTR'],
        },
        // {
        //   title: 'Captação MI - Consolidação semanal',
        //   path: '/capitation/weeklyConsolidation',
        //   Icon: BiTransferAlt,
        //   area: ['MKT'],
        //   permission: ['CLT'],
        // },
      ],
    },
    {
      sectionTitle: 'R.H',

      pages: [
        {
          title: 'E-mail Para Contratação',
          path: '/manager/mail_employee',
          Icon: AiOutlineMail,
          area: ['RH'],
          permission: ['CLT'],
        },
        {
          title: 'Contratações',
          path: '/rh/contracts',
          Icon: BiTask,
          area: ['RH'],
          permission: ['CLT'],
        },
        {
          title: 'Comunicados',
          path: '/hub/announcements/manage',
          Icon: BiCommentDetail,
          area: ['RH'],
          permission: ['CLT'],
        },
      ],
    },
    // // {
    // //   sectionTitle: 'Master',
    // //   positions: ['MASTER'],
    // //   pages: [
    // //     {
    // //       title: 'Crescimento da Mesa - MASTER',
    // //       path: '/dashboard/table_growth_master',
    // //       Icon: BiCommentDetail,
    // //[       area: ''],
    // //       allowedPosition: ['MASTER'],
    // //     },
    // //     {
    // //       title: 'Captação do mês - MASTER',
    // //       path: '/dashboard/captureofthemonth_master',
    // //       Icon: BiTask,
    // //[       area: ''],
    // //       allowedPosition: ['MASTER'],
    // //     },
    // //     {
    // //       title: 'Métricas do AAI - MASTER',
    // //       path: '/dashboard/metrics_aai_manager',
    // //       Icon: BiTask,
    // //[       area: ''],
    // //       allowedPosition: ['MASTER'],
    // //     },
    // //     {
    // //       title: 'Alocação - MASTER',
    // //       path: '/dashboard/allocation_manager',
    // //       Icon: BiTask,
    // //[       area: ''],
    // //       allowedPosition: ['MASTER'],
    // //     },
    // //   ],
    // // },
    {
      sectionTitle: 'Financeiro',
      pages: [
        {
          title: 'Captação',
          path: '/financial/captation',
          Icon: AiOutlineUpload,
          area: ['FI'],
          permission: ['CLT'],
        },
        {
          title: 'Clientes',
          path: '/financial/clients',
          Icon: AiOutlineUpload,
          area: ['FI'],
          permission: ['CLT'],
        },
        {
          title: 'Remuneração',
          path: '/financial/revenue',
          Icon: AiOutlineUpload,
          area: ['FI'],
          permission: ['GTR'],
        },
        {
          title: 'Saldo Negativo',
          path: '/financial/customer_balance',
          Icon: AiOutlineUpload,
          area: ['FI'],
          permission: ['CLT'],
        },
        {
          title: 'Relatório de Comissionamento',
          path: '/financial/commissioning_report/upload',
          Icon: AiOutlineUpload,
          area: ['FI'],
          permission: ['GTR'],
        },
        {
          title: 'Detalhe de Comissionamento',
          path: '/financial/remuneration/upload',
          Icon: AiOutlineUpload,
          area: ['FI'],
          permission: ['GTR'],
        },
      ],
    },
  ],
];

export { menuOptionsAlt };
