import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { BackPageButton } from 'components/elements/buttons';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import history from 'services/history';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { IReduxState } from 'shared/interfaces';
import { errorHandler } from 'utils';
import {
  checkIfMeetingIsNear as getDifferenceInMinutes,
  formatDate,
} from 'utils/formatDate';
import formatValue from 'utils/formatValue';

interface IEquity {
  created_at: string;
  fieldName: string;
  referring_month: string;
  value: number;
}

interface EquityPros {
  employee?: EmployeesProps;
}

export const Equity: React.FC<EquityPros> = ({ employee }) => {
  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [equity, setEquity] = useState<IEquity[]>([]);
  const user = useSelector((state: IReduxState) => state.user.profile);

  const handleSumEquity = () => {
    let sumEquity = 0;

    for (let i = 0; i < equity.length; +i++) {
      sumEquity += equity[i].value;
    }

    return sumEquity;
  };

  const handleGetEquity = async () => {
    try {
      if (employee) {
        const response: IEquity[] = await hubApiUser.getEquity(employee.id);
        setEquity(response);
      } else {
        const response: IEquity[] = await hubApiUser.getEquity(user.id);
        setEquity(response);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      handleGetEquity();
    }
  }, [lastAuth]);

  return (
    <PageWrapper
      flexDir="column"
      align="center"
      pt={employee ? '0' : '9'}
      minH={['60vh', employee ? 'calc(100vh - 280px)' : 'calc(100vh - 80px)']}
    >
      {!employee && <BackPageButton />}
      <MainPageTitle align="center">Equity</MainPageTitle>

      <TableBase
        headData={['Mês', 'Valor']}
        overflowY="auto"
        maxH={[
          '52vh',
          employee ? 'calc(100vh - 300px)' : 'calc(100vh - 250px)',
        ]}
        width="100%"
        maxW="720px"
      >
        {equity.map((item, key) => {
          const date = item.referring_month.split('-');

          const result = `${date[1]}-${date[0]}`;

          return (
            <TableRow
              key={`${item.referring_month}-${key}`}
              transition="background 0.2s ease-in"
              _hover={{ background: 'background.300' }}
            >
              <TableData>{formatDate(result).substring(5, 30)}</TableData>
              <TableData>{formatValue(item.value)}</TableData>
            </TableRow>
          );
        })}
      </TableBase>

      <Flex
        gridGap={3}
        maxW="720px"
        w="100%"
        align="center"
        justify="flex-end"
        mt="3"
      >
        <Text>Total:</Text>
        <Text as="span">{formatValue(handleSumEquity())}</Text>
      </Flex>
    </PageWrapper>
  );
};
