import { SimpleGrid } from '@chakra-ui/react';
import { queryClient } from 'App';
import { useUpdateClientMeeting } from 'hooksV2/useUpdateClientMeeting';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineSafety } from 'react-icons/ai';
import { BiDonateHeart } from 'react-icons/bi';
import { RiHealthBookLine } from 'react-icons/ri';
import { ClientMeetingType } from 'sharedV2/types/client';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { ClientMeetingDetailsCommentedAreasButton } from './button';

type PropsType = {
  meeting: ClientMeetingType;
};

export type CommentedAreaType = {
  label: string;
  icon?: IconType;
  altIcon?: string;
};

export const ClientMeetingDetailsCommentedAreas: React.FC<PropsType> = ({
  meeting,
}): JSX.Element => {
  const [commentedAreas, setCommentedAreas] = useState<string[]>([
    ...meeting.commentedAreas,
  ]);

  const areas: CommentedAreaType[] = [
    {
      label: 'Vida',
      icon: BiDonateHeart,
    },
    {
      label: 'Renda Variável',
      altIcon: 'RV',
    },
    {
      label: 'Renda Fixa',
      altIcon: 'RF',
    },
    {
      label: 'Avenue',
      altIcon: 'AV',
    },
    {
      label: 'Saúde',
      icon: RiHealthBookLine,
    },
    {
      label: 'Mundo Invest',
      altIcon: 'MI',
    },
    {
      label: 'Seguros Alternativos',
      icon: AiOutlineSafety,
    },
  ];

  const { mutateAsync: updateMeeting } = useUpdateClientMeeting({
    onError: error => {
      handleErrorMessage(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['clientMeetingDetails', meeting.id]);
    },
  });

  useEffect(() => {
    const handleDebouncedUpdateMeetingCommentedAreas = debounce(async () => {
      await updateMeeting({
        ...meeting,
        commentedAreas,
      });
    }, 1000);

    if (commentedAreas !== meeting.commentedAreas) {
      handleDebouncedUpdateMeetingCommentedAreas();
    }

    return () => {
      handleDebouncedUpdateMeetingCommentedAreas.cancel();
    };
  }, [commentedAreas]);

  return (
    <SimpleGrid columns={7} gap={2}>
      {areas.map((area, index) => (
        <ClientMeetingDetailsCommentedAreasButton
          key={index}
          area={area}
          commentedAreas={commentedAreas}
          setCommentedAreas={setCommentedAreas}
        />
      ))}
    </SimpleGrid>
  );
};
