import { DefaultPageWrapper } from 'components/elements/wrappers';
import { useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useQuery } from 'react-query';
import hubApi from 'services/hubApi';
import { toast } from 'react-toastify';

export type RouteParamsType = {
  pageName: string;
  reportId: string;
};

export const PowerBIDashboard = (): JSX.Element => {
  const { pageName, reportId } = useParams<RouteParamsType>();

  const { data: powerBIInformation } = useQuery({
    queryKey: ['powerBIInformation', pageName],
    queryFn: async () => {
      const response = hubApi.getPowerBILoadInformation({
        reportId,
        pageName,
      });
      return response;
    },
    onError: () => {
      toast.dark('Erro ao carregar Power BI.');
    },
    refetchOnWindowFocus: false,
  });

  return (
    <DefaultPageWrapper backPageButton>
      <PowerBIEmbed
        embedConfig={{
          pageName,
          type: 'report',
          id: reportId,
          embedUrl: powerBIInformation?.page.embedUrl,
          accessToken: powerBIInformation?.accessToken,
          tokenType: models.TokenType.Aad,
          settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToPage,
            },
            navContentPaneEnabled: false,
            filterPaneEnabled: true,
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Default,
          },
        }}
        cssClassName="EmbedContainer"
        getEmbeddedComponent={embeddedReport => {
          window.report = embeddedReport;
        }}
      />
    </DefaultPageWrapper>
  );
};
