import { CreateLeadManualPropsType } from 'hooksV2/useCreateLeadManual';
import { CreateMeetingPropsType } from 'hooksV2/useCreateMeeting';
import { LeadActionPropsType } from 'hooksV2/useLeadActions';
import { MeetingActionPropsType } from 'hooksV2/useMeetingActions';
import { UpdateLeadMutationPropsType } from 'hooksV2/useUpdateLead';
import { LeadMundoInvestPoolActionType } from 'pages/Captation/components/elements/cards/PoolMILeadCard';
import { SHQuestionsProps } from 'pages/Captation/interfaces/SHLeads';
import {
  IAaiGeneral,
  ICreateActions,
  IEmployeeSelected,
  IFrequency,
  IGroups,
  IIpcaAndFrequency,
} from 'pages/GenerationalVariables/interfaces';
import { EmployeeToSelect } from 'pages/MyMIBs/Components/ManagerModal';
import {
  IGeneralWalletName,
  IGeneralWalletSourceAction,
  ISourceAction,
  ITransactionType,
} from 'pages/MyMIBs/dtos';
import { IWalletNames } from 'pages/MyMIBs/hooks/useMibsOperations';
import { IAgendaData } from 'pages/UserAgenda';
import { LeadActionType } from 'sharedV2/types/leadAction';
import { LeadHistoryType } from 'sharedV2/types/leadHistory';
import { LeadInformationFromMundoInvest } from 'sharedV2/types/leadInformationsFromMundoInvest';
import { LeadManualType } from 'sharedV2/types/leadManual';
import {
  LeadMundoInvestStatusEnum,
  LeadMundoInvestType,
} from 'sharedV2/types/leadMundoInvest';
import { LeadPJType } from 'sharedV2/types/leadPJ';
import { LeadProspectType } from 'sharedV2/types/leadProspect';
import { LeadShQuestionType } from 'sharedV2/types/leadShQuestion';
import { LeadTypeEnum, LeadTypes } from 'sharedV2/types/leadTypes';
import { LeadUploadType } from 'sharedV2/types/leadUpload';
import { MeetingPoolType, MeetingType } from 'sharedV2/types/meeting';
import { HubApi } from '../index';

interface IGeneralMibsOperationRequest {
  mibsQuantity: number;
  name: IGeneralWalletName;
  operation: ITransactionType;
  employeeId: string;
  sourceAction: IGeneralWalletSourceAction;
  transactionResponsible?: string;
}

interface IMibsOperation {
  mibsQuantity: number;
  operation: 'WITHDRAW' | 'DEPOSIT';
  employeeId: string;
  sourceWallet: IWalletNames;
  sourceAction: ISourceAction;
  transactionResponsible: string;
  customTitle?: string;
  additionalInformations?: string;
  destinationWallet: IWalletNames;
}

interface IAddNewLeadByName {
  name: string;
  mails: [{ email: string }];
  cars: [{ placa: string }];
}

interface IAddNewLeadManual {
  name: string;
  email: [
    {
      email: string;
      leadType: string;
    }
  ];
  phone: [
    {
      phone: string;
      leadType: string;
    },
    {
      phone: string;
      leadType: string;
    },
    {
      phone: string;
      leadType: string;
    }
  ];
  phone1: string;
  phone2: string;
  phone3: string;
  status: string;
  reason: string;
  interest: string;
  feedback: string;
  btgAccount: string;
  origin: string;
}

interface IEditQuestion {
  question: string;
  leadId: string;
  id: string;
}

export interface IMetting {
  leadId: string;
  employeeId: string;
  employeeIdParticipant: string;
  type: string;
  local: string;
  leadType: string;
  complement: string;
  date: string | Date;
  status: string;
  feedback: string;
  id?: string;
  contactAttempts: string;
}

export type GetPoolMIHistoryResponseType = {
  id: string;
  leadId: string;
  employeeId: string;
  status: LeadMundoInvestStatusEnum;
  lead: LeadMundoInvestType;
  frequency: number;
};

interface IInvalidPhone {
  id: string;
  invalid: boolean;
}

interface ISendClientCode {
  btgAccount: string;
}

interface ISetNewMeeting {
  complement: string;
  local: string;
  date: string;
  employeeId: string;
  employeeIdParticipant: string;
  status: string;
  leadsId: string;
  leadType: string;
}

interface IAddLeadsRequests {
  name: string;
  email: string;
  cpf: string;
  car: string;
  status: string;
}

interface IAttQuestionResponse {
  id: string;
  answer: string;
}

interface IUpdateMeetingData {
  status: string;
  reason: string;
  obs: string;
  leadType: string;
}

interface IRemoveLeadsRequests {
  id: string;
  name: string;
  email: string;
  cpf: string;
  car: string;
  status: string;
  employeeId: string;
}

interface ILeadTransfer {
  leads: string[];
}

interface IManualLeadDistribution {
  employeeId: string;
  poolNameList: string;
  sendLeadsQtd: number;
}

interface IFrequencyDays {
  distributionDaysFrequency: number;
}

interface ILeadDistributionConfig {
  id?: string;
  isDistributionActive: boolean;
  employeeId: string;
  tableId: string;
  sendLeadsQtd: number;
  distributionDaysFrequency: number;
  poolNameList: string;
}

interface ISendMibsInMass {
  mibsQuantity: number;
  employees: EmployeeToSelect[];
  generalWalletName: IGeneralWalletName;
}

type GetLeadsProspectPoolRequestType = {
  page: number;
  filterValue?: string;
  contactAttempts?: string;
  leadType?: string;
  shouldFetchDeclinedLeads?: string;
};

export type GetLeadsProspectPoolResponseType = {
  leads: LeadProspectType[];
  totalPages: number;
  nextPage: number;
  previousPage: number;
};

export type GetMeetingsPoolResponseType = {
  late: {
    meetings: MeetingPoolType[];
    pipeValue: number;
  };
  callback: {
    meetings: MeetingPoolType[];
    pipeValue: number;
  };
  suitability: {
    meetings: MeetingPoolType[];
    pipeValue: number;
  };
  presentation: {
    meetings: MeetingPoolType[];
    pipeValue: number;
  };
  allocation: {
    meetings: MeetingPoolType[];
    pipeValue: number;
  };
  account: {
    meetings: MeetingPoolType[];
    pipeValue: number;
  };
};

export enum TransferToEnum {
  SH = 'SH',
  AAI = 'AAI',
}

export type TransferLeadRequestType = {
  leadId: string;
  leadType: LeadTypeEnum;
  newEmployeeId: string;
  transferTo: TransferToEnum;
  date?: Date | string;
};

class HubApiCaptation extends HubApi {
  async createMeeting(data: CreateMeetingPropsType) {
    const response = await this.axios.post(`/meetings/v2/`, data);

    return response.data;
  }

  async updateMeeting(data: Partial<MeetingType>) {
    const response = await this.axios.patch(
      `/meetings/v2?meetingId=${data.id}`,
      data
    );

    return response.data;
  }

  async meetingAction(data: MeetingActionPropsType) {
    let url = `/meetings/v2/actions?action=${data.action}&meetingId=${data.meetingId}&leadType=${data.leadType}`;

    if (data.isFromProspectPage) {
      url += '&isFromProspectPage=true';
    }

    const response = await this.axios.patch(url, data);

    return response.data;
  }

  async globalLeadAction(data: LeadActionPropsType) {
    const response = await this.axios.patch(
      `leads/actions?action=${data.action}&leadId=${data.leadId}&leadType=${data.leadType}`,
      data
    );

    return response.data;
  }

  async leadAction(data: LeadActionPropsType) {
    if (data.leadType === LeadTypeEnum.UPLOAD) {
      const response = await this.axios.patch(
        `/upload/leads/actions?action=${data.action}&leadId=${data.leadId}`,
        data
      );

      return response.data;
    }

    const response = await this.axios.patch(
      `/mi/leads/actions?action=${data.action}&leadId=${data.leadId}`,
      data
    );

    return response.data;
  }

  // Employee Metrics
  async getEmployeeMetrics(
    employeeId: string,
    fromTypeLead: string,
    startDate: string,
    endDate: string,
    allFromTable: string,
    tableId: string
  ) {
    const response = await this.axios.get(
      `/employees/aai/metrics?employeeId=${employeeId}&tableId=${tableId}&allFromTable=${allFromTable}&fromType=${fromTypeLead}${
        startDate && `&startDate=${startDate}`
      }${endDate && `&endDate=${endDate}`}`
    );
    return response.data;
  }

  async getAAISFromTable(tableId: string) {
    const response = await this.axios.get(
      `/management/aaiFromTableManagent/?tableId=${tableId}`
    );
    return response.data;
  }

  // new captation
  async checkSh(area: 'SH' | 'AAI' | 'AAI MI' | 'SH MI', employeeId: string) {
    const response = await this.axios.get(`/group/exist/${area}/${employeeId}`);
    return response.data;
  }

  async getEmployeesInGroup(area: 'SH' | 'AAI') {
    const response = await this.axios.get(`/group/type/${area}`);
    return response.data;
  }

  async getPublicLeads() {
    const response = await this.axios.get('/leads/publics');
    return response;
  }

  async getSHPoolLeads() {
    const response = await this.axios.get<LeadMundoInvestType[]>(
      '/mi/leads/shPool'
    );
    return response.data;
  }

  async getLeadInfomationsFromMundoInvest(leadEmail: string) {
    const response = await this.axios.get<{
      clients: LeadInformationFromMundoInvest[];
    }>(`/mi/clients/search?field=email&value=${leadEmail}&page=1`);

    return response.data.clients[0];
  }

  async getPublicLeadById(id: string) {
    const response = await this.axios.get(`leads/publics/${id}`);
    return response;
  }

  async getSHAgenda() {
    const response = await this.axios.get('leads/count');
    return response;
  }

  async getPoolPublicLeads() {
    const response = await this.axios.get('/leads/publics/pool');
    return response;
  }

  async getMinedLeads(page = 1, term?: string) {
    if (term) {
      const response = await this.axios.get(
        `/leads/tables/page/${page}?term=${term}`
      );
      return response.data;
    }

    const response = await this.axios.get(`/leads/tables/page/${page}`);
    return response.data;
  }

  async getLeadsProspectPool({
    filterValue,
    page,
    contactAttempts,
    leadType,
    shouldFetchDeclinedLeads,
  }: GetLeadsProspectPoolRequestType) {
    let url = `/prospect/leads/pool?page=${page}`;

    if (filterValue) url += `&filterValue=${filterValue}`;
    if (contactAttempts) url += `&contactAttempts=${contactAttempts}`;
    if (leadType) url += `&leadType=${leadType}`;
    if (shouldFetchDeclinedLeads)
      url += `&shouldFetchDeclinedLeads=${shouldFetchDeclinedLeads}`;

    const response = await this.axios.get<GetLeadsProspectPoolResponseType>(
      url
    );
    return response.data;
  }

  async getLeadsList(page = 1, term?: string) {
    if (term) {
      const response = await this.axios.get(
        `/leads/tables/page/${page}/lista?term=${term}`
      );
      return response.data;
    }

    const response = await this.axios.get(`/leads/tables/page/${page}/lista`);
    return response.data;
  }

  async patchLeadAction(data: any) {
    const response = await this.axios.patch('frequency/actions/history', data);
    return response;
  }

  async updateLeadAction(data: LeadActionType) {
    const response = await this.axios.patch(
      `/leads/actions/update?id=${data.id}&status=${data.status}`
    );
    return response;
  }

  async getFreshMeetingStatus(id: string) {
    const response = await this.axios.get(`/meetings/${id}`);
    return response;
  }

  async deleteMeeting(id: string) {
    const response = await this.axios.delete(`/meetings/${id}`);
    return response;
  }

  async patchEmployeePoints(
    data: {
      points: string;
      type: 'sub' | 'sum';
    },
    type: 'aai' | 'sh',
    employeeid: string
  ) {
    const response = await this.axios.patch(
      `ranking/${type}/${employeeid}`,
      data
    );
    return response;
  }

  // new leads
  async getLead(type: string) {
    let typeOfLead;

    switch (type) {
      case 'online':
        typeOfLead = 'publics';
        break;
      case 'leadspublic':
        typeOfLead = 'publics';
        break;
      case 'publics':
        typeOfLead = 'publics';
        break;
      case 'leadspj':
        typeOfLead = 'pj';
        break;
      case 'leadsmanual':
        typeOfLead = 'employee';
        break;
      case 'employee':
        typeOfLead = 'employee';
        break;
      case 'leadspersonal':
        typeOfLead = 'personal';
        break;
      default:
        typeOfLead = 'tables';
    }

    const response = await this.axios.get(`/leads/${typeOfLead}`);
    return response.data;
  }

  async getLeadById(type: string, id: string) {
    let typeOfLead;

    switch (type) {
      case LeadTypeEnum.MUNDO_INVEST:
      case 'online':
        typeOfLead = 'publics';
        break;
      case 'leadspublic':
        typeOfLead = 'publics';
        break;
      case 'publics':
        typeOfLead = 'publics';
        break;
      case 'leadspj':
        typeOfLead = 'pj';
        break;
      case 'leadsmanual':
        typeOfLead = 'employee';
        break;
      case 'employee':
        typeOfLead = 'employee';
        break;
      case 'leadspersonal':
        typeOfLead = 'personal';
        break;
      default:
        typeOfLead = 'tables';
    }

    const response = await this.axios.get(`/leads/${typeOfLead}/${id}`);
    return response.data;
  }

  async getLeadStatus() {
    const response = await this.axios.get(`/leads/count`);
    return response.data;
  }

  async pathLeadsUpdate(type: string, data: any) {
    let typeOfLead;

    switch (type) {
      case 'publics':
        typeOfLead = 'publics';
        break;
      case 'online':
        typeOfLead = 'publics';
        break;
      case 'leadspublic':
        typeOfLead = 'publics';
        break;
      case 'leadspj':
        typeOfLead = 'pj';
        break;
      case 'pj':
        typeOfLead = 'pj';
        break;
      case 'leadsmanual':
        typeOfLead = 'employee';
        break;
      case 'employee':
        typeOfLead = 'employee';
        break;
      case 'leadspersonal':
        typeOfLead = 'personal';
        break;
      default:
        typeOfLead = 'tables';
    }

    const response = await this.axios.patch(`/leads/${typeOfLead}`, data);
    return response.data;
  }

  async updateLead(data: UpdateLeadMutationPropsType) {
    if (data.type === LeadTypeEnum.UPLOAD) {
      const response = await this.axios.patch(
        `/upload/leads?id=${data.leadId}`,
        data
      );
      return response.data;
    }

    if (data.type === LeadTypeEnum.PJ) {
      const response = await this.axios.patch(`/leads/pj`, {
        id: data.leadId,
        ...data,
      });
      return response.data;
    }

    if (data.type === LeadTypeEnum.MANUAL) {
      const response = await this.axios.patch(
        `/manual/leads?id=${data.leadId}`,
        data
      );
      return response.data;
    }

    const response = await this.axios.patch(
      `/mi/leads?id=${data.leadId}`,
      data
    );
    return response.data;
  }

  async getLeadInformation(data: {
    leadType: LeadTypeEnum;
    leadId: string;
  }): Promise<LeadTypes> {
    if (data.leadType === LeadTypeEnum.UPLOAD) {
      const response = await this.axios.get<LeadUploadType[]>(
        `/upload/leads?id=${data.leadId}`
      );
      return response.data[0];
    }

    if (data.leadType === LeadTypeEnum.MANUAL) {
      const response = await this.axios.get<LeadManualType[]>(
        `/manual/leads?id=${data.leadId}`
      );
      return response.data[0];
    }

    if (data.leadType === LeadTypeEnum.PJ) {
      const response = await this.axios.get<LeadPJType>(
        `/leads/pj/${data.leadId}`
      );
      return response.data;
    }

    const response = await this.axios.get<LeadMundoInvestType[]>(
      `/mi/leads?id=${data.leadId}`
    );
    return response.data[0];
  }

  async AddNewLeadByName(data: IAddNewLeadByName) {
    const response = await this.axios.post(`/leadstable/create`, data);
    return response.data;
  }

  async AddNewLeadByCars(car: string, tele: string) {
    const response = await this.axios.post(`/leads/tables/mine/`, {
      type: 'veiculos',
      param: car,
    });
    return response.data;
  }

  async AddNewLeadByEmail(email: string, tele: string) {
    const response = await this.axios.post(
      `/leads/tables/mine/`,
      {
        type: 'email',
        param: email,
      }
      // `/leadsinclude/email/${email}/${tele}`
    );
    return response.data;
  }

  // olhar depois
  async AddNewLeadManual(data: IAddNewLeadManual) {
    const response = await this.axios.post(`/leads/employee`, data);
    return response.data;
  }

  async searchLead(param: string) {
    const response = await this.axios.get(`/leads/search/${param}`);
    return response.data;
  }

  async searchLeadByEmployeeId(param: string) {
    const response = await this.axios.get(`/leads/search/by/employee/${param}`);
    return response.data;
  }

  async searchLeadAAI(phone: string) {
    const response = await this.axios.get(
      `leads/search/by/employee/phone/${phone}`
    );
    return response.data;
  }

  async getSingleQuestion(id: string) {
    const response = await this.axios.get(`/leads/questions/employee/${id}`);
    return response.data;
  }

  // tasks
  async getVerifyQuestion() {
    const response = await this.axios.get(`/leads/questions/employee/primary`);
    return response.data;
  }

  async postNewQuestion(data: any) {
    const response = await this.axios.post(`/leads/questions/employee`, data);
    return response.data;
  }

  async getQuestion() {
    const response = await this.axios.get(`/leads/questions/employee`);
    return response.data;
  }

  async deleteQuestion(id: string) {
    const response = await this.axios.delete(`/leads/questions/employee/${id}`);
    return response.data;
  }

  async editQuestion(data: IEditQuestion) {
    const response = await this.axios.patch(`/leads/questions/employee/`, data);
    return response.data;
  }

  async postResponse(data: any) {
    const response = await this.axios.post(`/leads/questions/lead/`, data);

    return response.data;
  }

  async getQuestionResponse(id: string) {
    const response = await this.axios.get(`/leads/questions/lead/${id}`);
    return response.data;
  }

  async deleteResponse(id: string) {
    const response = await this.axios.delete(`/leads/questions/lead/${id}`);
    return response.data;
  }

  async attQuestionResponse(data: IAttQuestionResponse) {
    const response = await this.axios.patch(`/leads/questions/lead/`, data);
    return response.data;
  }

  async updateSHQuestions(data: SHQuestionsProps) {
    const response = await this.axios.patch(`/questions`, data);
    return response.data;
  }

  async updateLeadShQuestion(question: LeadShQuestionType) {
    const response = await this.axios.patch(
      `/leads/questions?leadId=${question.leadId}`,
      question
    );
    return response.data;
  }

  // meetings
  async allMeetings(status: string) {
    const response = await this.axios.get(`/meetings/status/${status}`);
    return response.data;
  }

  async postMetting(data: IMetting) {
    const response = await this.axios.post('/meetings', data);
    return response.data;
  }

  async pathMetting(data: any) {
    const response = await this.axios.patch('/meetings', data);
    return response.data;
  }

  async getLeadsOnlineCIC() {
    const response = await this.axios.get('leads/by/cic');
    return response.data;
  }

  async InvalidPhone(data: IInvalidPhone) {
    const response = await this.axios.patch('/invalid/phone', data);
    return response.data;
  }

  // leads
  async getShLeadsHistory() {
    const response = await this.axios.get('/leads/publics/meeting');
    return response.data;
  }

  async getLeadsOnline() {
    const response = await this.axios.get('/leadsOnline/show');
    return response.data;
  }

  async getLeads() {
    const response = await this.axios.get('leads/pj');
    return response.data;
  }

  async getLeadInfo(type: string, leadId: string) {
    const response = await this.axios.get(`/leads/id/${type}/${leadId}`);
    return response.data;
  }

  async getLeadInfoPj(leadId: string) {
    const response = await this.axios.get(`/leadspj/by/${leadId}`);
    return response.data;
  }

  async getStatus() {
    const response = await this.axios.get('leadshistory/stats');
    return response.data;
  }

  async editLeadInfos(type: string, id: string, formData: FormData) {
    const response = await this.axios.patch(`/leads/${type}/${id}`, formData);
    return response.data;
  }

  async editLeadsTable(formData: FormData) {
    const response = await this.axios.patch(`/leadstable/`, formData);
    return response.data;
  }

  async updateMeetingData(id: string, meetingData: IUpdateMeetingData) {
    const response = await this.axios.patch(`/meetings/${id}`, meetingData);
    return response.data;
  }

  async sendClientCode(id: string, data: ISendClientCode) {
    const response = await this.axios.patch(`/meetings/finish/${id}`, data);
    return response.data;
  }

  async setNewMeeting(meetingData: ISetNewMeeting) {
    const response = await this.axios.post('meetings', meetingData);
    return response.data;
  }

  async postIdMetting(id: string) {
    const response = await this.axios.post('/outlook/calendar/event', id);
    return response.data;
  }

  async getUrlOutlook() {
    const response = await this.axios.get('outlook');
    return response.data;
  }

  async postIdOutlook(id: string) {
    const response = await this.axios.post('meetings', id);
    return response.data;
  }

  async getAllTables() {
    const response = await this.axios.get('/table');
    return response.data;
  }

  async getStatistics(id: string) {
    const response = await this.axios.get(`/leads/stats/table/${id}`);
    return response.data;
  }

  async getFilial() {
    const response = await this.axios.get('/office');
    return response.data;
  }

  async searchLeadByPhone(phoneNumber: string) {
    const response = await this.axios.get(`/phone/${phoneNumber}`);
    return response.data;
  }

  async updatedObs(id: string, lead: string) {
    const response = await this.axios.patch(`/leads/online/${id}`, lead);
    return response.data;
  }

  async updatedObsPj(id: string, lead: string) {
    const response = await this.axios.patch(`/leads/pj/${id}`, lead);
    return response.data;
  }

  async updatedObsTable(lead: string) {
    const response = await this.axios.patch(`/leadstable`, lead);
    return response.data;
  }

  async listMeetingAndReturns() {
    const response = await this.axios.get('/captation');
    return response.data;
  }

  async listLeadsRequests() {
    const response = await this.axios.get('leadsemployees/');
    return response.data;
  }

  async listAddedLeads() {
    const response = await this.axios.get('/leadstable/list');
    return response.data;
  }

  async sendCPFLeads(cpf: string, idLead: string) {
    const response = await this.axios.patch(`/leadstable/cpf/${cpf}/${idLead}`);
    return response.data;
  }

  async invalidateLead(id: string) {
    const response = await this.axios.patch(`leadstable/update/${id}`, {
      status: 'delete',
    });
    return response.data;
  }

  async addLeadsRequests(data: IAddLeadsRequests) {
    const response = await this.axios.post('leadsemployees', data);
    return response.data;
  }

  async counterLeadsRequests() {
    const response = await this.axios.get('leadsemployees/count');
    return response.data;
  }

  async removeLeadsRequests(data: IRemoveLeadsRequests) {
    const response = await this.axios.patch(`/leadsemployees`, data);
    return response.data;
  }

  async getFrequency() {
    const response = await this.axios.get('/frequency');
    return response.data;
  }

  async getFrequencyById(id: string) {
    const response = await this.axios.get(`/frequency/by/${id}`);
    return response.data;
  }

  async postFrequency(data: IFrequency) {
    const response = await this.axios.post('/frequency', data);
    return response.data;
  }

  async patchFrequency(data: IFrequency) {
    const response = await this.axios.patch('/frequency', data);
    return response.data;
  }

  async postAction(data: ICreateActions) {
    const response = await this.axios.post('/frequency/actions', data);
    return response.data;
  }

  async deleteAction(id: string) {
    const response = await this.axios.delete(`frequency/actions/${id}`);
    return response.data;
  }

  async getControlSh() {
    const response = await this.axios.get('/control/sh');
    return response.data;
  }

  async getRankingSh() {
    const response = await this.axios.get('/employees/sh/variables/ranking');
    return response.data;
  }

  async getManagementLeads() {
    const response = await this.axios.get('/employees/sh/variables');
    return response.data;
  }

  async getManagementLeadsByPeriod(startDate: any, endDate: any) {
    const response = await this.axios.get(
      `/employees/sh/variables?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  }

  async pathControlSh(data: IIpcaAndFrequency) {
    const response = await this.axios.patch('/control/sh', data);
    return response.data;
  }

  async getControlAAI() {
    const response = await this.axios.get('/control/aai');
    return response.data;
  }

  async getAAIRanking() {
    const response = await this.axios.get('employees/aai/variables/ranking');
    return response.data;
  }

  async getAAIDeclinesAndResults(from: string, to: string) {
    if (from !== '' && to !== '') {
      const response = await this.axios.get(
        `/employees/aai/variables/?startDate=${from}&endDate=${to}`
      );
      return response.data;
    }

    const response = await this.axios.get('employees/aai/variables/');
    return response.data;
  }

  async patchControlAAi(data: IAaiGeneral) {
    const response = await this.axios.patch('/control/aai', data);
    return response.data;
  }

  async getRankingSH(initDate?: string, endDate?: string) {
    const response = await this.axios.get(
      `/employees/sh${initDate ? `?startDate=${initDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }`
    );
    return response.data;
  }

  async addPurchasedMILead({
    employeeId,
    leadValue,
    quantity,
  }: {
    employeeId: string;
    quantity: string;
    leadValue: string;
  }) {
    const data = { leadValue, quantity };
    const response = await this.axios.post(
      `/employees/sh/purchasedMILeads?employeeId=${employeeId}`,
      data
    );
    return response.data;
  }

  async getEmployeeMibs(employeeId: string) {
    const response = await this.axios.get(
      `/wallets/mibs?employeeId=${employeeId}`
    );
    return response.data;
  }

  async getGeneralWalletMibs() {
    const response = await this.axios.get(`/generalWallets/mibs`);
    return response.data;
  }

  async mibsOperations(data: IMibsOperation) {
    const response = await this.axios.patch(`/wallets/mibsOperations`, data);
    return response.data;
  }

  async sendMIBsInMass(data: ISendMibsInMass) {
    const response = await this.axios.patch(`/wallets/sendMibsInMass`, data);
    return response.data;
  }

  async generalMibsOperations(data: IGeneralMibsOperationRequest) {
    const response = await this.axios.patch(
      `/generalWallets/mibsOperations`,
      data
    );
    return response.data;
  }

  async getMibsHistory() {
    const response = await this.axios.get(`/wallets/mibsHistory`);
    return response.data;
  }

  async getGroups() {
    const response = await this.axios.get('/group');
    return response.data;
  }

  async createGroup(data: IGroups) {
    const response = await this.axios.post('/group', data);
    return response.data;
  }

  async pathUpdateStatusGroup(data: IGroups) {
    const response = await this.axios.patch('/group', data);
    return response.data;
  }

  async patchGroup(data: IGroups) {
    const response = await this.axios.patch('/group', data);
    return response.data;
  }

  async postAddUserToGroup(data: IEmployeeSelected[]) {
    const response = await this.axios.post(`/group/members`, data);

    return response.data;
  }

  async deleteUserOfGroup(id: string, idGroup: string) {
    const groupId = { groupId: idGroup };
    const response = await this.axios.patch(
      `/group/members/${id}`,

      groupId
    );

    return response.data;
  }

  async getCaptationMundoInvest() {
    const response = await this.axios.get(`/dash/mi/nnm/`);

    return response.data;
  }

  async getDiarySh(dateFilter?: string) {
    const response = await this.axios.get(`/dash/sh?dateFilter=${dateFilter}`);
    return response.data;
  }

  async getShInfos() {
    const response = await this.axios.get(`/dash/sh/specific`);
    return response.data;
  }

  async getLeadsShInfos(id: string) {
    const response = await this.axios.get(`leads/publics/employee/${id}`);
    return response.data;
  }

  async notifyNewBtgAccount(
    btgAccount: string,
    data: { leadType: string; nomeListaPool: string | undefined }
  ) {
    const response = await this.axios.patch(
      `/clients/fromType/mi/${btgAccount}`,
      data
    );
    return response.data;
  }

  async patchStopSH(id: string) {
    const response = await this.axios.patch(`ranking/sh/status/${id}`);
  }

  async getInfosMundoInvestCpmm() {
    const response = await this.axios.get(`/dash/mi/cpmm`);

    return response.data;
  }

  async getDateAAIEWZ() {
    const response = await this.axios.get(`/employees/aai/ewz`);

    return response.data;
  }

  async updateFrequency(data: { id: string; frequency: string }) {
    const response = await this.axios.patch(`leads/publics`, data);

    return response.data;
  }

  async updatePipeValue(data: {
    id: string;
    pipeValue: string;
    leadType: string;
  }) {
    const response = await this.axios.patch(`/leads/${data.leadType}`, {
      id: data.id,
      pipeValue: data.pipeValue,
    });

    return response.data;
  }

  async patchTransferLeads(id: string, leads: ILeadTransfer) {
    const response = await this.axios.patch(
      `/leads/publics/transfer/${id}`,
      leads
    );
    return response.data;
  }

  async getInfosMundoInvest() {
    const response = await this.axios.get(`/dash/bignumber/mi`);
    return response.data;
  }

  async postLeadsFile(file: FormData, employeeIds: string[]) {
    const response = await this.axios.post(`/leads/tables/file`, file, {
      params: { employeeIds },
    });
    return response.data;
  }

  async changeToPipe(item: any) {
    const response = await this.axios.patch(`/meetings/ispipe`, item);
    return response.data;
  }

  async getMeetingsByPipe() {
    const response = await this.axios.get(`/meetings/ispipe/user`);
    return response.data;
  }

  async getExpiredLeads() {
    const response = await this.axios.get(`leads/publics/expired/leads`);
    return response.data;
  }

  async getMundoInvestExpiredLead(employeeId: string) {
    const response = await this.axios.get<LeadMundoInvestType>(
      `/mi/leads/expired?employeeId=${employeeId}`
    );
    return response.data;
  }

  async getNonImportedLeads() {
    const response = await this.axios.get(`/leads/tables/notimport`);
    return response.data;
  }

  // mined leads

  async getLeadsByTableId(tableId: string) {
    const response = await this.axios.get(
      `/leads/tables/employeeMinedLeads/${tableId}`
    );
    return response.data;
  }

  async getAllLists() {
    const response = await this.axios.get(`/leads/tables/getMinedLeads`);
    return response.data;
  }

  async postManualLeadDistribution(data: IManualLeadDistribution) {
    const response = await this.axios.post(`/leads/tables/distribution`, data);
    return response.data;
  }

  async getFrequencyDays() {
    const response = await this.axios.get(`/leads/tables/frequencyDays`);
    return response.data;
  }

  async patchFrequencyDays(data: IFrequencyDays) {
    const response = await this.axios.patch(
      `/leads/tables/frequencyDays`,
      data
    );
    return response.data;
  }

  async postLeadDistributionConfig(data: ILeadDistributionConfig) {
    const response = await this.axios.post(
      `/leads/tables/distribution/config`,
      data
    );
    return response.data;
  }

  async getAllEmployees() {
    const response = await this.axios.get(`/leads/tables/listAllEmployees`);

    return response.data;
  }

  async listAllEmployees() {
    const response = await this.axios.get(`/employees/listAll`);

    return response.data;
  }

  async patchLeadDistributionConfig(data: ILeadDistributionConfig) {
    const response = await this.axios.patch(
      `/leads/tables/distribution/config`,
      data
    );
    return response.data;
  }

  // histórico de ações dos assessores
  async patchEmployeeActionsHistory(data: {
    employeeId: string;
    leadId: string;
    leadType: string;
    [action: string]: string | boolean | number;
  }) {
    const response = await this.axios.patch(`/dailyEmployees`, data);
    return response.data;
  }

  async getDeclinedLeadsByEmployee() {
    const response = await this.axios.get(`/meetings/declinedLeads`);
    return response.data;
  }

  async getRoutedMeetings(
    page: string,
    table?: string,
    startDate?: string,
    endDate?: string,
    status?: string
  ) {
    const response = await this.axios.get(
      `/management/allRoutedMeetings/?page=${page}&table=${table}&startDate=${startDate}&endDate=${endDate}&status=${status}`
    );
    return response.data;
  }

  async getAgendaData(employeeId: string) {
    const response = await this.axios.get<IAgendaData>(
      `/meetings/calendarMeetings/${employeeId}`
    );
    return response.data;
  }

  async getTotalNumOfLeads() {
    const response = await this.axios.get(`/meetings/totalMeetingsInPipe`);
    return response.data;
  }

  async leadsPurchased(data: {
    employee: { name: string; id: string };
    leadsValue: string[];
    quantity: number;
  }) {
    const response = await this.axios.post('/leadsPurchased', data);
    return response.data;
  }

  async getLeadsPurchasedOrders() {
    const response = await this.axios.get('/leadsPurchased');
    return response.data;
  }

  async getLeadsPurchased() {
    const response = await this.axios.get('/leads/publics/purchased');
    return response.data;
  }

  async getLeadsPricingVariables() {
    const response = await this.axios.get('/leads/publics/pricingVariables');
    return response.data;
  }

  async getTotalSystemMibs() {
    const response = await this.axios.get('/generalWallets/mibs/system');
    return response.data;
  }

  async getTotalSHPLRouted({
    employeeId,
    initialDate,
    finalDate,
  }: {
    employeeId: string;
    initialDate: string;
    finalDate: string;
  }) {
    const response = await this.axios.get(
      `/employees/sh/totalPl?employeeId=${employeeId}&initialDate=${initialDate}&finalDate=${finalDate}`
    );
    return response.data;
  }

  async getShLeadActivities({
    employeeId,
    eventType,
    date,
  }: {
    employeeId: string;
    eventType: string;
    date: string;
  }) {
    const response = await this.axios.get(
      `/employees/sh/leadActivities?employeeId=${employeeId}&eventType=${eventType}&created_at=${date}`
    );
    return response.data;
  }

  async updateLeadPurchaseOrder({
    orderId,
    status,
  }: {
    orderId: string;
    status: 'active' | 'finished';
  }) {
    const response = await this.axios.patch(
      `/mi/leads/purchaseOrders?orderId=${orderId}`,
      {
        status,
      }
    );
    return response.data;
  }

  async getPoolMILeads({
    minPL,
    maxPL,
    ddd,
  }: {
    minPL: string;
    maxPL: string;
    ddd: string;
  }) {
    if (ddd.length > 0) {
      const response = await this.axios.get(
        `/mi/leads/pool?minPL=${minPL}&maxPL=${maxPL}&ddd=${ddd}`
      );

      return response.data;
    }

    const response = await this.axios.get(
      `/mi/leads/pool?minPL=${minPL || '0'}&maxPL=${maxPL || '0'}`
    );
    return response.data;
  }

  async getPoolMIHistory() {
    const response = await this.axios.get<GetPoolMIHistoryResponseType[]>(
      `/mi/leads/pool/history`
    );
    return response.data;
  }

  async getMeetingsPool() {
    const response = await this.axios.get<GetMeetingsPoolResponseType>(
      `/meetings/v2/pool`
    );
    return response.data;
  }

  async updateLeadFromPoolMI({
    action,
    employeeId,
    leadId,
    declinedReason,
  }: {
    action: LeadMundoInvestPoolActionType;
    employeeId: string;
    leadId: string;
    declinedReason?: string;
  }) {
    let url = `/mi/leads/pool?action=${action}&employeeId=${employeeId}&leadId=${leadId}`;

    if (declinedReason) {
      url += `&declinedReason=${declinedReason}`;
    }

    const response = await this.axios.patch(url);
    return response.data;
  }

  async getAllLeads(filterValue: string) {
    const response = await this.axios.get(`/leads?filterValue=${filterValue}`);
    return response.data;
  }

  async transferLead(data: TransferLeadRequestType) {
    const response = await this.axios.patch(
      `/leads/transfer?transferTo=${data.transferTo}&leadType=${data.leadType}&leadId=${data.leadId}&newEmployeeId=${data.newEmployeeId}&date=${data.date}`
    );
    return response.data;
  }

  async createLeadManual(data: CreateLeadManualPropsType) {
    const response = await this.axios.post(`/manual/leads`, data);
    return response.data;
  }

  async getLeadHistory(leadId: string) {
    const response = await this.axios.get<LeadHistoryType[]>(
      `/leads/history?leadId=${leadId}`
    );

    return response.data;
  }

  async createLeadNote(data: {
    content: string;
    leadType: string;
    leadId: string;
  }) {
    const response = await this.axios.post(`/leadsNotes`, data);
    return response.data;
  }
}

export const hubApiCaptation = new HubApiCaptation();
