import { Flex, Text } from '@chakra-ui/react';
import { useNavigation } from 'hooksV2/useNavigation';
import { useMeetingsPoolContext } from 'pagesV2/MeetingsPool/context/useMeetingsPoolContext';

const othersLeadsTypesTab = [
  {
    title: 'Pool MI',
    name: 'Pool MI',
  },
];

export const MeetingsPoolHeader = (): JSX.Element => {
  const { selectedTab } = useMeetingsPoolContext();
  const { redirect } = useNavigation();

  return (
    <Flex justifyContent="flex-end" alignItems="center" h="40px">
      <Flex gap={0}>
        {othersLeadsTypesTab.map((tab, index) => (
          <Flex
            borderBottom={
              selectedTab === tab.name
                ? '1px solid rgba(29, 99, 221, 1)'
                : '1px solid gray'
            }
            key={index}
            cursor="pointer"
            w="130px"
            justifyContent="center"
            onClick={() => redirect('/v2/captation/mundoInvest/pool')}
          >
            <Text color="rgba(70, 70, 70, 1)">{tab.title}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
