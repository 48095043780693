import { Flex } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { useEffect, useState } from 'react';
import { IFunnelData } from './dtos';
import { FunnelPart } from './funnelPart';
import { HundredPerCent } from './HundredPerCent';

interface IProps {
  data: IFunnelData[];
  isLoading: boolean;
}

export const FunnelChart: React.FC<IProps> = ({ data, isLoading }) => {
  const [highestValue, setHighestValue] = useState(0);
  const [firstData, setFirstData] = useState(data[0]?.value);

  const isDataEmpty = data.length === 0;

  useEffect(() => {
    if (data.length > 1) {
      const max = data?.reduce((acc, curr) => {
        return curr.value > acc.value ? curr : acc;
      });
      setHighestValue(max.value);
      setFirstData(data[0].value);
    }
  }, [data]);

  return (
    <>
      {(isDataEmpty || isLoading) && <LoadingSpinner h="100%" />}
      {!isDataEmpty && (
        <Flex
          marginTop={6}
          flexDirection="column"
          gap="15px"
          alignItems="center"
          w="100%"
          padding={3}
          h="80%"
          justifyContent="center"
        >
          <HundredPerCent highestValue={highestValue} firstData={firstData} />
          {data.map(item => (
            <FunnelPart
              data={data}
              color={item.color}
              label={item.label}
              value={item.value}
              highestValue={highestValue}
            />
          ))}
        </Flex>
      )}
    </>
  );
};
