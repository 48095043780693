import { TableBase } from 'components/elements/table';
import { useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadsPoolMundoInvestHistoryRow } from '../leadsHistoryRow';

type PropsType = {
  leadAction: (action: 'decline' | 'purchase' | 'no_answer') => void;
};

export const LeadsPoolMundoInvestHistory: React.FC<PropsType> = ({
  leadAction,
}): JSX.Element => {
  const { data: leadsPoolMundoInvestHistory } = useQuery({
    queryKey: ['leadsPoolMundoInvestHistory'],
    queryFn: async () => {
      const response = await hubApiCaptation.getPoolMIHistory();
      return response;
    },
  });

  return (
    <TableBase
      w="100%"
      headData={[
        'Nome',
        'Telefone',
        'Ação do Pool',
        'Status Atual',
        'Frequência',
        '',
      ]}
      p="0"
      h="97%"
      overflowY="auto"
      className="slimScrollbar"
      my={3}
    >
      {leadsPoolMundoInvestHistory &&
        leadsPoolMundoInvestHistory.map((history, index) => (
          <LeadsPoolMundoInvestHistoryRow
            key={index}
            history={history}
            leadAction={leadAction}
          />
        ))}
    </TableBase>
  );
};
