import { Flex, Text } from '@chakra-ui/react';
import { IFunnelData } from './dtos';
import { adjustLabel } from './utils/adjustLabel';
import { calcPercentage } from './utils/calcPercentage';
import { calcWidth } from './utils/calcWidth';

interface IProps {
  color: string;
  label: string;
  value: number;
  highestValue: number;
  data: IFunnelData[];
}

export const FunnelPart: React.FC<IProps> = ({
  color,
  label,
  value,
  highestValue,
  data,
}) => {
  const width = calcWidth(value, highestValue);
  return (
    <Flex w="100%" alignItems="center">
      <Flex w="20%">
        <Text textAlign="center" color="rgba(70, 70, 70, 1)" fontWeight="bold">
          {adjustLabel(label)}
        </Text>
      </Flex>

      <Flex w="80%" justifyContent="center" alignItems="center" h="2.5rem">
        {value === 0 ? (
          '-'
        ) : (
          <Flex
            w={`${width}%`}
            backgroundColor={color}
            justifyContent="center"
            alignItems="center"
            gap="5px"
            h="40px"
          >
            {(width > 10 ||
              value === highestValue ||
              data[0].value === value) && (
              <>
                <Text fontSize="sm" color="#fff">
                  {value}
                </Text>
                <>
                  <Text fontSize="sm" color="#fff">
                    ({calcPercentage({ data, label })}%)
                  </Text>
                </>
              </>
            )}
          </Flex>
        )}

        <Flex w="100px" justifyContent="end">
          {width <= 10 && value !== highestValue && data[0].value !== value && (
            <Flex gap="5px">
              <Text fontSize="sm" color="#fff" textAlign="right">
                {value}
              </Text>
              <>
                {data[0].value !== value && (
                  <Text fontSize="sm" color="#fff">
                    ({calcPercentage({ data, label })}%)
                  </Text>
                )}
              </>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
