import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { TransferLeadModal } from 'componentsV2/modules/captation/transferLeadModal';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { TransferToEnum } from 'services/hubApi/classes/HubApiCaptation';

export const LeadDetailsTransferLeadToAAI = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const {
    filterValue: isOpenTransferLeadToAAIModalFilterValue,
    handleChangeFilterValue: handleChangeIsOpenTransferLeadToAAIModalValue,
    handleDeleteParam: handleDeleteIsOpenTransferLeadToAAIModalFilter,
  } = useSimpleQueryFilter('isOpenTransferLeadToAAIModal');

  const isOpenTransferLeadToAAIModal =
    isOpenTransferLeadToAAIModalFilterValue === 'true';

  return (
    <>
      <Button.Primary
        onClick={() => handleChangeIsOpenTransferLeadToAAIModalValue('true')}
      >
        Transferir Lead para AAI
      </Button.Primary>

      <ModalWrapper
        isOpen={isOpenTransferLeadToAAIModal}
        onClose={() => handleDeleteIsOpenTransferLeadToAAIModalFilter()}
      >
        <TransferLeadModal
          lead={lead}
          leadType={leadType}
          transferTo={TransferToEnum.AAI}
        />
      </ModalWrapper>
    </>
  );
};
