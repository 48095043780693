import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { Icons } from 'sharedV2/icons';
import { ClientMeetingStatusEnum, ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsLastActivities: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const lastMeetings = client.meetings.filter(
    meeting => meeting.status === ClientMeetingStatusEnum.CONCLUDED
  );

  const handleGoToMeetingPage = (meetingId: string) => {
    redirect(
      `/v2/client/${client.btgCode}/meeting/${meetingId}?isOnlyVisualizationMode=true`
    );
  };

  return (
    <Flex
      flexDirection="column"
      gap={2}
      overflowY="auto"
      className="thinScrollbar"
    >
      {lastMeetings.length === 0 && (
        <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
          Não existem últimas atividades
        </Text>
      )}
      {lastMeetings.map((meeting, index) => (
        <Box.Outline
          key={index}
          w="100%"
          p={2}
          gap={4}
          cursor="pointer"
          _hover={{ borderColor: '#1d63dd' }}
          onClick={() => handleGoToMeetingPage(meeting.id)}
        >
          <Icons.CALENDAR color="white" />
          <Flex flexDirection="column">
            <Text color="white">Reunião</Text>
            <Text fontSize="sm">{DateUtils.formatWithTime(meeting.date)}</Text>
          </Flex>
        </Box.Outline>
      ))}
    </Flex>
  );
};
