import styled, { css } from 'styled-components';

export enum InputTypeEnum {
  NUMBER = 'number',
  TEXT = 'text',
}

export type PropsType = {
  w?: string;
  h?: string;
  type?: InputTypeEnum;
  cursor?: string;
};

export const InputTextFieldStyledComponents = styled.input.attrs<PropsType>(
  ({ type = InputTypeEnum.TEXT }) => ({
    type,
  })
)<PropsType>`
  ${({ w, h }) => css`
    height: 100%;
    width: ${w || '100%'};
    color: black;
    background: transparent;
    border-radius: 6px;
    padding-left: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    height: ${h};
    padding-top: 4px;
    padding-bottom: 4px;

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray};
      font-size: 13px;
      align-items: center;
      font-style: italic;
    }
  `}
`;

export const InputTextAreaFieldStyledComponents = styled.textarea.attrs({
  className: 'thinScrollbar',
})<PropsType>`
  ${({ w, h, cursor }) => css`
    width: ${w};
    color: rgba(70, 70, 70, 1);
    border-radius: 5px;
    border: 1px solid #1d63ddb2;
    padding: 8px;
    height: ${h};
    resize: none;
    cursor: ${cursor};

    background: #fff;

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray};
    }
  `}
`;
